import { useState, useEffect } from 'react';
import Select from 'react-select';

const Form = () => {
    const [eno, setEno] = useState(null);
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [quantity, setQuantity] = useState("");
    const [comments, setComments] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [contractorOptions, setContractorOptions] = useState([]);
    const [combinedOptions, setCombinedOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedType, setSelectedType] = useState("");
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedMachineTools, setSelectedMachine] = useState(null);
    const [siteOptions, setSiteOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [machineToolsOptions, setMachineToolsOptions] = useState([]);
    const [showMachineTools, setShowMachineTools] = useState(false);
    useEffect(() => {
        fetchDropdownOptions("https://backendaab.in/dashboardaab/api/expenses/sites", setSiteOptions, "site names");
        fetchDropdownOptions("https://backendaab.in/dashboardaab/api/expenses/categories", setCategoryOptions, "categories");
        fetchDropdownOptions("https://backendaab.in/dashboardaab/api/expenses/machine-tools", setMachineToolsOptions, "machine tools");
    }, []);
console.log(siteOptions);
    useEffect(() => {
        fetchDropdownOptions("https://backendaab.in/dashboardaab/api/expenses/vendors", setVendorOptions, "Vendor");
        fetchDropdownOptions("https://backendaab.in/dashboardaab/api/expenses/contractors", setContractorOptions, "Contractor");
    }, []);

    useEffect(() => {
        setCombinedOptions([...vendorOptions, ...contractorOptions]);
    }, [vendorOptions, contractorOptions]);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            setSelectedType(selectedOption.type);
        } else {
            setSelectedType("");
        }
    };
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const handleCategoryChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);

        // Show the machine tools dropdown if "Machine Repair" is selected
        if (selectedCategory && selectedCategory.label === 'Machine Repair') {
            setShowMachineTools(true);
        } else {
            setShowMachineTools(false);
            setSelectedMachine(null); // Reset machine tools selection if hidden
        }
    };
    // Function to fetch the latest eno from the backend
    const fetchLatestEno = async () => {
        try {
            const response = await fetch('https://backendaab.in/aabuildersDash/expenses_form/get_form');
            if (!response.ok) {
                throw new Error('Failed to fetch ENo');
            }
            const data = await response.json();

            if (data.length > 0) {
                // Sort the data by eno in descending order to get the largest eno
                const sortedData = data.sort((a, b) => b.eno - a.eno);
                const lastEno = sortedData[0].eno; // Get the largest eno
                setEno(lastEno + 1); // Increment the eno by 1
            } else {
                // If no data, start with the default eno 4120
                setEno(4120);
            }
        } catch (error) {
            console.error('Error fetching latest ENo:', error);
        }
    };

    useEffect(() => {
        // Fetch the latest ENo on component mount
        fetchLatestEno();
    }, []);
    const handleUpload = async (e) => {
        e.preventDefault();

        // Form validation for required fields
        if (!date || !selectedSite || !amount || !selectedCategory) {
            alert('Please fill out all required fields.');
            return;
        }
        setEno(eno + 1);
        setIsSubmitting(true);
        try {
            // Step 1: Check if PDF is mandatory based on accountType
            if (selectedAccountType === 'Bill Payment' && !selectedFile) {
                alert('PDF file is required for Bill Payment.');
                setIsSubmitting(false);
                return;
            }
            let pdfUrl = '';
            if (selectedFile) {
                try {
                    const formData = new FormData();
                    formData.append('pdf', selectedFile);

                    const uploadResponse = await fetch("https://backendaab.in/aabwebapps/googleUploader/uploadToGoogleDrive", {
                        method: "POST",
                        body: formData,
                    });

                    if (!uploadResponse.ok) {
                        throw new Error('File upload failed');
                    }

                    const uploadResult = await uploadResponse.json();
                    pdfUrl = uploadResult.url;
                    console.log('File URL:', pdfUrl);
                } catch (error) {
                    console.error('Error during file upload:', error);
                    alert('Error during file upload. Please try again.');
                    setIsSubmitting(false);
                    return;
                }
            }

            // Determine whether to assign to vendor or contractor based on the selected type
            let vendor = '';
            let contractor = '';
            if (selectedType === 'Vendor') {
                vendor = selectedOption ? selectedOption.label : '';
            } else if (selectedType === 'Contractor') {
                contractor = selectedOption ? selectedOption.label : '';
            }

            // Prepare the form data to be sent
            const formData2 = new FormData();
            formData2.append('accountType', selectedAccountType);
            formData2.append('date', date);
            formData2.append('ENo', eno);
            formData2.append('siteName', selectedSite ? selectedSite.label : '');
            formData2.append('vendor', vendor);
            formData2.append('contractor', contractor);
            formData2.append('quantity', quantity);
            formData2.append('amount', amount);
            formData2.append('category', selectedCategory ? selectedCategory.label : '');
            formData2.append('comments', comments);  // Assuming command should be comments
            formData2.append('machineTools', selectedMachineTools ? selectedMachineTools.label : '');
            formData2.append('billCopyUrl', pdfUrl || '');

            const formResponse = await fetch("https://backendaab.in/aabuildersDash/expenses_form/save", {
                method: "POST",
                body: formData2,
            });

            if (!formResponse.ok) {
                const errorText = await formResponse.text();
                throw new Error(`Form submission failed: ${errorText}`);
            }

            const formResult = await formResponse.text();
            console.log('Form submitted successfully:', formResult);
            alert('Form submitted successfully!');
            window.location.reload();
            resetForm(); // Reset the form after successful submission
        } catch (error) {
            console.error('Error during form submission:', error);
            alert('Error during form submission. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    const resetForm = () => {
        setDate('');
        setAmount('');
        setQuantity('');
        setComments('');
        setSelectedFile(null);
        setSelectedOption(null);
        setSelectedSite(null);
        setSelectedCategory(null);
        setSelectedMachine(null);
        setSelectedAccountType('');
    };

    return (
        <body className=' bg-yellow-50'>
            <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg md:ml-4 ml-3">
                <form onSubmit={handleUpload}>
                    <div className='grid gap-6'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='flex'>
                                <h4 className="text-lg font-semibold mb-2 text-[#E4572E] mr-3">Account Type</h4>
                                <select className="h-10 border border-yellow-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 shadow-yellow-400 md:h-10 md:w-50"
                                    value={selectedAccountType}
                                    onChange={(e) => setSelectedAccountType(e.target.value)}
                                >
                                    <option value="" disabled selected>Select...</option>
                                    <option value="dailyWage">Daily Wage</option>
                                    <option value="weeklyPayment">Weekly Payment</option>
                                    <option value="claim">Claim</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <label className="text-sm font-semibold mb-2">Date</label>
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                className="border border-yellow-300 w-44 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 shadow-yellow-400"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='flex flex-col'>
                                <label className="text-sm font-semibold mb-2">Site Name</label>
                                <Select
                                    options={siteOptions || []}
                                    placeholder="Select a site..."
                                    isSearchable={true}
                                    value={selectedSite}
                                    onChange={setSelectedSite}
                                    styles={customStyles}
                                    isClearable
                                    className="custom-select border border-yellow-300 rounded-md md:w-72 focus:outline-none "
                                />
                            </div>
                            <div className='flex flex-col md:-ml-80'>
                                <div className=' flex'>
                                    <label className="text-sm font-semibold mb-2">Vendor/Contractor Name</label>
                                    {selectedType && <span className="text-sm text-orange-600 mb-1 ml-14 mt-1 font-semibold">{selectedType}</span>}
                                </div>
                                <Select
                                    styles={customStyles}
                                    options={combinedOptions}
                                    value={selectedOption}
                                    onChange={handleChange}
                                    placeholder="Select a Option..."
                                    isClearable
                                    className="custom-select border border-yellow-300 rounded-md md:w-72"
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='flex flex-col'>
                                <label className="text-sm font-semibold mb-2">Quantity</label>
                                <input
                                    type="text"
                                    value={quantity}
                                    placeholder='Enter Quantity...'
                                    onChange={(e) => setQuantity(e.target.value)}
                                    className="border border-yellow-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 md:w-72 shadow-yellow-400"
                                />
                            </div>
                            <div className='flex flex-col md:-ml-80'>
                                <label className="text-sm font-semibold mb-2">Amount</label>
                                <input
                                    type="number"
                                    value={amount}
                                    placeholder='Enter The Amount...'
                                    onChange={(e) => setAmount(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    className="border border-yellow-300 rounded-md px-4 py-2 md:w-72 focus:outline-none focus:ring-2 focus:ring-yellow-400 shadow-yellow-400"
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='flex flex-col'>
                                <label className="text-sm font-semibold mb-2">Category</label>
                                <Select
                                    options={categoryOptions || []}
                                    placeholder="Select a category..."
                                    isSearchable={true}
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    styles={customStyles}
                                    isClearable
                                    className="custom-select border border-yellow-300 rounded-md md:w-72 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                />
                            </div>
                            {showMachineTools && (
                                <div className="flex flex-col md:-ml-80">
                                    <label className="text-sm font-semibold mb-2 ">Machine Tools</label>
                                    <Select
                                        options={machineToolsOptions || []}
                                        placeholder="Select a machine tool..."
                                        isSearchable={true}
                                        value={selectedMachineTools}
                                        onChange={setSelectedMachine}
                                        styles={customStyles}
                                        isClearable
                                        className="custom-select border border-yellow-300 rounded-md md:w-72 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                    />
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className="text-sm font-semibold mb-2">Comments</label>
                            <input
                                className=" md:w-6/12 border border-yellow-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 shadow-yellow-400"
                                type='text'
                                placeholder='Enter Your Comments ...'
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <label htmlFor="fileInput" className="cursor-pointer flex items-center text-orange-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.88 3.549l4.588 4.588a2.25 2.25 0 010 3.182l-9.68 9.68a2.25 2.25 0 01-1.067.607l-5.56 1.39a.75.75 0 01-.923-.923l1.39-5.56a2.25 2.25 0 01.607-1.067l9.68-9.68a2.25 2.25 0 013.182 0zM15 5.875L18.125 9M16.5 7.5L9 15m0 0v3m0-3h3" />
                                </svg>
                                Attach file
                            </label>
                            <input type="file" id="fileInput" className="hidden" onChange={handleFileChange} />
                            {selectedFile && <span className="text-gray-600">{selectedFile.name}</span>}
                        </div>

                        <div className="flex ">
                            <button
                                type='submit'
                                disabled={isSubmitting}
                                className={`bg-yellow-700 text-white px-6 py-2 rounded-md hover:bg-yellow-600 transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </body>
    );
};
export default Form;
// Fetch function
const fetchDropdownOptions = async (url, setOptions, type) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Failed to fetch ${type}`);
        const data = await response.json();
        if (!Array.isArray(data)) throw new Error(`${type} data is not an array`);
        const optionsWithType = data
            .filter(item => item && item.trim())
            .map((item, index) => ({
                label: item,
                value: `${type} - ${index}`,
                type,
            }));
        setOptions(optionsWithType);
    } catch (error) {
        console.error(`Failed to fetch ${type}:`, error.message);
    }
};
// Custom styles for select
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'rgb(234, 179, 8)' : 'rgb(253, 230, 138)',
        boxShadow: state.isFocused ? '0 0 0 2px rgba(234, 179, 8, 0.5)' : 'none',
        '&:hover': {
            borderColor: 'rgb(234, 179, 8)',
        }
    }),
};