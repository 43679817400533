import React, { useState } from "react";
import { Link } from "react-router-dom";
import home from '../Images/dashboard.svg';
import homeWhite from '../Images/dashboard1.svg';
import billing from '../Images/Billing.svg';
import billingWhite from '../Images/Billing1.svg';
import crm from '../Images/CRM.svg';
import crmWhite from '../Images/CRM1.svg';
import account from '../Images/Accounts.svg';
import accountWhite from '../Images/Accounts1.svg';
import procurement from '../Images/Procurement.svg'
import procurementWhite from '../Images/Procurement1.svg';
import designtools from '../Images/Design Tools.svg';
import designtoolsWhite from '../Images/Design Tools1.svg';
import hr from '../Images/HR.svg';
import hrWhite from '../Images/HR1.svg';

function Sidebar({ isVisible, sidebarRef }) {
  const [activeMenu, setActiveMenu] = useState('');
  const [activeSubmenuItem, setActiveSubmenuItem] = useState('');
  const handleMenuClick = (menu) => {
    setActiveMenu(menu === activeMenu ? '' : menu);
  };
  const handleSubmenuItemClick = (itemName) => {
    setActiveSubmenuItem(itemName === activeSubmenuItem ? '' : itemName);
  };
  return (
    <aside
      ref={sidebarRef}
      className={`fixed  h-screen w-[250px] bg-[#FFFFFF] mt-14 z-20 overflow-y-auto transition-transform duration-1000 ease-in-out transform ${isVisible ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <nav className="h-full flex flex-col">
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'home' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('home')}>
          <img src={activeMenu === 'home' ? homeWhite : home}
            alt="home" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base bg-brown-500 w-[190px] -ml-[33%]">Home</p>
        </div>
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'billing' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('billing')}
        >
          <img src={activeMenu === 'billing' ? billingWhite : billing}
            alt="billing" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base bg-brown-500 w-[190px] -ml-[33%]">Billing</p>
        </div>
        {activeMenu === 'billing' && (
          <div className="ml-6">
            <Link to="database_retrieves" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Bill Payments Tracker' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Bill Payments Tracker')}>
              <p className="text-sm cursor-pointer"><li>Bill Payments Tracker</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Bill Entry Checklist' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Bill Entry Checklist')}>
              <p className="text-sm cursor-pointer"><li>Bill Entry Checklist</li></p>
            </Link>
            <Link to="/invoice-bill/invoice" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Invoice' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Invoice')}>
              <p className="text-sm cursor-pointer"><li>Invoice</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Quotation' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Quotation')}>
              <p className="text-sm cursor-pointer"><li>Quotation</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'change Oreder' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('change Oreder')}>
              <p className="text-sm cursor-pointer"><li>change Oreder</li></p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'crm' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('crm')}
        >
          <img src={activeMenu === 'crm' ? crmWhite : crm} alt="crm" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base ">CRM</p>
        </div>
        {activeMenu === 'crm' && (
          <div className="ml-6">
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Enquiry' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Enquiry')}>
              <p className="text-sm cursor-pointer"><li>Enquiry</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Projects' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Projects')}>
              <p className="text-sm cursor-pointer"><li>Projects</li></p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'account' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('account')}
        >
          <img src={activeMenu === 'account' ? accountWhite : account} alt="account" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base">Account</p>
        </div>
        {activeMenu === 'account' && (
          <div className="ml-6">
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Vendor Payments Tracker' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Vendor Payments Tracker')}>
              <p className="text-sm cursor-pointer"><li>Vendor Payments Tracker</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Advance Portal' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Advance Portal')}>
              <p className="text-sm cursor-pointer"><li>Advance Portal</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Payment Receipt' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Payment Receipt')}>
              <p className="text-sm cursor-pointer"><li>Payment Receipt</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Rent Management' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Rent Management')}>
              <p className="text-sm cursor-pointer"><li>Rent Management</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Claim Payments' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Claim Payments')}>
              <p className="text-sm cursor-pointer"><li>Claim Payments</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'weekly Payment Register' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('weekly Payment Register')}>
              <p className="text-sm cursor-pointer"><li>weekly Payment Register</li></p>
            </Link>
            <Link to="/expense-entry/form" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Expense Entry' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Expense Entry')}>
              <p className="text-sm cursor-pointer"><li>Expense Entry</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Expense Dashboard' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Expense Dashboard')}>
              <p className="text-sm cursor-pointer"><li>Expense Dashboard</li></p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'procurement' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('procurement')}
        >
          <img src={activeMenu === 'procurement' ? procurementWhite : procurement} alt="procurement" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base">Procurement</p>
        </div>
        {activeMenu === 'procurement' && (
          <div className="ml-6">
            <Link to="database_retrieves" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Purchase Order' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Purchase Order')}>
              <p className="text-sm cursor-pointer"><li>Purchase Order</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Inventory' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Inventory')}>
              <p className="text-sm cursor-pointer"><li>Inventory</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Tools Tracker' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Tools Tracker')}>
              <p className="text-sm cursor-pointer"><li>Tools Tracker</li></p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'designtools' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('designtools')}
        >
          <img src={activeMenu === 'designtools' ? designtoolsWhite : designtools} alt="designtools" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base">Design Tools</p>
        </div>
        {activeMenu === 'designtools' && (
          <div className="ml-6">
            <Link to="/designtool/tileCalculate" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'onboarding' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('onboarding')}>
              <p className="text-sm cursor-pointer"><li>Tile Calculator</li></p>
            </Link>
            <Link to="/paints/paintCalculation" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'paints' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('paints')}>
              <p className="text-sm cursor-pointer"><li>Paint Calculator</li></p>
            </Link>
            <Link to="" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Attendance' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Attendance')}>
              <p className="text-sm cursor-pointer"><li>Bath Fixtures Matrix</li></p>
            </Link>
            <Link to="rccal/RCCCalculation" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem == 'rccCalculation' ? 'text-red-500':''}` }
            onClick={() => handleSubmenuItemClick('rccCalculation')}> 
            <p className=" text-sm cursor-pointer"><li>RCC Calculation</li></p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${activeMenu === 'hr' ? 'bg-[#BF9853] text-white' : 'text-black'}`}
          onClick={() => handleMenuClick('hr')}
        >
          <img src={activeMenu === 'hr' ? hrWhite : hr} alt="hr" className="h-4 w-4" />
          <p className="text-[12px] leading-[15px] font-medium text-base">HR</p>
        </div>
        {activeMenu === 'hr' && (
          <div className="ml-6">
            <Link to="database_retrieves" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'onboarding' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('onboarding')}>
              <p className="text-sm cursor-pointer"><li>Onboarding</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Attendance' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Attendance')}>
              <p className="text-sm cursor-pointer"><li>Attendance</li></p>
            </Link>
            <Link to="billview" className={`submenu-link flex items-center gap-[1px] p-2 ${activeSubmenuItem === 'Staff Advance' ? 'text-red-500' : ''
              }`} onClick={() => handleSubmenuItemClick('Staff Advance')}>
              <p className="text-sm cursor-pointer"><li>Staff Advance</li></p>
            </Link>
          </div>
        )}
      </nav>
    </aside>
  );
}
export default Sidebar;