import React, { useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import './Heading.css';
import Form from '../Components/Maincantents/Form';
import Tableview from '../Components/Maincantents/TableViewExpense';
import Database from '../Components/Maincantents/DatabaseExpenses';
const Heading = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };
    console.log("Current Path:", location.pathname); // Check the current path
    return (
        <body className=" bg-yellow-50">
            <div className="topbar-title">
                <h2 className="mb-2">
                    <Link
                        className={`link ${activeLink === '/expense-entry/form' ? 'active' : ''}`}
                        to="/expense-entry/form"
                        onClick={() => handleLinkClick('/expense-entry/form')}
                    >
                        Form
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/expense-entry/tableview' ? 'active' : ''}`}
                        to="/expense-entry/tableview"
                        onClick={() => handleLinkClick('/expense-entry/tableview')}
                    >
                        Table View
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/expense-entry/database' ? 'active' : ''}`}
                        to="/expense-entry/database"
                        onClick={() => handleLinkClick('/expense-entry/database')}
                    >
                        Database
                    </Link>
                </h2>
            </div>

            <Routes>
                <Route path="form" element={<Form />} />  {/* Form Component */}
                <Route path="tableview" element={<Tableview/>} />  {/* Table View Component */}
                <Route path="database" element={<Database />} />  {/* Database Component */}
            </Routes>
        </body>
    );
}
export default Heading;
