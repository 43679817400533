import React, { useState, useEffect } from "react";
import Search from '../Images/search.png'
import port from '../Images/Import.svg'
import Edit from '../Images/Edit.svg'
import dele from '../Images/Delete.svg'
const RcAddinput = () => {
    const [selectedTileName, setSelectedTileName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAreaNameOpen, setIsAreaNameOpen] = useState(false);
  const [isFloorNameOpen, setIsFloorNameOpen] = useState(false);
  const [isFloorTypeOpen, setIsFloorTypeOpen] = useState(false);
  const [isTileSizeOpen, setIsTileSizeOpen] = useState(false);
  const closeTileSize = () => setIsTileSizeOpen(false);
  const openTileSize = () => setIsTileSizeOpen(true);
  const closeFloorType = () => setIsFloorTypeOpen(false);
  const openFloorType = () => setIsFloorTypeOpen(true);
  const openFloorName = () => setIsFloorNameOpen(true);
  const closeFloorName = () => setIsFloorNameOpen(false);
  const openAreaName = () => setIsAreaNameOpen(true);
  const closeAreaName = () => setIsAreaNameOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [file, setFile] = useState(null);
  const [areaSearch, setAreaSearch] = useState("");
  const [sizeSearch, setSizeSearch] = useState('');
  const [tileName, setTileName] = useState('');
  const [tileSize, setTileSize] = useState('');
  const [floorSearch, setFloorSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const [areaName, setAreaName] = useState('');
  const [floorName, setFloorName] = useState('');
  const [floorType, setFloorType] = useState('');
  const [tileAreaNames, setTileAreaNames] = useState([]);
  const [tileFloorNames, setTileFloorNames] = useState([]);
  const [tileToDelete, setTileToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tileData, setTileData] = useState({
    tileSize: '',
    quantityBox: '',
    areaTile: ''
  });
  const [isAreaModalOpens, setIsAreaModalOpens] = useState(false);
  const [tileList, setTileList] = useState([]);
  const [tileFloorTypes, setTileFloorTypes] = useState([]);
  const [tiles, setTiles] = useState([]);
  const [isTileNameModalOpen, setIsTileNameModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [message, setMessage] = useState('');
  console.log(message);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [areaEdit, setAreaEdit] = useState(null);
  const [editTileData, setEditTileData] = useState({
    id: '',
    tileName: '',
    tileSize: '',
    image: null,
  });
  const [isTileEditSizeOpen, setIsTileEditSizeOpen] = useState(false);
  const [currentTileSize, setCurrentTileSize] = useState(null);
  const [isEditFloorOpen, setIsEditFloorOpen] = useState(false);
  const [editFloorName, setEditFloorName] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState(null);
  const [isEditFloorTypeOpen, setIsEditFloorTypeOpen] = useState(false);
  const [editFloorType, setEditFloorType] = useState('');
  const [selectedFloorTypeId, setSelectedFloorTypeId] = useState(null);
  const [isSizeModalOpens, setIsSizeModalOpens] = useState(false);
  const [isFloorNameOpens, setIsFloorNameOpens] = useState(false);
  const [isFloorTypeOpens, setIsFloorTypeOpens] = useState(false);
  const [isTileNameAndImageOpens, setIsTileNameAndImageOpens] = useState(false);
  const openEditFloorTypePopup = (floorType) => {
    setEditFloorType(floorType.floorType); // Set the current name to be edited
    setSelectedFloorTypeId(floorType.id); // Store the ID of the floor type to edit
    setIsEditFloorTypeOpen(true);
  };
  const openAreaModals = () => setIsAreaModalOpens(true);
  const openSizeModals = () => setIsSizeModalOpens(true);
  const openFloorNameModals = () => setIsFloorNameOpens(true);
  const openFloorTypeModals = () => setIsFloorTypeOpens(true);
  const openTileNameAndImageModals = () => setIsTileNameAndImageOpens(true);
  const closeAreaModals = () => setIsAreaModalOpens(false);
  const closeSizeModals = () => setIsSizeModalOpens(false);
  const closeFloorNameModals = () => setIsFloorNameOpens(false);
  const closeFloorTypeModals = () => setIsFloorTypeOpens(false);
  const closeTileNameAndImageModals = () => setIsTileNameAndImageOpens(false);
  const closeEditFloorTypePopup = () => {
    setIsEditFloorTypeOpen(false);
    setEditFloorType('');
    setSelectedFloorTypeId(null);
  };
    return (
        <div className="p-4 bg-white z-0 ml-6 mr-8">
          <div className=" flex space-x-[2%]">
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  className="border border-[#FAF6ED] border-r-4 border-l-4 border-b-4 border-t-4 rounded-lg p-2 flex-1  h-12 focus:outline-none" style={{width: '198px', height: '45px'}}
                  placeholder="Search Beam Name"
                />
                <button className="-ml-6 mt-5 transform -translate-y-1/2 text-gray-500">
                  <img src={Search}  alt='search' className=' w-5 h-5' />
                </button>
                <button className=" text-black font-bold px-1 ml-4 rounded border-dashed border-b-2 border-[#BF9853]">
                  + Add
                </button>
              </div>
              <button  className="text-[#E4572E] font-semibold  flex ">
                <img src={port} alt='import' className=' w-7 h-5 bg-transparent pr-2 mt-1' />
                <h1 className='mt-1.5'>Import file</h1>
              </button>
              <div className="rounded-lg border border-gray-200 border-l-8  border-l-[#BF9853]" style={{width: '268px', height: '48px'}}>
                <table className="table-auto ">
                  <thead className="bg-[#FAF6ED]">
                    <tr className="border-b">
                      <th className="p-2 text-left w-16 text-lg font-bold">SI.No</th>
                      <th className="p-2 text-left w-72 text-lg font-bold">Item Name</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr  className="border-b odd:bg-white even:bg-[#FAF6ED]">
                        <td className="p-2 text-left font-semibold"></td>
                        <td className="p-2 group flex justify-between items-center font-semibold">
                          <div className="flex flex-grow">
                            <button
                              className="font-medium hover:text-[#E4572E] text-left flex"
                            >
                            </button>
                          </div>
                          <div className="flex space-x-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                            <button type="button">
                              <img src={Edit} alt="edit" className="w-4 h-4" />
                            </button>
                            <button >
                              <img src={dele} alt="delete" className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  className="border border-[#FAF6ED] border-r-4 border-l-4 border-b-4 border-t-4 rounded-lg p-2 flex-1  h-12 focus:outline-none" style={{width: '199px', height: '45px'}}
                  placeholder="Search Description.."
                />
                <button className="-ml-6 mt-5 transform -translate-y-1/2 text-gray-500">
                  <img src={Search} alt='search' className=' w-5 h-5' />
                </button>
                <button className="text-black font-bold px-1 ml-4 rounded border-dashed border-b-2 border-[#BF9853]">
                  + Add
                </button>
              </div>
              <button  className="text-[#E4572E] font-semibold  flex"><img src={port} alt='import' className=' w-7 h-5 bg-transparent pr-2 mt-1' /><h1 className='mt-1.5'>Import file</h1></button>

              <div className='rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853]' style={{width: '266px', height: '48px'}}>
                <table className="table-auto ">
                  <thead className='bg-[#FAF6ED]'>
                    <tr className="border-b">
                      <th className="p-2 text-left w-16 text-lg font-bold">SI.No</th>
                      <th className="p-2 text-left w-72 text-lg font-bold">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="border-b odd:bg-white even:bg-[#FAF6ED]">
                        <td className="p-2 text-left font-semibold"></td>
                        <td className="p-2 text-left group flex font-semibold">
                          <div className="flex flex-grow">
                          </div>
                          <div className="flex space-x-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                            <button type="button" >
                              <img src={Edit} alt="add" className="w-4 h-4" type="button"  />
                            </button>
                            <button >
                              <img src={dele} alt="delete" className="w-4 h-4"  />
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  className="border border-[#FAF6ED] border-r-4 border-l-4 border-b-4 border-t-4 rounded-lg p-2 flex-1  h-12 focus:outline-none" style={{width: '142px', height: '45px'}}
                  placeholder="Search T.."
                />
                <button className="-ml-6 mt-5 transform -translate-y-1/2 text-gray-500">
                  <img src={Search} alt='search' className=' w-5 h-5' />
                </button>
                <button className="text-black font-bold ml-4 px-1 rounded border-dashed border-b-2 border-[#BF9853]">
                  + Add
                </button>
              </div>
              <button  className="text-[#E4572E] font-semibold  flex "><img src={port} alt='import' className=' w-7 h-5 bg-transparent pr-2 mt-1' /><h1 className='mt-1.5'>Import file</h1></button>

              <div className='rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853]' style={{width: '203px', height: '48px'}}>
                <table className="table-auto  ">
                  <thead className='bg-[#FAF6ED]'>
                    <tr className="border-b">
                      <th className="p-2 text-left w-16 text-lg font-bold">S.No</th>
                      <th className="p-2 text-left w-72 text-lg font-bold">Type</th>
                    </tr>
                  </thead>
                  <tbody>               
                      <tr  className="border-b odd:bg-white even:bg-[#FAF6ED]">
                        <td className="p-2 text-left font-semibold"></td>
                        <td className="p-2 text-left group flex font-semibold">
                          <div className="flex flex-grow">
                          </div>
                          <div className="flex space-x-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                            <button type="button" >
                              <img src={Edit} alt="add" className="w-4 h-4" type="button"  />
                            </button>
                            <button >
                              <img src={dele} alt="delete" className="w-4 h-4"  />
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  className="border border-[#FAF6ED] border-r-4 border-l-4 border-b-4 border-t-4 rounded-lg p-2 flex-1  h-12 focus:outline-none " style={{width: '142px', height: '45px'}}
                  placeholder="Search T.."
                />
                <button className="-ml-6 mt-5 transform -translate-y-1/2 text-gray-500">
                  <img src={Search} alt='search' className=' w-5 h-5' />
                </button>
                <button className="text-black font-bold ml-4 px-1 rounded border-dashed border-b-2 border-[#BF9853]">
                  + Add
                </button>
              </div>
              <button  className="text-[#E4572E] font-semibold  flex"><img src={port} alt='import' className=' w-7 h-5 bg-transparent pr-2 mt-1' /><h1 className='mt-1.5'>Import file</h1></button>

              <div className='rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853] ' style={{width: '203px', height: '48px'}}>
                <table className="table-auto  ">
                  <thead className='bg-[#FAF6ED]'>
                    <tr className="border-b">
                      <th className="p-2 text-left w-16 text-lg font-bold">S.No</th>
                      <th className="p-2 text-left w-72 text-lg font-bold">Size</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="border-b odd:bg-white even:bg-[#FAF6ED]">
                        <td className="p-2 text-left font-semibold"></td>
                        <td className="p-2 text-left group flex font-semibold">
                          <div className="flex flex-grow">
                          </div>
                          <div className="flex space-x-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                            <button type="button" >
                              <img src={Edit} alt="add" className="w-4 h-4" type="button"  />
                            </button>
                            <button >
                              <img src={dele} alt="delete" className="w-4 h-4"/>
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  className="border border-[#FAF6ED] border-r-4 border-l-4 border-b-4 border-t-4 rounded-lg p-2 flex-1  h-12 focus:outline-none" style={{width: '234px', height: '45px'}}
                  placeholder="Search Formula.."
                />
                <button className="-ml-6 mt-5 transform -translate-y-1/2 text-gray-500">
                  <img src={Search} alt='search' className=' w-5 h-5' />
                </button>
                <button className="text-black font-bold ml-4 px-1 rounded border-dashed border-b-2 border-[#BF9853]">
                  + Add
                </button>
              </div>
              <button  className="text-[#E4572E] font-semibold  flex"><img src={port} alt='import' className=' w-7 h-5 bg-transparent pr-2 mt-1' /><h1 className='mt-1.5'>Import file</h1></button>
              <div className='rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853]'style={{width: '290px', height: '48px'}}>
                <table className="table-auto  ">
                  <thead className='bg-[#FAF6ED]'>
                    <tr className="border-b">
                      <th className="p-2 text-left w-16 text-lg font-bold">SI.No</th>
                      <th className="p-2 text-left w-72 text-lg font-bold">Formula</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr  className="border-b odd:bg-white even:bg-[#FAF6ED]">
                        <td className="p-2 text-left font-semibold"></td>
                        <td className="p-2 text-left group flex font-semibold">
                          <div className="flex flex-grow">
                          </div>
                          <div className="flex space-x-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
                            <button type="button">
                              <img src={Edit} alt="edit" className="w-4 h-4" />
                            </button>
                            <button type="button" >
                              <img src={dele} alt="delete" className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {areaEdit && (
            <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]">
                    <img  alt='cross' className='w-5 h-5' />
                  </button>
                </div>
                <label className="block text-xl font-medium mb-2 -ml-72">Area Name</label>
                <input
                  type="text"
                  className="w-96 -ml-2 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded h-14 focus:outline-none"
                />
                <div className="flex space-x-2 mt-8 ml-12">
                  <button className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold">Save</button>
                  <button className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">Cancel</button>
                </div>
              </div>
            </div>
          )}
          {isTileEditSizeOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white rounded-md w-[37rem] h-[22rem] px-2 py-2">
                <button className="text-red-500 ml-[95%]">
                  <img alt="close" className="w-5 h-5" />
                </button>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-[29rem]">Tile Size</label>
                    <input
                      type="text"
                      name="tileSize"
                      className="w-[30rem] -ml-16 border p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter here.."
                      required
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="mb-4">
                      <label className="block text-xl font-medium mb-2 -ml-[6rem]">Quantity/Box</label>
                      <input
                        type="text"
                        name="quantityBox"
                        className="w-60 ml-4 border p-2 rounded h-14 focus:outline-none"
                        placeholder="Enter here.."
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-xl font-medium mb-2 -ml-28">Tile Area/Sqft</label>
                      <input
                        type="text"
                        name="areaTile"
                        className="w-60 ml-2 border p-2 rounded h-14 focus:outline-none"
                        placeholder="Enter Sqft"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button type="submit" className="bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold">
                      Submit
                    </button>
                    <button type="button" className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isEditFloorOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]">
                    <img alt='close' className='w-5 h-5' />
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2">Floor Name</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter Floor Name"
                      required
                    />
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isEditFloorTypeOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]" >
                    <img alt='close' className='w-5 h-5' />
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2">Floor Type</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter Floor Type"
                      required
                    />
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
    
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
              <div className="bg-white rounded-md w-[30rem] h-96 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]">
                    <img  alt='cross' className='w-5 h-5' />
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-72">Tile Name</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded-lg h-14 focus:outline-none"
                      placeholder="Enter Tile Name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-72">Tile Size</label>
                    <select
                      className="w-40 rounded-lg border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2  -ml-52 h-12 focus:outline-none"
                      required
                    >
                      <option value="">Select Size</option>
                    </select>
                  </div>
                  <div className="flex items-center space-x-2 ml-12 -mb-4">
                    <label htmlFor="fileInput" className="cursor-pointer flex items-center text-orange-600 font-bold">
                      <img  alt='attach' className=' w-5 h-5' />
                      <h1 className='ml-4 text-lg'>Attach file</h1>
                    </label>
                    <input type="file" id="fileInput" className="hidden"  />
                    {selectedFile && <span className="text-gray-600">{selectedFile.name}</span>}
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isAreaNameOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]">
                    <img  alt='cross' className='w-5 h-5' />
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-72">Area Name</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter Area Name"
                      required
                    />
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isFloorNameOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]">
                    <img alt='cross' className='w-5 h-5' />
                  </button>
                </div>
                <form >
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-72">Floor Name</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter Floor Name"
                      required
                    />
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isFloorTypeOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
              <div className="bg-white rounded-md w-[30rem] h-60 px-2 py-2">
                <div>
                  <button className="text-red-500 ml-[95%]" >
                    <img alt='cross' className='w-5 h-5' />
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-72">Floor Name</label>
                    <input
                      type="text"
                      className="w-96 ml-4 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter Floor Type"
                      required
                    />
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button
                      type="submit"
                      className="btn bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isTileSizeOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white rounded-md w-[37rem] h-[22rem] px-2 py-2">
                <button className="text-red-500 ml-[95%]">
                  <img  alt="close" className="w-5 h-5" />
                </button>
                <form >
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-[29rem]">Tile Size</label>
                    <input
                      type="text"
                      name="tileSize"
                      className="w-[30rem] -ml-16 border p-2 rounded h-14 focus:outline-none"
                      placeholder="Enter here.."
                      required
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="mb-4">
                      <label className="block text-xl font-medium mb-2 -ml-[6rem]">Quantity/Box</label>
                      <input
                        type="text"
                        name="quantityBox"
                        className="w-60 ml-4 border p-2 rounded h-14 focus:outline-none"
                        placeholder="Enter here.."
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-xl font-medium mb-2 -ml-32">Tile Area/Sqft</label>
                      <input
                        type="text"
                        name="areaTile"
                        className="w-60 ml-4 border p-2 rounded h-14 focus:outline-none"
                        placeholder="Enter Sqft"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 mt-8 ml-12">
                    <button type="submit" className="bg-[#BF9853] text-white px-8 py-2 rounded-lg hover:bg-yellow-800 font-semibold">
                      Submit
                    </button>
                    <button type="button" className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]" onClick={closeTileSize}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isEditModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white rounded-md w-[28rem] p-6 relative">
                <button
                  className="absolute top-4 right-4 text-red-500"
                >
                  <img alt="Close" className="w-5 h-5" />
                </button>
                <form>
                  <div className="mb-4">
                    <label className="block text-xl font-medium mb-2 -ml-80">Tile Name</label>
                    <input
                      type="text"
                      name="tileName"
                      className="w-full border p-2 rounded-lg h-14 focus:outline-none"
                      required
                    />
                  </div>
                  <div className='flex justify-between'>
                    <div className="mb-4">
                      <label className="block text-xl font-medium mb-2 -ml-32">Tile Size</label>
                      <select
                        name="tileSize"
                        className="w-52 border p-2 rounded-lg h-14 focus:outline-none"
                        required
                      >
                          <option></option>
                      </select>
                    </div>
                    {editTileData.image && (
                      <img
                        src={`data:image/png;base64,${editTileData.image}`}
                        alt="Preview"
                        className=" h-28 w-40 object-cover"
                      />
                    )}
    
                  </div>
                  <div className="mb-4 flex justify-between">
                    <div className="flex items-center space-x-2">
                      <label htmlFor="fileInput" className="cursor-pointer flex items-center text-orange-600 font-bold">
                        <img alt='attach' className=' w-5 h-5' />
                        <h1 className='ml-4 text-lg'>Attach file</h1>
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                      />
                      {selectedFile && <span className="text-gray-600">{selectedFile.name}</span>}
                    </div>
                  </div>
                  <div className="flex space-x-2 mt-8">
                    <button type="submit" className="bg-[#BF9853] text-white px-8 py-2 rounded-lg font-semibold">
                      Save
                    </button>
                    <button
                      type="button"
                      className="px-8 py-2 border rounded-lg text-[#BF9853] border-[#BF9853]"
                      onClick={() => setIsEditModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {confirmDelete && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded">
                <h2>Confirm Deletion</h2>
                <p>Are you sure you want to delete this tile?</p>
                <div className="flex space-x-4">
                  <button className="bg-red-500 text-white p-2 rounded">
                    Yes, Delete
                  </button>
                  <button className="bg-gray-300 p-2 rounded">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
}

export default RcAddinput
