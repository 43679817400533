import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import edit from '../Images/edit.png';
import history from '../Images/history.png';
import remove from '../Images/delete.png';
import clear from '../Images/dust.png';
import Swal from 'sweetalert2';
import up_arrow from '../Images/up-arrow.png';
import down_arrow from '../Images/arrow-down.png';
Modal.setAppElement('#root');
const DatabaseExpenses = ({ username }) => {
    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [editId, setEditId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);
    const [audits, setAudits] = useState([]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [contractorOptions, setContractorOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [machineToolsOptions, setMachineToolsOptions] = useState([]);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedContractor, setSelectedContractor] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [enoOptions, setEnoOptions] = useState([]);
    const [selectedEno, setSelectedEno] = useState('');
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [selectedMachineTools, setSelectedMachineTools] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    // Function to sort by date and toggle between asc and desc
    const handleSortByDate = () => {
        const sortedExpenses = [...filteredExpenses].sort((a, b) => {
            const dateA = new Date(a.timestamp);  // Sorting based on timestamp
            const dateB = new Date(b.timestamp);
    
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
    
        setFilteredExpenses(sortedExpenses); // Update the filteredExpenses after sorting
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sorting order
    };
    const [formData, setFormData] = useState({
        accountType: '',
        date: '',
        siteName: '',
        vendor: '',
        quantity: '',
        contractor: '',
        amount: '',
        category: '',
        otherVendorName: '',
        otherContractorName: '',
        machineTools: '',
        billCopy: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useEffect(() => {
        axios
            .get('https://backendaab.in/aabuildersDash/expenses_form/get_form')
            .then((response) => {
                const sortedExpenses = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setExpenses(sortedExpenses);
                setFilteredExpenses(sortedExpenses);

                // Extract unique values for the dropdowns
                const uniqueEnos = [...new Set(response.data.map(expense => expense.eno))];
                const uniqueSites = [...new Set(response.data.map(expense => expense.siteName))];
                const uniqueVendors = [...new Set(response.data.map(expense => expense.vendor))];
                const uniqueContractors = [...new Set(response.data.map(expense => expense.contractor))];
                const uniqueCategories = [...new Set(response.data.map(expense => expense.category))];
                const uniqueAccountTypes = [...new Set(response.data.map(expense => expense.accountType))];
                const uniqueMachineTools = [...new Set(response.data.map(expense => expense.machineTools))];

                // Set the unique dropdown options in state
                setEnoOptions(uniqueEnos);
                setSiteOptions(uniqueSites);
                setVendorOptions(uniqueVendors);
                setContractorOptions(uniqueContractors);
                setCategoryOptions(uniqueCategories);
                setAccountTypeOptions(uniqueAccountTypes);
                setMachineToolsOptions(uniqueMachineTools);
            })
            .catch((error) => {
                console.error('Error fetching expenses:', error);
            });
    }, []);

    useEffect(() => {
        const filtered = expenses.filter(expense => {
            return (
                (selectedSiteName ? expense.siteName === selectedSiteName : true) &&
                (selectedVendor ? expense.vendor === selectedVendor : true) &&
                (selectedContractor ? expense.contractor === selectedContractor : true) &&
                (selectedCategory ? expense.category === selectedCategory : true) &&
                (selectedMachineTools ? expense.machineTools === selectedMachineTools : true) &&
                (selectedAccountType ? expense.accountType === selectedAccountType : true) &&
                (selectedDate ? expense.date === selectedDate : true) &&
                (selectedEno ? String(expense.eno) === String(selectedEno) : true)  // Ensure types match
            );
        });
        setFilteredExpenses(filtered);
    }, [selectedSiteName, selectedVendor, selectedContractor, selectedCategory, selectedMachineTools, selectedEno, selectedAccountType, selectedDate, expenses]);
    const handleFilterChange = (setter) => (e) => {
        setter(e.target.value);
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const formatDateOnly = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();

        return `${day}/${month}/${year}`; // Formats the date to DD/MM/YYYY
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageChange = (direction) => {
        if (direction === 'next' && indexOfLastItem < filteredExpenses.length) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleEditClick = (expense) => {
        setEditId(expense.id);
        setFormData(expense);
        setModalIsOpen(true);
    };

    const handleSave = () => {
        axios.put(`https://backendaab.in/aabuildersDash/expenses_form/update/${editId}`, formData, {
            params: { editedBy: username } // Pass username as `editedBy`
        })
            .then(response => {
                setExpenses(expenses.map(exp => (exp.id === editId ? { ...exp, ...formData } : exp)));
                setModalIsOpen(false);
            })
            .catch(error => {
                console.error('Error updating expense:', error);
            });
    };
    // Function to handle clearing all data for a given row
    const handleClearAll = (expenseId) => {
        // Display a SweetAlert2 confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to clear all data for this expense?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'bg-red-500 text-white px-4 py-2 rounded',
                cancelButton: 'bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2',
                title: 'text-lg font-semibold',
                popup: 'p-6 rounded-lg shadow-lg',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedExpense = expenses.find(exp => exp.id === expenseId);
                // Set all relevant fields to empty strings
                const clearedExpense = {
                    ...updatedExpense,
                    siteName: '',
                    vendor: '',
                    contractor: '',
                    quantity: '',
                    amount: '',
                    category: '',
                    accountType: '',
                    machineTools: '',
                    billCopy: '',
                };
                // Update the state with the modified expenses list
                setExpenses(expenses.map(exp => (exp.id === expenseId ? clearedExpense : exp)));
                // Update the backend to reflect the cleared data
                axios.put(`https://backendaab.in/aabuildersDash/expenses_form/update/${expenseId}`, clearedExpense, {
                    params: { editedBy: username } // Pass username as `editedBy`
                })
                    .then(response => {
                        console.log('Expense cleared successfully');
                        Swal.fire('Cleared!', 'Your data has been cleared.', 'success');
                    })
                    .catch(error => {
                        console.error('Error clearing expense:', error);
                        Swal.fire('Error!', 'There was a problem clearing the data.', 'error');
                    });
            }
        });
    };
    const handleCancel = () => {
        setModalIsOpen(false);
    };
    const fetchAuditDetails = async (expenseId) => {
        try {
            const response = await fetch(`https://backendaab.in/aabuildersDash/expenses_form/audit/${expenseId}`);
            const data = await response.json();
            setAudits(data);
            setSelectedExpenseId(expenseId);
            console.log(selectedExpenseId)
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching audit details:", error);
        }
    };
    const clearFilters = () => {
        setSelectedSiteName('');
        setSelectedVendor('');
        setSelectedContractor('');
        setSelectedCategory('');
        setSelectedMachineTools('');
        setSelectedAccountType('');
        setSelectedDate('');
        setSelectedEno('');  // Clear E.No filter
        setFilteredExpenses(expenses); // Reset the filtered expenses to the original list
    };
    return (
        <body className=' bg-yellow-50'>
            <div>
                <div className="mx-auto p-4 bg-white ml-3">
                    <div>
                        <div className="rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853] max-w-screen-2xl overflow-x-scroll table-auto min-w-full">
                            <table className="">
                                <thead className=' bg-orange-50'>
                                    <tr>
                                        <th className="px-2 flex mt-3 w-40">Timestamp
                                            <button
                                                onClick={handleSortByDate}
                                                className=' ml-8 w-3 h-7'
                                            >
                                                {sortOrder === 'asc' ? (
                                                    <img src={up_arrow} alt='asc'></img> // Up arrow for ascending
                                                ) : (
                                                    <img src={down_arrow} alt='dsc'/> // Down arrow for descending
                                                )}
                                            </button>
                                        </th>
                                        <th className="px-2">
                                            <input
                                                type="date"
                                                className=" p-1 rounded bg-transparent w-32"
                                                value={selectedDate}
                                                onChange={(e) => handleFilterChange(setSelectedDate)(e)}
                                                placeholder="Date"
                                            />
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className=" p-1 rounded bg-transparent w-48 border-none"
                                                value={selectedSiteName}
                                                onChange={handleFilterChange(setSelectedSiteName)}
                                            >
                                                <option value="">Site Name</option>
                                                {siteOptions.map((site, index) => (
                                                    <option key={index} value={site}>
                                                        {site}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className="p-1 rounded bg-transparent w-40"
                                                value={selectedVendor}
                                                onChange={handleFilterChange(setSelectedVendor)}
                                            >
                                                <option value="">Vendor</option>
                                                {vendorOptions.map((vendor, index) => (
                                                    <option key={index} value={vendor}>
                                                        {vendor}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className="p-1 rounded bg-transparent w-40"
                                                value={selectedContractor}
                                                onChange={handleFilterChange(setSelectedContractor)}
                                            >
                                                <option value="">Contractor</option>
                                                {contractorOptions.map((contractor, index) => (
                                                    <option key={index} value={contractor}>
                                                        {contractor}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">Quantity</th>
                                        <th className="px-2">Amount</th>
                                        <th className="px-2">Comments</th>
                                        <th className="px-2">
                                            <select
                                                className=" p-1 rounded bg-transparent w-40"
                                                value={selectedCategory}
                                                onChange={handleFilterChange(setSelectedCategory)}
                                            >
                                                <option value="">Category</option>
                                                {categoryOptions.map((category, index) => (
                                                    <option key={index} value={category}>
                                                        {category}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className=" p-1 rounded bg-transparent w-20"
                                                value={selectedEno}
                                                onChange={(e) => setSelectedEno(e.target.value)}
                                            >
                                                <option value="">E.No</option>
                                                {enoOptions.map((eno, index) => (
                                                    <option key={index} value={eno}>
                                                        {eno}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className=" p-1 rounded bg-transparent w-32"
                                                value={selectedAccountType}
                                                onChange={handleFilterChange(setSelectedAccountType)}
                                            >
                                                <option value="">A/C Type</option>
                                                {accountTypeOptions.map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2">
                                            <select
                                                className="ml-2 p-1 rounded bg-transparent w-40"
                                                value={selectedMachineTools}
                                                onChange={handleFilterChange(setSelectedMachineTools)}
                                            >
                                                <option value="">Machine Tools</option>
                                                {machineToolsOptions.map((tool, index) => (
                                                    <option key={index} value={tool}>
                                                        {tool}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>
                                        <th className="px-2 ">Bill Copy</th>
                                        <th className="px-2 ">Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((expense, index) => (
                                        <tr key={expense.id} className='odd:bg-white even:bg-orange-50'>
                                            <td className="px-2 text-sm text-left">{formatDate(expense.timestamp)}</td>
                                            <td className="px-2 text-sm text-left">{formatDateOnly(expense.date)}</td>
                                            <td className="px-2 text-sm text-left">{expense.siteName}</td>
                                            <td className="px-2 text-sm text-left">{expense.vendor}</td>
                                            <td className="px-2 text-sm text-left">{expense.contractor}</td>
                                            <td className="px-2 text-sm text-left">{expense.quantity}</td>
                                            <td className="px-2 text-sm text-left">{parseInt(expense.amount, 10).toLocaleString()}</td>
                                            <td className="px-2 text-sm text-left">{expense.comments}</td>
                                            <td className="px-2 text-sm text-left">{expense.category}</td>
                                            <td className="px-2 text-sm text-left">{expense.eno}</td>
                                            <td className="px-2 text-sm text-left">{expense.accountType}</td>
                                            <td className="px-2 text-sm text-left">{expense.machineTools}</td>
                                            <td className="px- text-sm">
                                                {expense.billCopy ? (
                                                    <a href={expense.billCopy} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                                                        View
                                                    </a>
                                                ) : (
                                                    <span className="text-red-500"></span>
                                                )}
                                            </td>
                                            <td className=" flex w-20 justify-between py-2">
                                                <button
                                                    onClick={() => handleEditClick(expense)}
                                                    className="hover:bg-red-200 rounded-full transition duration-200 ml-2 mr-3"
                                                >
                                                    <img
                                                        src={edit}
                                                        alt="Edit"
                                                        className=" w-4 h-6 transform hover:scale-110 hover:brightness-110 transition duration-200 "
                                                    />
                                                </button>
                                                <button
                                                    className=" -ml-5 -mr-2"
                                                    onClick={() => handleClearAll(expense.id)}
                                                >
                                                    <img
                                                        src={remove}
                                                        alt='delete'
                                                        className='  w-4 h-5 transform hover:scale-110 hover:brightness-110 transition duration-200 ' />
                                                </button>
                                                <button
                                                    onClick={() => fetchAuditDetails(expense.id)}
                                                    className="rounded-full transition duration-200 -mr-1"
                                                >
                                                    <img
                                                        src={history}
                                                        alt="history"
                                                        className=" w-4 h-5 transform hover:scale-110 hover:brightness-110 transition duration-200 "
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination Controls */}
                        {/* Modal for editing expense */}
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={handleCancel}
                            contentLabel="Edit Expense"
                            className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
                            overlayClassName="fixed inset-0"
                        >
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-72 max-h-[70vh] overflow-y-auto sm:w-11/12 sm:max-w-xl">
                                <h2 className="text-xl font-bold mb-4">Edit Expense</h2>
                                <form>
                                    <div className="mb-4">
                                        <label htmlFor="date" className="block text-gray-700">Date</label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="siteName" className="block text-gray-700">Site Name</label>
                                        <select
                                            name="siteName"
                                            value={formData.siteName} // This is the selected value
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        >
                                            {/* Dynamically generate dropdown options from the siteName list */}
                                            {siteOptions
                                                .filter(site => site !== null && site !== '')
                                                .map((site, index) => (
                                                    <option key={index} value={site}>
                                                        {site}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="vendor" className="block text-gray-700">Vendor</label>
                                        <select
                                            name="vendor"
                                            value={formData.vendor}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        >
                                            {vendorOptions
                                                .filter(vendor => vendor !== null && vendor !== '')
                                                .map((vendor, index) => (
                                                    <option key={index} value={vendor}>
                                                        {vendor}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="contractor" className="block text-gray-700">Contractor</label>
                                        <select
                                            name="contractor"
                                            value={formData.contractor}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        >
                                            {contractorOptions
                                                .filter(contractor => contractor !== null && contractor !== '')
                                                .map((contractor, index) => (
                                                    <option key={index} value={contractor}>
                                                        {contractor}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="quantity" className="block text-gray-700">Quantity</label>
                                        <input
                                            type="text"
                                            name="quantity"
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="amount" className="block text-gray-700">Amount</label>
                                        <input
                                            type="number"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                            style={{
                                                WebkitAppearance: "none",
                                                MozAppearance: "textfield",
                                            }}
                                            onWheel={(e) => e.target.blur()} // This disables the scroll behavior
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="category" className="block text-gray-700">Category</label>
                                        <select
                                            name="category"
                                            value={formData.category}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        >
                                            {categoryOptions
                                                .filter(category => category !== null && category !== '')
                                                .map((category, index) => (
                                                    <option key={index} value={category}>
                                                        {category}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="otherContractorName" className="block text-gray-700">Account_Type</label>
                                        <input
                                            type="text"
                                            name="accountType"
                                            value={formData.accountType}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="machineTools" className="block text-gray-700">Machine Tools</label>
                                        <select
                                            name="machineTools"
                                            value={formData.machineTools}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        >
                                            {machineToolsOptions
                                                .filter(machineTools => machineTools !== null && machineTools !== '')
                                                .map((machineTools, index) => (
                                                    <option key={index} value={machineTools}>
                                                        {machineTools}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="billCopy" className="block text-gray-700">Bill Copy URL</label>
                                        <input
                                            type="text"
                                            name="billCopy"
                                            value={formData.billCopy}
                                            onChange={handleChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <button
                                            type="button"
                                            onClick={handleCancel}
                                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleSave}
                                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                        {/* Audit Modal */}
                        <AuditModal show={showModal} onClose={() => setShowModal(false)} audits={audits} />
                    </div>
                </div>
                <div className="mt-4 flex justify-center items-center">
                    <button
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 1}
                        className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                    >
                        Previous
                    </button>
                    <span className="text-lg">
                        Page {currentPage} of {Math.ceil(filteredExpenses.length / itemsPerPage)}
                    </span>
                    <button
                        onClick={() => handlePageChange('next')}
                        disabled={indexOfLastItem >= filteredExpenses.length}
                        className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                    >
                        Next
                    </button>
                    <div className="relative group">
                        <button
                            className="py-2 px-4 rounded"
                            onClick={clearFilters}
                        >
                            <img
                                src={clear}
                                alt='clear'
                                className="w-5 h-6 transform hover:scale-110 hover:brightness-110 transition duration-200"
                            />
                        </button>
                        <span className="absolute w-20 h-7 left-1/2 transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 text-xs font-medium text-black rounded-md bg-[#BF9853] opacity-0 group-hover:opacity-100 transition-opacity text-center">
                            Clear filter
                        </span>
                    </div>
                </div>
            </div>
        </body>
    );
};
export default DatabaseExpenses;

// Helper function to format the date and time in 12-hour format with AM/PM and adjust time zone
const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Add 5 hours and 30 minutes (330 minutes in total) to the time
    date.setMinutes(date.getMinutes() + 330);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, '0') : '12'; // the hour '0' should be '12'

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`; // Formats the date to DD/MM/YYYY HH:MM AM/PM
};


const AuditModal = ({ show, onClose, audits }) => {
    if (!show) return null;
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-3/4 max-w-2xl mx-4 p-4">
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-bold text-gray-700">Expenses Edited History</h2>
                </div>
                {/* Set a max height and overflow-y for the table container */}
                <div className="overflow-y-auto mt-4 max-h-80 overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-red-100">
                            <tr>
                                <th className="border-b px-1 py-2 text-left text-sm font-medium text-gray-700">Field Name</th>
                                <th className="border-b px-1 py-2 text-left text-sm font-medium text-gray-700">Old Value</th>
                                <th className="border-b px-1 py-2 text-left text-sm font-medium text-gray-700">New Value</th>
                                <th className="border-b px-1 py-2 text-left text-sm font-medium text-gray-700">Edited By</th>
                                <th className="border-b px-1 py-2 text-left text-sm font-medium text-gray-700">Edited Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {audits.map((audit, index) => (
                                <tr key={index} className="odd:bg-white even:bg-red-200">
                                    <td className="border-b border-r px-1 py-2 text-sm text-gray-600">{audit.fieldName}</td>
                                    <td className="border-b border-r px-1 py-2 text-sm text-gray-600" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {audit.oldValue}
                                    </td>
                                    <td className="border-b border-r px-1 py-2 text-sm text-gray-600" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {audit.newValue}
                                    </td>
                                    <td className="border-b border-r px-1 py-2 text-sm text-gray-600">{audit.editedBy}</td>
                                    <td className="border-b px-1 py-2 text-sm text-gray-600">{formatDate(audit.editedDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        onClick={onClose}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};