import React, { useState, useEffect } from 'react';
import TileCalculation from './TileCalculator';
import DTableview from './DTableView';
import TileHistory from './TileHistory';
import './DHeading.css';

const DHeading = () => {
    // Get the last active tab from localStorage or default to 'tileCalculate'
    const [activeTab, setActiveTab] = useState(
        localStorage.getItem('activeTab') || 'tileCalculate'
    );

    useEffect(() => {
        // Save the active tab to localStorage whenever it changes
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    const renderContent = () => {
        switch (activeTab) {
            case 'tileCalculate':
                return <TileCalculation />;
            case 'tileHistory':
                return <TileHistory />;
            case 'tileTableView':
                return <DTableview />;
            default:
                return <TileCalculation />;
        }
    };

    return (
        <div className="bg-[#FAF6ED]">
            <div className="topbar-title">
                <h2
                    className={`link ${activeTab === 'tileCalculate' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tileCalculate')}
                >
                    Tile Calculation
                </h2>
                <h2
                    className={`link ${activeTab === 'tileHistory' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tileHistory')}
                >
                    History
                </h2>
                <h2
                    className={`link ${activeTab === 'tileTableView' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tileTableView')}
                >
                    Add Input
                </h2>
            </div>
            <div className="content">
                {renderContent()}
            </div>
        </div>
    );
};

export default DHeading;