import React, { useState, useRef, useEffect } from 'react';
import logo from '../Images/aablogo.png';
import Sidebar from './Sidebar';

const Navbar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);

  // Toggle sidebar visibility when clicking the logo
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  // Close sidebar if clicking outside of it
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarVisible(false);
    }
  };

  // Attach event listener for clicks outside
  useEffect(() => {
    if (isSidebarVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarVisible]);

  return (
    <>
      <nav className="navbar fixed w-full top-0 z-10 bg-white h-14">
        <div className="flex">
          <img
            src={logo}
            alt="Logo"
            className="cursor-pointer w-[42px] h-[40px] rounded-full ml-2 my-2"
            onClick={toggleSidebar}
          />
          <div className="container flex justify-between items-center">
            <p className="text-yellow-500 ml-1 mt-1 font-medium text-lg">BUILDERS</p>
          </div>
        </div>
      </nav>
      <Sidebar isVisible={isSidebarVisible} sidebarRef={sidebarRef} />
    </>
  );
};

export default Navbar;
