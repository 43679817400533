import React from 'react';

const HomePage = () => {
  return (
    <div className=' bg-gray-300'>
      <h3>Welcome to the Home Page!</h3>
      <p>This is the default landing page for your application.</p>
    </div>
  );
};

export default HomePage;
