import React, { useState, useEffect } from "react";
import Select from "react-select";
import add from '../Images/adding.png';
import deleteIcon from '../Images/Delete.svg';
import edit from '../Images/Edit.svg'
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { Link } from "react-router-dom";

const Interior = () => {
    const [interiorFloors, setInteriorFloors] = useState([
        {
            floorName: "Ground Floor",
            areaName: "",
            tiles: [
                { length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0", putty: "No", primer: "No", ceilingCoat: "No", waterproof: "No", paintVariant: "", colorCode: "", orderQty: "" },
            ],
        },
    ]);
    const [exteriorFloors, setExteriorFloors] = useState([
        {
            floorName: "Ground Floor",
            areaName: "",
            tiles: [
                { length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0", putty: "No", primer: "No", ceilingCoat: "No", waterproof: "No", paintVariant: "", colorCode: "", orderQty: "" },
            ],
        },
    ]);
    const [paintCalculations, setPaintCalculations] = useState([]);
    const [filteredPaintCalculations, setFilteredPaintCalculations] = useState([]);
    useEffect(() => {
        fetchPaintCalculations();
    }, []);
    const fetchPaintCalculations = async () => {
        try {
            const response = await fetch("https://backendaab.in/aabuilderDash/api/paint_calculation/all/paints");
            if (response.ok) {
                const data = await response.json();
                console.log("Fetched Paint Calculations:", data);
                setPaintCalculations(data);
                setFilteredPaintCalculations(data);
            } else {
                console.error("Error fetching paint calculations:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    return (
        <div>
            <div className=" mx-auto p-6 border-collapse bg-[#FFFFFF] ml-6 mr-6 rounded-md">
                <div className=" flex">
                    <div className=" flex">
                        <div className="w-full -mt-8 mb-4">
                            <h4 className=" mt-10 font-bold mb-2 -ml-60">Site Name </h4>
                            <Select
                                placeholder="Select Site Name..."
                                className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-80 h-12 text-left"

                                isClearable />
                        </div>
                        <input
                            type="text"
                            readOnly
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg h-12 w-16 mt-10 ml-1 bg-transparent text-center"
                        />
                    </div>
                    <div className=" ml-6 mt-1">
                        <h4 className=" font-bold mb-2 -ml-32">Date </h4>
                        <input
                            type="date"
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] w-44 rounded-lg px-4 py-2 "
                        />
                    </div>
                    <div>
                        <h4 className=" mt-1.5 font-bold -ml-20"> E No</h4>
                        <input
                            className="bg-gray-100 rounded-lg w-36 h-12 mt-2 ml-2 pl-4"
                            readOnly
                        />
                    </div>
                    <div className=" ml-6">
                        <h4 className=" mt-1.5 font-bold mb-2 -ml-32"> Revision</h4>
                        <Select
                            placeholder="Select the file..."
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-52 h-12"
                            isClearable
                        />
                    </div>
                </div>
            </div>
            <div className="mt-6 bg-[#FFFFFF] ml-6 mr-6 p-6">
                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                    <table className="min-w-full">
                        <thead className="bg-[#FAF6ED]">
                            <tr>
                                <th className="px-4 py-2 text-left font-semibold">Sl.No</th>
                                <th className="px-4 py-2 text-left font-semibold">Date</th>
                                <th className="px-4 py-2 text-left font-semibold">Site Name</th>
                                <th className="px-4 py-2 text-left font-semibold">E. No</th>
                                <th className="px-4 py-2 text-left font-semibold">PMS Revision</th>
                                <th className="px-4 py-2 text-left font-semibold">No of litre</th>
                                <th className="px-4 py-2 text-left font-semibold">Total Sqft</th>
                                <th className="px-4 py-2 text-left font-semibold">File</th>
                                <th className="px-4 py-2 text-left font-semibold">Print</th>
                                <th className="px-4 py-2 text-left font-semibold">Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPaintCalculations.map((item, index) => (
                                <tr
                                    key={index}
                                    className={index % 2 === 0 ? "odd:bg-white" : "even:bg-[#FAF6ED]"}
                                >
                                    <td className="px-4 py-2 text-left font-semibold">{item.ENo}</td>
                                    <td className="px-4 py-2 text-left font-semibold">{item.date}</td>
                                    <td className="px-4 py-2 text-left font-semibold">{item.clientName}</td>
                                    <td className="px-4 py-2 text-left font-semibold">{item.eno}</td>
                                    <td className="px-4 py-2 text-left font-semibold">{item.fileName}</td>
                                    <td className="px-4 py-2 text-left font-semibold">
                                        {item.paintCalculations.reduce((total, calc) => total + calc.paintTiles.reduce((tileTotal, tile) => tileTotal + tile.orderQty, 0), 0)}
                                    </td>
                                    <td className="px-4 py-2 text-left font-semibold">
                                        {item.paintCalculations.reduce((total, calc) => total + calc.paintTiles.reduce((tileTotal, tile) => tileTotal + tile.orderQty * tile.length * tile.breadth, 0), 0)}
                                    </td>
                                    <td className="px-4 py-2 text-left font-semibold text-red-500 underline">
                                        <a href="#">View</a>
                                    </td>
                                    <td className="px-4 py-2 text-left">🖨️</td>
                                    <td className="px-4 py-2 text-left flex">
                                        <img src={edit} alt="Edit" />
                                        <img src={deleteIcon} alt="Delete" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>


    )
}
export default Interior;