import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import edit from '../Images/edit.png';
import clear from '../Images/dust.png';
Modal.setAppElement('#root');

const TableViewExpense = ({ username }) => {
    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [editId, setEditId] = useState(null);
    const [siteOptions, setSiteOptions] = useState([]);
    const [enoOptions, setEnoOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [contractorOptions, setContractorOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedEno, setSelectedEno] = useState('');
    const [machineToolsOptions, setMachineToolsOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedContractor, setSelectedContractor] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMachineTools, setSelectedMachineTools] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState('');
    
    const [formData, setFormData] = useState({
        accountType: '',
        date: '',
        siteName: '',
        vendor: '',
        quantity: '',
        contractor: '',
        amount: '',
        category: '',
        otherVendorName: '',
        otherContractorName: '',
        machineTools: '',
        billCopy: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useEffect(() => {
        axios
            .get('https://backendaab.in/aabuildersDash/expenses_form/get_form')
            .then((response) => {
                const sortedExpenses = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setExpenses(sortedExpenses);
                setFilteredExpenses(sortedExpenses);

                // Extract unique values for the dropdowns
                const uniqueEnos = [...new Set(response.data.map(expense => expense.eno))];
                const uniqueSites = [...new Set(response.data.map(expense => expense.siteName))];
                const uniqueVendors = [...new Set(response.data.map(expense => expense.vendor))];
                const uniqueContractors = [...new Set(response.data.map(expense => expense.contractor))];
                const uniqueCategories = [...new Set(response.data.map(expense => expense.category))];
                const uniqueAccountTypes = [...new Set(response.data.map(expense => expense.accountType))];
                const uniqueMachineTools = [...new Set(response.data.map(expense => expense.machineTools))];

                // Set the unique dropdown options in state
                setEnoOptions(uniqueEnos);
                setSiteOptions(uniqueSites);
                setVendorOptions(uniqueVendors);
                setContractorOptions(uniqueContractors);
                setCategoryOptions(uniqueCategories);
                setAccountTypeOptions(uniqueAccountTypes);
                setMachineToolsOptions(uniqueMachineTools);
            })
            .catch((error) => {
                console.error('Error fetching expenses:', error);
            });
    }, []);

    useEffect(() => {
        const filtered = expenses.filter(expense => {
            return (
                (selectedSiteName ? expense.siteName === selectedSiteName : true) &&
                (selectedVendor ? expense.vendor === selectedVendor : true) &&
                (selectedContractor ? expense.contractor === selectedContractor : true) &&
                (selectedCategory ? expense.category === selectedCategory : true) &&
                (selectedMachineTools ? expense.machineTools === selectedMachineTools : true) &&
                (selectedAccountType ? expense.accountType === selectedAccountType : true) &&
                (selectedDate ? expense.date === selectedDate : true) &&
                (selectedEno ? String(expense.eno) === String(selectedEno) : true)  // Ensure types match
            );
        });
        setFilteredExpenses(filtered);
    }, [selectedSiteName, selectedVendor, selectedContractor, selectedCategory, selectedMachineTools, selectedEno, selectedAccountType, selectedDate, expenses]);

    const handleFilterChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handlePageChange = (direction) => {
        if (direction === 'next' && indexOfLastItem < filteredExpenses.length) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleEditClick = (expense) => {
        setEditId(expense.id);
        setFormData(expense);
        setModalIsOpen(true);
    };

    const handleSave = () => {
        axios
            .put(`https://backendaab.in/aabuildersDash/expenses_form/update/${editId}`, formData, {
                params: { editedBy: username }
            })
            .then(() => {
                setExpenses(expenses.map(exp => exp.id === editId ? { ...exp, ...formData } : exp));
                setModalIsOpen(false);
            })
            .catch((error) => {
                console.error('Error updating expense:', error);
            });
    };
    const clearFilters = () => {
        setSelectedSiteName('');
        setSelectedVendor('');
        setSelectedContractor('');
        setSelectedCategory('');
        setSelectedMachineTools('');
        setSelectedAccountType('');
        setSelectedDate('');
        setSelectedEno('');  // Clear E.No filter
        setFilteredExpenses(expenses); // Reset the filtered expenses to the original list
    };
    const handleCancel = () => {
        setModalIsOpen(false);
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const formatDateOnly = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();

        return `${day}/${month}/${year}`; // Formats the date to DD/MM/YYYY
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <body className="bg-yellow-50">
            <div >
                <div className="mx-auto p-4 bg-white ml-3">
                    <div className="rounded-lg border border-gray-200 border-l-8 border-l-[#BF9853] max-w-screen-6xl overflow-x-auto table-auto min-w-full">
                        <table>
                            <thead className="bg-orange-50">
                                <tr>
                                    <th className="px-2">
                                        <input
                                            type="date"
                                            className="p-1 rounded bg-transparent w-32"
                                            value={selectedDate}
                                            onChange={(e) => handleFilterChange(setSelectedDate)(e)}
                                            placeholder="Date"
                                        />
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-48 border-none"
                                            value={selectedSiteName}
                                            onChange={handleFilterChange(setSelectedSiteName)}
                                        >
                                            <option value="">Site Name</option>
                                            {siteOptions.map((site, index) => (
                                                <option key={index} value={site}>
                                                    {site}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-40"
                                            value={selectedVendor}
                                            onChange={handleFilterChange(setSelectedVendor)}
                                        >
                                            <option value="">Vendor</option>
                                            {vendorOptions.map((vendor, index) => (
                                                <option key={index} value={vendor}>
                                                    {vendor}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-40"
                                            value={selectedContractor}
                                            onChange={handleFilterChange(setSelectedContractor)}
                                        >
                                            <option value="">Contractor</option>
                                            {contractorOptions.map((contractor, index) => (
                                                <option key={index} value={contractor}>
                                                    {contractor}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">Quantity</th>
                                    <th className="px-2">Amount</th>
                                    <th className="px-2">Comments</th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-40"
                                            value={selectedCategory}
                                            onChange={handleFilterChange(setSelectedCategory)}
                                        >
                                            <option value="">Category</option>
                                            {categoryOptions.map((category, index) => (
                                                <option key={index} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-20"
                                            value={selectedEno}
                                            onChange={(e) => setSelectedEno(e.target.value)}
                                        >
                                            <option value="">E.No</option>
                                            {enoOptions.map((eno, index) => (
                                                <option key={index} value={eno}>
                                                    {eno}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-32"
                                            value={selectedAccountType}
                                            onChange={handleFilterChange(setSelectedAccountType)}
                                        >
                                            <option value="">A/C Type</option>
                                            {accountTypeOptions.map((type, index) => (
                                                <option key={index} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">
                                        <select
                                            className="p-1 rounded bg-transparent w-40"
                                            value={selectedMachineTools}
                                            onChange={handleFilterChange(setSelectedMachineTools)}
                                        >
                                            <option value="">Machine Tools</option>
                                            {machineToolsOptions.map((tool, index) => (
                                                <option key={index} value={tool}>
                                                    {tool}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                    <th className="px-2">Bill Copy</th>
                                    <th className="px-2">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((expense, index) => (
                                    <tr key={expense.id} className="odd:bg-white even:bg-orange-50">
                                        <td className="px-4 text-sm text-left">{formatDateOnly(expense.date)}</td>
                                        <td className="px-2 text-sm text-left">{expense.siteName}</td>
                                        <td className="px-2 text-sm text-left">{expense.vendor}</td>
                                        <td className="px-2 text-sm text-left">{expense.contractor}</td>
                                        <td className="px-2 text-sm text-left">{expense.quantity}</td>
                                        <td className="px-2 text-sm text-left">{parseInt(expense.amount, 10).toLocaleString()}</td>
                                        <td className="px-4 text-sm text-left">{expense.comments}</td>
                                        <td className="px-2 text-sm text-left">{expense.category}</td>
                                        <td className="px-2 text-sm text-left">{expense.eno}</td>
                                        <td className="px-2 text-sm text-left">{expense.accountType}</td>
                                        <td className="px-2 text-sm text-left">{expense.machineTools}</td>
                                        <td className="px-4 text-sm">
                                            {expense.billCopy ? (
                                                <a
                                                    href={expense.billCopy}
                                                    className="text-blue-500 hover:underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View
                                                </a>
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                        <td className="flex w-20 justify-between py-2">
                                            <button
                                                onClick={() => handleEditClick(expense)}
                                                className="bg-white w-6 ml-7 rounded hover:bg-gray-100">
                                                <img src={edit} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={handleCancel}
                    contentLabel="Edit Expense"
                    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
                    overlayClassName="fixed inset-0"
                >
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-72 max-h-[70vh] overflow-y-auto sm:w-11/12 sm:max-w-xl">
                        <h2 className="text-xl font-bold mb-4">Edit Expense</h2>
                        <form>
                            <div className="mb-4">
                                <label htmlFor="date" className="block text-gray-700">Date</label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="siteName" className="block text-gray-700">Site Name</label>
                                <select
                                    name="siteName"
                                    value={formData.siteName}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    {siteOptions.map((site, index) => (
                                        <option key={index} value={site}>
                                            {site}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="vendor" className="block text-gray-700">Vendor</label>
                                <select
                                    name="vendor"
                                    value={formData.vendor}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    {vendorOptions.map((vendor, index) => (
                                        <option key={index} value={vendor}>
                                            {vendor}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="contractor" className="block text-gray-700">Contractor</label>
                                <select
                                    name="contractor"
                                    value={formData.contractor}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    {contractorOptions.map((contractor, index) => (
                                        <option key={index} value={contractor}>
                                            {contractor}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="quantity" className="block text-gray-700">Quantity</label>
                                <input
                                    type="text"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="amount" className="block text-gray-700">Amount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    value={formData.amount}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    style={{
                                        WebkitAppearance: "none",
                                        MozAppearance: "textfield",
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="category" className="block text-gray-700">Category</label>
                                <select
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    {categoryOptions.map((category, index) => (
                                        <option key={index} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="accountType" className="block text-gray-700">Account Type</label>
                                <input
                                    type="text"
                                    name="accountType"
                                    value={formData.accountType}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="machineTools" className="block text-gray-700">Machine Tools</label>
                                <select
                                    name="machineTools"
                                    value={formData.machineTools}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    {machineToolsOptions.map((tool, index) => (
                                        <option key={index} value={tool}>
                                            {tool}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="billCopy" className="block text-gray-700">Bill Copy URL</label>
                                <input
                                    type="text"
                                    name="billCopy"
                                    value={formData.billCopy}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSave}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
            <div className="mt-4 flex justify-center items-center">
                <button
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                >
                    Previous
                </button>
                <span className="text-lg">
                    Page {currentPage} of {Math.ceil(filteredExpenses.length / itemsPerPage)}
                </span>
                <button
                    onClick={() => handlePageChange('next')}
                    disabled={indexOfLastItem >= filteredExpenses.length}
                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                >
                    Next
                </button>
                <div className="relative group">
                    <button
                        className="py-2 px-4 rounded"
                        onClick={clearFilters}
                    >
                        <img
                            src={clear}
                            alt='clear'
                            className="w-5 h-6 transform hover:scale-110 hover:brightness-110 transition duration-200"
                        />
                    </button>
                    <span className="absolute w-20 h-7 left-1/2 transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 text-xs font-medium text-black rounded-md bg-[#BF9853] opacity-0 group-hover:opacity-100 transition-opacity text-center">
                        Clear filter
                    </span>
                </div>
            </div>
        </body>
    );
};

export default TableViewExpense;
