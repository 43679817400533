import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import delt from '../Images/Worng.svg';
import add from '../Images/Right.svg';
import change from '../Images/change.png';
import refresh from '../Images/refresh.png';
import jsPDF from 'jspdf';
import loadingScreen from '../Images/AAlogoBlackSVG.svg';
import { evaluate } from 'mathjs';
import "jspdf-autotable";
function PopupModal({ isOpen, onClose }) {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg relative">
                <div className="flex justify-center mb-4">
                    <div className="text-green-500 text-5xl">
                        <i className="fas fa-check-circle"></i>
                    </div>
                </div>
                <h2 className="text-center text-lg font-bold mb-2">Your file Saved successfully!</h2>
                <div className="flex justify-center">
                    <button
                        onClick={onClose}
                        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
                    >
                        Okay
                    </button>
                </div>
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                >
                    &times;
                </button>
            </div>
        </div>
    );
}
const DesignTool = () => {
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fullData, setFullData] = useState([]);
    const [tileFloorTypes, setTileFloorTypes] = useState([]);
    const [tileList, setTileList] = useState([]);
    const [tileOptions, setTileOptions] = useState([]);
    const [filteredFileOptions, setFilteredFileOptions] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [isModalOpen, setModalOpen] = useState(false);
    const [isClientName, setIsClientName] = useState(false);
    const [extraClient, setExtraClient] = useState("");
    const [rows, setRows] = useState([
        { tileName: "", size: "", qtyPerBox: "", numberOfBoxes: "" },
    ]);
    const [floors, setFloors] = useState([
        {
            floorName: "Ground Floor",
            areaName: "",
            tiles: [
                { type: "Floor Tile", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" },
            ],
        },
    ]);
    const [eno, setEno] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [clientSNo, setClientSNo] = useState("");
    const [selectedClientData, setSelectedClientData] = useState({});
    const [calculationData, setCalculationData] = useState(null);
    const currentDate = new Date().toLocaleDateString();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileOptions, setFileOptions] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the input box
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsClientName(false); // Close the popup
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleClientAddButtonClick = () => {
        setIsClientName(true);
    }
    const closeClientName = () => {
        setIsClientName(false)
    }
    useEffect(() => {
        const savedClientName = sessionStorage.getItem('clientName');
        const savedClientSNo = sessionStorage.getItem('clientSNo');
        const savedFloors = sessionStorage.getItem('floors');
        const savedFilteredFileOptions = sessionStorage.getItem('filteredFileOptions');
        const savedSelectedFile = sessionStorage.getItem('selectedFile');
        try {
            if (savedClientName) setClientName(JSON.parse(savedClientName));
            if (savedClientSNo) setClientSNo(savedClientSNo);
            if (savedFloors) setFloors(JSON.parse(savedFloors));
            if (savedFilteredFileOptions) setFilteredFileOptions(JSON.parse(savedFilteredFileOptions));
            if (savedSelectedFile) setSelectedFile(JSON.parse(savedSelectedFile));
        } catch (error) {
            console.error("Error parsing sessionStorage data:", error);
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    const handleBeforeUnload = () => {
        sessionStorage.removeItem('clientName');
        sessionStorage.removeItem('clientSNo');
        sessionStorage.removeItem('filteredFileOptions');
        sessionStorage.removeItem('floors');
        sessionStorage.removeItem('rows');
    };
    useEffect(() => {
        if (clientName) sessionStorage.setItem('clientName', JSON.stringify(clientName));
        if (clientSNo) sessionStorage.setItem('clientSNo', clientSNo);
        sessionStorage.setItem('floors', JSON.stringify(floors));
        sessionStorage.setItem('filteredFileOptions', JSON.stringify(filteredFileOptions));
        if (selectedFile) sessionStorage.setItem('selectedFile', JSON.stringify(selectedFile));
    }, [clientName, clientSNo, floors, filteredFileOptions, selectedFile]);
    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await fetch("https://backendaab.in/aabuilderDash/api/expenses/sites", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                if (!response.ok) {
                    throw new Error("Network response was not ok: " + response.statusText);
                }
                const data = await response.json();
                const formattedData = data.map(item => ({
                    value: item.siteName,
                    label: item.siteName,
                    sNo: item.sNo
                }));
                setSiteOptions(formattedData);
            } catch (error) {
                console.error("Fetch error: ", error);
            }
        };
        fetchSites();
    }, []);
    const fetchCalculations = async () => {
        try {
            const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/all');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const formattedOptions = data.map(calculation => ({
                value: calculation.id,
                clientName: calculation.clientName,
                siteName: calculation.siteName,
                label: calculation.fileName,
            }));
            setFullData(data);
            setFileOptions(formattedOptions);
        } catch (error) {
            console.error('Error fetching calculations:', error);
        }
    };
    const fetchLatestEno = async () => {
        try {
            const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/all');
            if (!response.ok) {
                throw new Error('Failed to fetch ENo');
            }
            const data = await response.json();
            if (data.length > 0) {
                const lastEno = Math.max(...data.map(item => item.eno));
                setEno(lastEno + 1);
            } else {
                setEno(100);
            }
        } catch (error) {
            console.error('Error fetching latest ENo:', error);
        }
    };
    useEffect(() => {
        fetchLatestEno();
    }, []);
    const handleSiteChange = (selected) => {
        setClientName(selected);
        setClientSNo(selected ? selected.sNo : "");
        if (selected) {
            const clientNameFromSite = selected.value;
            const filteredOptions = fileOptions.filter(option => option.clientName === clientNameFromSite);

            setFilteredFileOptions(filteredOptions);
            sessionStorage.setItem('clientName', JSON.stringify(selected));
            sessionStorage.setItem('clientSNo', selected.sNo);
            sessionStorage.setItem('filteredFileOptions', JSON.stringify(filteredOptions));
        } else {
            setFilteredFileOptions([]);
            setSelectedFile(null);
            setSelectedClientData({ calculations: [] });
            setFloors([{
                floorName: "Ground Floor",
                areaName: "",
                tiles: [{ type: "Floor Tile", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" }],
            }]);
            setRows([{ tileName: "", size: "", qtyPerBox: "", numberOfBoxes: "" }]);
            sessionStorage.removeItem('clientName');
            sessionStorage.removeItem('clientSNo');
            sessionStorage.removeItem('filteredFileOptions');
            sessionStorage.removeItem('selectedFile');
            sessionStorage.removeItem('selectedClientData');
            sessionStorage.removeItem('floors');
            sessionStorage.removeItem('rows');
        }
    };
    useEffect(() => {
        fetchTileFloorTypes();
    }, []);
    const fetchTileFloorTypes = async () => {
        try {
            const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/floorType');
            if (response.ok) {
                const data = await response.json();
                setTileFloorTypes(data);
            } else {
                console.error('Error fetching tile floor types.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        fetchCalculations();
    }, []);
    const [floorOptions, setFloorOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const calculateSkirtingArea = (tile) => {
        const length = Number(tile.length || 0);
        const breadth = Number(tile.breadth || 0);
        if (tile.type === "Floor Tile") {
            if (tile.isUserChanged) {
                return tile.skirtingArea ?? 0;
            }
            if (tile.skirtingArea === null || tile.skirtingArea === undefined) {
                return ((length + breadth) * 0.33).toFixed(2);
            }

            return tile.skirtingArea;
        }
        return 0;
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/areaName');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const areas = data.map(item => item.areaName);
                setAreaOptions([...new Set(areas)]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/floorName');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const areas = data.map(item => item.floorName);
                setFloorOptions([...new Set(areas)]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const fetchTileData = async () => {
        try {
            const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/quantity/size');
            const data = await response.json();
            setTileList(data);
        } catch (error) {
            console.error("Error fetching tile data:", error);
        }
    };
    useEffect(() => {
        fetchTileData();
    }, []);
    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const summaryMap = {};
    let lastValidFloorName = "";
    floors.forEach((floor) => {
        const currentFloorName = floor.floorName || lastValidFloorName;
        if (floor.floorName) {
            lastValidFloorName = floor.floorName;
        }
        floor.tiles.forEach((tile) => {
            const length = parseFloat(tile.length || 0);
            const breadth = parseFloat(tile.breadth || 0);
            const height = parseFloat(tile.height || 0);
            const wastagePercentage = parseFloat(tile.wastagePercentage || 0);
            const deductionArea = parseFloat(tile.deductionArea || 0);
            let tileArea = 0;
            const skirtingArea = parseFloat(calculateSkirtingArea(tile));
            if (tile.type === "Floor Tile") {
                tileArea = length * breadth;
            } else if (tile.type === "Wall Tile" || tile.type === "Highlighter") {
                tileArea = length * height;
            }
            const finalArea = tileArea - deductionArea;
            const actualQuantity = finalArea + skirtingArea;
            const wastage = (wastagePercentage / 100) * actualQuantity;
            const totalOrderedTile = actualQuantity + wastage;
            const qtyPerBox = parseFloat(tile.quantityBox || 1);
            const Areainsqft = parseFloat(tile.areaTile || 1);
            const totalBoxes = totalOrderedTile / (qtyPerBox * Areainsqft);
            const tileKey = `${currentFloorName}-${tile.tileName}-${tile.size}`;
            if (summaryMap[tileKey]) {
                summaryMap[tileKey].totalOrderedQuantity = (
                    parseFloat(summaryMap[tileKey].totalOrderedQuantity) + totalOrderedTile
                ).toFixed(2);
                summaryMap[tileKey].totalBoxes = (
                    parseFloat(summaryMap[tileKey].totalBoxes) + totalBoxes
                ).toFixed(2);
            } else {
                summaryMap[tileKey] = {
                    floorName: currentFloorName,
                    tileName: tile.tileName,
                    tileSize: tile.size,
                    totalOrderedQuantity: totalOrderedTile.toFixed(2),
                    totalBoxes: totalBoxes.toFixed(2),
                };
            }
        });
    });
    const summary1 = Object.values(summaryMap);
    const handleAddRow = () => {
        setRows([
            ...rows,
            { tileName: "", size: "", qtyPerBox: "", numberOfBoxes: "" },
        ]);
    };
    useEffect(() => {
        const calculations = floors.map(floor => {
            let totalOrderedQuantity = 0;
            let totalBoxes = 0;
            const floorTiles = floor.tiles.map(tile => {
                const lengthNum = Number(tile.length) || 0;
                const breadthNum = Number(tile.breadth) || 0;
                const heightNum = Number(tile.height) || 0;
                const deductionAreaNum = Number(tile.deductionArea) || 0;
                let tileArea;
                const skirtingArea = calculateSkirtingArea(tile);
                if (tile.type === "Floor Tile") {
                    tileArea = lengthNum * breadthNum;
                } else if (tile.type === "Wall Tile" || tile.type === "Highlighter") {
                    tileArea = lengthNum * (breadthNum || heightNum);
                }
                const finalArea = tileArea - deductionAreaNum;
                const actualQuantity = finalArea + skirtingArea;
                const wastagePercentage = (Number(tile.wastagePercentage) || 0) / 100 * actualQuantity;
                const totalOrderedTile = actualQuantity + wastagePercentage;
                const qtyPerBoxNum = Number(tile.quantityBox) || 1;
                const AreainsqftNum = Number(tile.areaTile) || 1;
                const noOfBoxes = (totalOrderedTile / (qtyPerBoxNum * AreainsqftNum)).toFixed(2);
                return {
                    type: tile.type,
                    length: lengthNum,
                    breadth: breadthNum,
                    height: heightNum,
                    deductionArea: deductionAreaNum,
                    wastagePercentage: tile.wastagePercentage,
                    skirtingArea,
                    actualQuantity,
                    totalOrderedTile,
                    correctQuantityBox: tile.correctQuantityBox,
                    tileName: tile.tileName || "Default Tile Name",
                    tileSize: tile.tileSize,
                    size: tile.size || "Default Size",
                    qtyPerBox: qtyPerBoxNum,
                    Areainsqft: AreainsqftNum,
                    noOfBoxes,
                };
            });
            return {
                floorName: floor.floorName,
                areaName: floor.areaName,
                tiles: floorTiles,
                totalOrderedQuantity,
                totalBoxes,
            };
        });
        setCalculationData({
            clientName: clientName ? clientName.label : null,
            date: currentDate,
            calculations,
        });
    }, [clientName, floors, currentDate, clientSNo]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPopupOpen(true);
        setModalOpen(true)
        setIsSubmitting(true);
        if (!calculationData || !calculationData.calculations.length || !clientName) {
            console.error("Data is incomplete, please check inputs.");
            setIsSubmitting(false);
            setIsPopupOpen(false);
            return;
        }
        try {
            const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
            if (!clientResponse.ok) {
                throw new Error("Failed to fetch calculations from the backend");
            }
            const clientData = await clientResponse.json();
            const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === calculationData.clientName);
            const clientCount = matchingClientCalculations.length;
            const formatDateForName = (date) => {
                const d = new Date(date);
                const day = String(d.getDate()).padStart(2, '0');
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const year = d.getFullYear();
                return `${day}-${month}-${year}`;
            };
            const selectedDate = formatDateForName(date);
            const siteName = clientName.label;
            const clientId = clientSNo || 0;
            const getRevisionNumber = async (clientName) => {
                try {
                    const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                    if (!clientResponse.ok) {
                        throw new Error("Failed to fetch calculations from the backend");
                    }
                    const clientData = await clientResponse.json();
                    const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                    return matchingClientCalculations.length;
                } catch (error) {
                    console.error('Error fetching revision number:', error.message);
                    return 0;
                }
            };
            const revisionCount = await getRevisionNumber(siteName);
            const revisionNumber = `R ${Math.max(revisionCount)}`;
            const fileName = `${formatDate(date)} - R ${clientCount}`;
            const normalizedCalculations = calculationData.calculations.reduce((acc, floor, index) => {
                if (!floor.floorName && index > 0) {
                    floor.floorName = acc[index - 1].floorName;
                }
                acc.push(floor);
                return acc;
            }, []);
            const formattedData = {
                clientName: calculationData.clientName,
                date: formatDate(date),
                fileName,
                ENo: eno,
                calculations: normalizedCalculations.map((floor, floorIndex) => ({
                    floorName: floor.floorName,
                    areaName: floor.areaName,
                    tiles: floor.tiles.map((tile) => ({
                        type: tile.type,
                        length: Number(tile.length) || 0,
                        breadth: Number(tile.breadth) || 0,
                        height: Number(tile.height) || 0,
                        deductionArea: Number(tile.deductionArea) || 0,
                        wastagePercentage: Number(tile.wastagePercentage) || 0,
                        skirtingArea: Number(tile.skirtingArea) || 0,
                        actualQuantity: Number(tile.actualQuantity) || 0,
                        totalOrderedTile: Number(tile.totalOrderedTile) || 0,
                        tileName: tile.tileName || "Default Tile Name",
                        correctQuantityBox: Number(tile.correctQuantityBox) || 0,
                        tileSize: tile.tileSize || "Default Size",
                        size: tile.size || "Default Size",
                        qtyPerBox: Number(tile.qtyPerBox) || 1,
                        areaInSqft: Number(tile.Areainsqft) || 1,
                        noOfBoxes: Number(tile.noOfBoxes) || 0,
                    })),
                })),
            };
            const summaryData = createSummaryData();
            const StockingPdf = await generateFloorSummaryPDF(summaryData);
            const fullPdf = await generateFullPDF();
            const customerCopyPdf = await generateCustomerCopyPDF();
            const summaryPdf = await generateSummaryPDF();
            const uploadPdf = async (pdf, name) => {
                const singleFormData = new FormData();
                singleFormData.append("files", pdf, name);
                const pdfUploadResponse = await fetch(`https://backendaab.in/aabuilderDash/googleUploader/pdfs`, {
                    method: "POST",
                    body: singleFormData,
                });
                if (!pdfUploadResponse.ok) {
                    throw new Error("Failed to upload PDF: " + name);
                }
            };
            await uploadPdf(fullPdf, `TMS EC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`);
            await uploadPdf(customerCopyPdf, `TMS CC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`);
            await uploadPdf(summaryPdf, `TMS OC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`);
            await uploadPdf(StockingPdf, `TMS SC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`);
            const response = await fetch("https://backendaab.in/aabuilderDash/api/tile/tile/save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formattedData),
            });

            if (!response.ok) {
                throw new Error("Failed to send data to the backend");
            }
            setEno(eno + 1);
            setIsSubmitting(false);
            setClientName(null);
            setClientSNo('');
            setFloors([{
                floorName: "Ground Floor",
                areaName: "",
                tiles: [{ type: "Floor Tile", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" }],
            }]);
            setRows([{ tileName: "", size: "", qtyPerBox: "", numberOfBoxes: "" }]);
            setIsPopupOpen(false);
            setTimeout(() => {
                setModalOpen(true);
            }, 2000);
            window.location.reload();
        } catch (error) {
            console.error('Error during form submission:', error);
            setIsSubmitting(false);
            setIsPopupOpen(false);
        }
    };
    const safeEvaluate = (expression) => {
        try {
            return evaluate(expression); // Evaluate the mathematical expression
        } catch (error) {
            console.error("Invalid expression:", error);
            return NaN; // Return NaN for invalid expressions
        }
    };
    const handleTileChange = (floorIndex, tileIndex, event) => {
        const { name, value } = event.target;
        const updatedFloors = [...floors];
        const tile = updatedFloors[floorIndex].tiles[tileIndex];
        if (name === "tileName") {
            const selectedTile = tileOptions.find(tile => tile.value === value);
            if (selectedTile) {
                tile.tileName = value;
                tile.tileSize = selectedTile.tileSize;
                tile.defaultSize = selectedTile.tileSize;
                const selectedSizeOption = tileList.find(tile => tile.tileSize === selectedTile.tileSize);
                if (selectedSizeOption) {
                    tile.quantityBox = selectedSizeOption.quantityBox || '';
                    tile.areaTile = selectedSizeOption.areaTile || '';
                    tile.correctQuantityBox = selectedSizeOption.quantityBox || '';
                }
            } else {
                tile.tileName = '';
                tile.tileSize = '';
                tile.quantityBox = '';
                tile.areaTile = '';
                tile.defaultSize = '';
                tile.correctQuantityBox = '';
            }
        }
        else if (name === "deductionArea") {
            tile[name] = value;
        } else {
            tile[name] = value;
        }
        setFloors(updatedFloors);
    };

    const handleKeyDown = (floorIndex, tileIndex, event) => {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            const updatedFloors = [...floors];
            const tile = updatedFloors[floorIndex].tiles[tileIndex];

            if (name === "length" && tile.type === "Wall Tile") {
                const calculatedValue = safeEvaluate(value);
                tile[name] = isNaN(calculatedValue) ? value : calculatedValue.toString();
            } else if (name === "deductionArea") {
                const calculatedValue = safeEvaluate(value);
                tile[name] = isNaN(calculatedValue) ? value : calculatedValue.toString();
            } else {
                tile[name] = value;
            }
            setFloors(updatedFloors); // Update state
        }
    };
    const addTile = (floorIndex, tileIndex) => {
        const newTile = {
            type: 'Floor Tile',
            length: '',
            breadth: '',
            height: '',
            deductionArea: '',
            wastagePercentage: '0',
        };
        const updatedFloors = floors.map((floor, index) => {
            if (index === floorIndex) {
                const updatedTiles = [...floor.tiles];
                updatedTiles.splice(tileIndex + 1, 0, newTile);
                return { ...floor, tiles: updatedTiles };
            }
            return floor;
        });
        setFloors(updatedFloors);
        handleAddRow();
    };
    const addAreaRow = (floorIndex) => {
        const updatedFloors = [...floors];
        updatedFloors.splice(floorIndex + 1, 0, {
            floorName: null,
            areaName: "",
            tiles: [
                { type: "Floor Tile", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" },
            ],
        });
        handleAddRow();
        setFloors(updatedFloors);
    };
    const addFloorRow = () => {
        setFloors((prevFloors) => [
            ...prevFloors,
            {
                floorName: "",
                areaName: "",
                tiles: [
                    { type: "Floor Tile", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" },
                ],
            },
        ]);
        handleAddRow();
    };
    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            borderColor: state.isFocused ? "#FAF6ED" : "transparent",
            "&:hover": {
                borderColor: "none",
            },

            boxShadow: state.isFocused ? "0 0 0 #FAF6ED" : "none",
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#000',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };
    useEffect(() => {
        const fetchTiles = async () => {
            try {
                const response = await fetch('https://backendaab.in/aabuilderDash/api/tiles/all/data');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const options = data.map(tile => ({
                    value: tile.label || tile.tileName,
                    label: tile.label || tile.tileName,
                    tileSize: tile.tileSize || 'default size'
                }));
                setTileOptions(options);
            } catch (error) {
                console.error('Failed to fetch tiles:', error);
            }
        };
        fetchTiles();
    }, []);
    const calculateSummary = () => {
        const summaryMap = {};
        floors.forEach((floor) => {
            floor.tiles.forEach((tile) => {
                const key = `${tile.tileName}-${tile.size}`;
                if (!summaryMap[key]) {
                    summaryMap[key] = {
                        tileName: tile.tileName,
                        size: tile.size,
                        totalOrderedQuantity: 0,
                        totalBoxes: 0,
                    };
                }
                let skirtingArea = tile.skirtingArea;
                if (skirtingArea === undefined || skirtingArea === null) {
                    if (tile.type !== "Wall Tile" && tile.type !== "Highlighter") {
                        skirtingArea = tile.isUserChanged
                            ? Number(tile.directValue || 0)
                            : (Number(tile.length || 0) + Number(tile.breadth || 0)) * 0.33;
                    } else {
                        skirtingArea = 0;
                    }
                }
                let totalOrderedQuantity = 0;
                if (tile.length && (tile.breadth || tile.height)) {
                    const length = Number(tile.length || 0);
                    const breadth = Number(tile.breadth || 0);
                    const height = Number(tile.height || 0);
                    const wastagePercentage = Number(tile.wastagePercentage || 0);
                    const deductionArea = Number(tile.deductionArea || 0);
                    let tileArea = 0;
                    if (tile.type === "Floor Tile") {
                        tileArea = length * breadth;
                    } else if (tile.type === "Wall Tile" || tile.type === "Highlighter") {
                        tileArea = length * height;
                    }
                    const finalArea = Math.max(tileArea - deductionArea, 0);
                    const actualQuantity = finalArea + skirtingArea;
                    const wastage = (wastagePercentage / 100) * actualQuantity;
                    totalOrderedQuantity = actualQuantity + wastage;
                }
                summaryMap[key].totalOrderedQuantity += totalOrderedQuantity || 0;
                summaryMap[key].totalBoxes += (totalOrderedQuantity / (Number(tile.quantityBox || 1) * Number(tile.areaTile || 1))) || 0;
            });
        });
        return Object.values(summaryMap).map(item => ({
            ...item,
            totalOrderedQuantity: (Number(item.totalOrderedQuantity) || 0).toFixed(2),
            totalBoxes: (Number(item.totalBoxes) || 0).toFixed(2),
        }));
    };
    const summary = calculateSummary();
    const generateCustomerCopyPDF = async () => {
        const doc = new jsPDF();
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const siteName = clientName.label;
        const clientId = clientSNo || 0;

        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                return matchingClientCalculations.length;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 0;
            }
        };
        const revisionCount = await getRevisionNumber(siteName);
        const revisionNumber = `R ${Math.max(revisionCount)}`;
        // Header
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("TILE MEASUREMENT SHEET", 14, 15);
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(11);
        const clientLabel = "CLIENT: ";
        doc.text(clientLabel, 14, 33);
        const labelWidth = doc.getTextWidth(clientLabel);
        doc.setFont("helvetica", "normal");
        const siteNameText = siteName.toUpperCase();
        doc.text(siteNameText, 14 + labelWidth, 33);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text("CLIENT COPY", doc.internal.pageSize.width - 38, 15);
        doc.setFontSize(10);
        doc.setFontSize(10);
        const tmsDate = `TMS CC ${clientId} - ${selectedDate} - ${revisionNumber}`;
        doc.setFont("helvetica", "normal");
        const textWidth = doc.getTextWidth(tmsDate);
        const rightMargin = 14;
        const pageWidth = doc.internal.pageSize.width;
        const startX = pageWidth - rightMargin - textWidth;
        doc.text(tmsDate, startX, 27);
        doc.setDrawColor('#BF9853');
        doc.setLineWidth(1);
        doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        const headers = [
            [
                "SNO",
                "AREA NAME",
                "TYPE",
                "L (Ft)",
                "B (Ft)",
                "H (Ft)",
                "DEDUCTION AREA (SQFT)",
                "SKIRTING AREA (SQFT)",
                "WASTAGE (%)",
                "QTY TO BE ORDERED (SQFT)"
            ]
        ];
        const rows = [];
        let previousFloorName = null;
        let floorCounter = 0;
        let areaCounter = 1;
        floors.forEach((floor) => {
            const currentFloorName = (floor.floorName || previousFloorName).toUpperCase();
            if (currentFloorName !== previousFloorName) {
                floorCounter += 1;
                areaCounter = 1;
                const floorLetter = String.fromCharCode(64 + floorCounter);
                rows.push([
                    { content: floorLetter, styles: { fontStyle: "bold" } },
                    { content: currentFloorName, styles: { fontStyle: "bold" } },
                    "", "", "", "", "", "", "", "", "", "", "", ""
                ]);
                previousFloorName = currentFloorName;
            }
            let areaNamePrinted = false;
            floor.tiles.forEach(tile => {
                const { length, breadth, height, wastagePercentage, deductionArea, type } = tile;
                const lengthNum = Number(length);
                const breadthNum = Number(breadth);
                const heightNum = Number(height);
                const wastagePercentageNum = Number(wastagePercentage);
                const deductionAreaNum = Number(deductionArea || 0);
                let tileArea;
                let skirtingArea = tile.skirtingArea;
                if (skirtingArea === undefined || skirtingArea === null) {
                    skirtingArea = (tile.type === "Wall Tile" || tile.type === "Highlighter")
                        ? 0
                        : (tile.isUserChanged
                            ? Number(tile.directValue)
                            : ((lengthNum + breadthNum) * 0.33));
                }
                if (type === "Floor Tile") {
                    tileArea = lengthNum * breadthNum;
                } else if (type === "Wall Tile" || type === "Highlighter") {
                    tileArea = lengthNum * heightNum;
                }
                const finalArea = tileArea - deductionAreaNum;
                const actualQuantity = finalArea + skirtingArea;
                const wastage = (wastagePercentageNum / 100) * actualQuantity;
                const totalOrderedQuantity = actualQuantity + wastage;
                const areaNameCell = areaNamePrinted ? '' : floor.areaName;
                areaNamePrinted = true;
                rows.push([
                    areaCounter,
                    areaNameCell,
                    tile.type,
                    length,
                    breadth,
                    height,
                    deductionAreaNum,
                    skirtingArea.toFixed(2),
                    wastagePercentage + "%",
                    totalOrderedQuantity.toFixed(2)
                ]);
                areaCounter += 1;
            });
        });
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: headers,
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 10,
                halign: "center",
                lineWidth: 0,
            },
            bodyStyles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                halign: "left",
                font: "helvetica",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 11, minCellHeight: 2 },
                1: { halign: "left", cellWidth: 30, cellHeight: 2 },
                2: { halign: "left", cellWidth: 20, cellHeight: 2 },
                3: { halign: "center", cellWidth: 10, cellHeight: 2 },
                4: { halign: "center", cellWidth: 10, cellHeight: 2 },
                5: { halign: "center", cellWidth: 10, cellHeight: 2 },
                6: { halign: "center", cellWidth: 24.1, cellHeight: 2 },
                7: { halign: "center", cellWidth: 22, cellHeight: 2 },
                8: { halign: "center", cellWidth: 21, cellHeight: 2 },
                9: { halign: "center", cellWidth: 24, cellHeight: 2 },
            },
            margin: { top: 30 },
            pageBreak: 'auto',
            tableWidth: 'wrap',
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        // Footer
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        footer(doc);
        const filename = `TMS CC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`;
        doc.save(filename);
        return doc.output("blob");
    };
    const handleDirectValueChange = (floorIndex, tileIndex, event) => {
        const updatedFloors = [...floors];
        const tile = { ...updatedFloors[floorIndex].tiles[tileIndex] };
        const inputValue = Number(event.target.value) || 0;
        tile.directValue = inputValue;
        tile.skirtingArea = inputValue;
        tile.isUserChanged = true;
        updatedFloors[floorIndex].tiles[tileIndex] = tile;
        setFloors(updatedFloors);
    };
    const resetToCalculatedValue = (floorIndex, tileIndex) => {
        const updatedFloors = [...floors];
        const tile = { ...updatedFloors[floorIndex].tiles[tileIndex] };
        const length = Number(tile.length) || 0;
        const breadth = Number(tile.breadth) || 0;
        let calculatedSkirtingArea = 0;
        if (tile.type !== "Wall Tile") {
            calculatedSkirtingArea = ((length + breadth) * 0.33).toFixed(2);
        }
        tile.skirtingArea = Number(calculatedSkirtingArea);
        tile.isUserChanged = false;
        tile.directValue = "";
        updatedFloors[floorIndex].tiles[tileIndex] = tile;
        setFloors(updatedFloors);
    };
    const generateSummaryPDF = async () => {
        const doc = new jsPDF();
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, "0");
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const pdf = new jsPDF();
        pdf.addFileToVFS("ArialNovaCond.ttf", "BASE64_ENCODED_FONT_DATA");
        pdf.addFont("ArialNovaCond.ttf", "ArialNovaCond", "normal");
        const getRevisionNumber = async (clientName) => {
            try {
                const response = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!response.ok) {
                    throw new Error("Failed to fetch revision data");
                }
                const data = await response.json();
                return data.filter((calc) => calc.clientName === clientName).length;
            } catch (error) {
                console.error("Error fetching revision:", error);
                return 0;
            }
        };
        const revisionCount = await getRevisionNumber(siteName);
        const revisionNumber = `R ${revisionCount}`;
        const header = () => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.setFontSize(11);
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("ORDER COPY", doc.internal.pageSize.width - 38, 15);
            doc.setFontSize(10);
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const tmsDate = `TMS OC ${clientId} - ${selectedDate} - ${revisionNumber}`;
            doc.setFont("helvetica", "normal");
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setDrawColor('#BF9853');
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const columns = [
            { content: "SNO", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TILE NAME", styles: { halign: "left", fontStyle: "bold" } },
            { content: "SIZE", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TOTAL AREA (SQFT)", styles: { halign: "center", fontStyle: "bold" } },
            { content: "NO OF BOXES", styles: { halign: "center", fontStyle: "bold" } },
        ];
        const rows = summary.map((row, index) => {
            const totalOrderedQuantity = parseFloat(row.totalOrderedQuantity) || 0;
            const totalBoxes = parseFloat(row.totalBoxes) || 0;
            return [
                { content: (index + 1).toString(), styles: { halign: "center" } },
                { content: row.tileName, styles: { halign: "left" } },
                { content: row.size, styles: { halign: "center" } },
                { content: totalOrderedQuantity.toFixed(3), styles: { halign: "center" } },
                { content: totalBoxes.toFixed(3), styles: { halign: "center" } },
            ];
        });
        const totalArea = summary.reduce((acc, row) => acc + (parseFloat(row.totalOrderedQuantity) || 0), 0).toFixed(2);
        const totalBoxes = summary.reduce((acc, row) => acc + (parseFloat(row.totalBoxes) || 0), 0).toFixed(2);
        rows.push([
            { content: "", styles: { fontStyle: "bold" } },
            { content: "TOTAL", styles: { fontStyle: "bold" } },
            { content: "", styles: { fontStyle: "bold" } },
            { content: totalArea, styles: { halign: "center", fontStyle: "bold" } },
            { content: totalBoxes, styles: { halign: "center", fontStyle: "bold" } },
        ]);
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: [columns.map((col) => col.content)],
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                halign: "center",
                fontSize: 11,
            },
            bodyStyles: {
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 15 },
                1: { halign: "left", cellWidth: 57 },
                2: { halign: "center", cellWidth: 35 },
                3: { halign: "center", cellWidth: 40 },
                4: { halign: "center", cellWidth: 35 },
            },
            didDrawPage: (data) => {
                header();
                footer();
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        const filename = `TMS OC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`;
        doc.save(filename);
        return doc.output("blob");
    };
    const createSummaryData = () => {
        const summaryData = [];
        floors.forEach(floor => {
            const floorName = floor.floorName;
            floor.tiles.forEach(tile => {
                if (tile.tileName) {
                    const length = Number(tile.length) || 0;
                    const breadth = Number(tile.breadth) || 0;
                    const height = Number(tile.height) || 0;
                    const deductionArea = Number(tile.deductionArea) || 0;
                    const wastagePercentage = Number(tile.wastagePercentage) || 0;
                    const qtyPerBox = Number(tile.quantityBox) || 1;
                    const Areainsqft = Number(tile.areaTile || 1);
                    let tileArea;
                    let skirtingArea = tile.skirtingArea;
                    if (skirtingArea === undefined || skirtingArea === null) {
                        if (tile.type !== "Wall Tile" && tile.type !== "Highlighter") {
                            skirtingArea = tile.isUserChanged
                                ? Number(tile.directValue || 0)
                                : (Number(tile.length || 0) + Number(tile.breadth || 0)) * 0.33;
                        } else {
                            skirtingArea = 0;
                        }
                    }
                    if (tile.type === "Floor Tile") {
                        tileArea = length * breadth;
                    } else if (tile.type === "Wall Tile") {
                        tileArea = length * height;
                    } else if (tile.type === "Highlighter") {
                        tileArea = length * height;
                    }
                    const finalArea = tileArea - deductionArea;
                    const actualQuantity = finalArea + skirtingArea;
                    const wastage = (wastagePercentage / 100) * actualQuantity;
                    const totalOrderedTile = actualQuantity + wastage;
                    const numberOfBoxes = (totalOrderedTile / (qtyPerBox * Areainsqft));
                    summaryData.push({
                        floorName: floorName,
                        tileName: tile.tileName,
                        size: tile.size,
                        totalOrderedQuantity: totalOrderedTile,
                        totalBoxes: numberOfBoxes
                    });
                }
            });
        });
        return summaryData;
    };
    const generateFloorSummaryPDF = async (summaryData) => {
        const doc = new jsPDF();
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const siteName = clientName.label;
        const clientId = clientSNo || 0;

        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                return matchingClientCalculations.length;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 0;
            }
        };
        const revisionCount = await getRevisionNumber(siteName);
        const revisionNumber = `R ${revisionCount}`;
        const header = () => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.setFontSize(11);
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("STOCKING CHART", doc.internal.pageSize.width - 46, 15);
            doc.setFontSize(10);
            const tmsDate = `TMS SC ${clientId} - ${selectedDate} - ${revisionNumber}`;
            doc.setFont("helvetica", "normal");
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setDrawColor('#BF9853');
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const columns = [
            { content: "SNO", styles: { halign: "center", fontStyle: "bold" } },
            { content: "FLOOR NAME", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TILE NAME", styles: { halign: "left", fontStyle: "bold" } },
            { content: "TILE SIZE", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TOTAL AREA (SQFT)", styles: { halign: "center", fontStyle: "bold" } },
            { content: "NO OF BOXES", styles: { halign: "center", fontStyle: "bold" } },
        ];
        const rows = [];
        const floorData = {};
        let previousFloorName = "";
        let serialNumber = 1;
        summaryData.forEach(item => {
            const floorName = item.floorName && item.floorName.trim() !== "" ? item.floorName : previousFloorName;
            if (floorName) {
                previousFloorName = floorName;
            }
            const tileKey = `${item.tileName}|${item.size}`;
            if (!floorData[floorName]) {
                floorData[floorName] = {};
            }
            if (!floorData[floorName][tileKey]) {
                floorData[floorName][tileKey] = {
                    tileName: item.tileName,
                    size: item.size,
                    totalOrderedQuantity: 0,
                    totalBoxes: 0
                };
            }
            floorData[floorName][tileKey].totalOrderedQuantity += item.totalOrderedQuantity;
            floorData[floorName][tileKey].totalBoxes += item.totalBoxes;
        });
        for (const floor in floorData) {
            for (const tileKey in floorData[floor]) {
                const tile = floorData[floor][tileKey];
                rows.push([
                    { content: serialNumber++, styles: { halign: "center" } },
                    { content: floor, styles: { halign: "center" } },
                    { content: tile.tileName, styles: { halign: "left" } },
                    { content: tile.size, styles: { halign: "center" } },
                    { content: tile.totalOrderedQuantity.toFixed(2), styles: { halign: "center" } },
                    { content: tile.totalBoxes.toFixed(2), styles: { halign: "center" } },
                ]);
            }
        }
        const totalArea = summaryData.reduce((acc, row) => acc + (parseFloat(row.totalOrderedQuantity) || 0), 0).toFixed(2);
        const totalBoxes = summaryData.reduce((acc, row) => acc + (parseFloat(row.totalBoxes) || 0), 0).toFixed(2);
        rows.push([
            { content: "", styles: { fontStyle: "bold" } },
            { content: "TOTAL", styles: { fontStyle: "bold" }, fontSize: 12 },
            { content: "", styles: { fontStyle: "bold" } },
            { content: "", styles: { fontStyle: "bold" } },
            { content: totalArea, styles: { halign: "center", fontStyle: "bold" }, fontSize: 12 },
            { content: totalBoxes, styles: { halign: "center", fontStyle: "bold" }, fontSize: 12 },
        ]);
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: [columns.map(col => col.content)],
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                halign: "center",
                fontSize: 11,
            },
            bodyStyles: {
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 34 },
                2: { cellWidth: 48 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
                5: { cellWidth: 25 },
            },
            didDrawPage: (data) => {
                header();
                footer();
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        const filename = `TMS SC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`;
        doc.save(filename);
        return doc.output("blob");
    };
    const handleGeneratePDF = () => {
        const summaryData = createSummaryData();
        floorSummaryPDF(summaryData);
    };
    const generateFullPDF = async () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                return matchingClientCalculations.length;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 0;
            }
        };
        const revisionCount = await getRevisionNumber(siteName);
        const revisionNumber = `R ${Math.max(revisionCount)}`;
        const header = (doc) => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.setFontSize(11);
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("ENGINEER COPY", doc.internal.pageSize.width - 44, 15);
            doc.setFontSize(10);
            const tmsDate = `TMS EC ${clientId} - ${selectedDate} - ${revisionNumber}`;
            doc.setFont("helvetica", "normal");
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setDrawColor('#BF9853');
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const customerHeaders = [
            [
                "SNO",
                "AREA NAME",
                "TYPE",
                "L (Ft)",
                "B (Ft)",
                "H (Ft)",
                "DEDUCTION AREA (SQFT)",
                "SKIRTING AREA (SQFT)",
                "WASTAGE (%)",
                "QTY TO BE ORDERED (SQFT)",
                "TILE NAME",
                "SIZE",
                "QTY/BOX",
                "NO OF BOXES",
            ],
        ];
        const customerRows = [];
        if (!floors || floors.length === 0) {
            customerRows.push(["No Data", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
        } else {
            let floorCounter = 0;
            let areaCounter = 1;
            let previousFloorName = null;
            floors.forEach((floor) => {
                const currentFloorName = (floor.floorName || previousFloorName).toUpperCase();
                if (currentFloorName !== previousFloorName) {
                    floorCounter += 1;
                    areaCounter = 1;
                    const floorLetter = String.fromCharCode(64 + floorCounter);
                    customerRows.push([
                        { content: floorLetter, styles: { fontStyle: "bold" } },
                        { content: currentFloorName, styles: { fontStyle: "bold" } },
                        "", "", "", "", "", "", "", "", "", "", "", ""
                    ]);
                    previousFloorName = currentFloorName;
                }
                let areaNamePrinted = false;
                floor.tiles.forEach(tile => {
                    const {
                        length, breadth, height, wastagePercentage, deductionArea,
                        type, quantityBox, tileName, size, areaTile
                    } = tile;
                    const lengthNum = Number(length);
                    const breadthNum = Number(breadth);
                    const heightNum = Number(height);
                    const wastagePercentageNum = Number(wastagePercentage);
                    const deductionAreaNum = Number(deductionArea || 0);
                    let tileArea;
                    let skirtingArea = tile.skirtingArea;
                    if (skirtingArea === undefined || skirtingArea === null) {
                        skirtingArea = (tile.type === "Wall Tile" || tile.type === "Highlighter")
                            ? 0
                            : (tile.isUserChanged
                                ? Number(tile.directValue)
                                : ((lengthNum + breadthNum) * 0.33));
                    }
                    if (type === "Floor Tile") {
                        tileArea = lengthNum * breadthNum;
                    } else if (type === "Wall Tile" || type === "Highlighter") {
                        tileArea = lengthNum * heightNum;
                    }
                    const finalArea = tileArea - deductionAreaNum;
                    const actualQuantity = finalArea + skirtingArea;
                    const wastage = (wastagePercentageNum / 100) * actualQuantity;
                    const totalOrderedQuantity = actualQuantity + wastage;
                    const areainsqft = Number(areaTile || 1);
                    const noOfBoxes = (totalOrderedQuantity / (quantityBox * areainsqft)).toFixed(2);
                    const areaNameCell = areaNamePrinted ? '' : floor.areaName;
                    areaNamePrinted = true;
                    customerRows.push([
                        areaCounter,
                        areaNameCell,
                        type,
                        length,
                        breadth,
                        height,
                        deductionAreaNum,
                        skirtingArea.toFixed(2),
                        `${wastagePercentage}%`,
                        totalOrderedQuantity.toFixed(2),
                        tileName,
                        size,
                        quantityBox,
                        noOfBoxes
                    ]);
                    areaCounter += 1;
                });
            });
        }
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: customerHeaders,
            body: customerRows,
            startY: 44,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 10,
                halign: "center",
                lineWidth: 0,
            },
            bodyStyles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                halign: "left",
                font: "helvetica",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 12, minCellHeight: 2 },
                1: { halign: "left", cellWidth: 35, cellHeight: 2 },
                2: { halign: "left", cellWidth: 20, cellHeight: 2 },
                3: { halign: "center", cellWidth: 10, cellHeight: 2 },
                4: { halign: "center", cellWidth: 10, cellHeight: 2 },
                5: { halign: "center", cellWidth: 10, cellHeight: 2 },
                6: { halign: "center", cellWidth: 24.1, cellHeight: 2 },
                7: { halign: "center", cellWidth: 22, cellHeight: 2 },
                8: { halign: "center", cellWidth: 22, cellHeight: 2 },
                9: { halign: "center", cellWidth: 25, cellHeight: 2 },
                10: { halign: "left", cellWidth: 33, cellHeight: 2 },
                11: { halign: "center", cellWidth: 17, cellHeight: 2 },
                12: { halign: "center", cellWidth: 13, cellHeight: 2 },
                13: { halign: "center", cellWidth: 16, cellHeight: 2 },
            },
            margin: { left: 14, top: 44, bottom: 25 },
            pageBreak: 'auto',
            didDrawPage: (data) => {
                if (doc.internal.getCurrentPageInfo().pageNumber > 1) {
                    data.settings.startY = -30;
                    const tableStartY = 44;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.01);
                    doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
                }
                header(doc);
                footer(doc);
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        const filename = `TMS EC ${clientId} - ${selectedDate} - ${revisionNumber}.pdf`;
        doc.save(filename);
        return doc.output("blob");
    };

    const deleteTileRow = (floorIndex, tileIndex) => {
        const updatedFloors = [...floors];
        updatedFloors[floorIndex].tiles.splice(tileIndex, 1);
        setFloors(updatedFloors);
    };
    const deleteAreaRow = (floorIndex) => {
        const updatedFloors = [...floors];
        updatedFloors.splice(floorIndex, 1);
        setFloors(updatedFloors);
    };
    const [wastagePercentage, setWastagePercentage] = useState(0);
    const handleCommonWastageChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setWastagePercentage(selectedValue);
        const updatedFloors = floors.map((floor) => ({
            ...floor,
            tiles: floor.tiles.map((tile) => ({
                ...tile,
                wastagePercentage: selectedValue,
            })),
        }));
        setFloors(updatedFloors);
    };
    const totalArea = summary1.reduce((sum, item) => sum + parseFloat(item.totalOrderedQuantity || 0), 0);
    const totalBoxes = summary1.reduce((sum, item) => sum + parseFloat(item.totalBoxes || 0), 0);
    const handleSizeChange = (floorIndex, tileIndex, newSize) => {
        const updatedFloors = [...floors];
        updatedFloors[floorIndex].tiles[tileIndex].size = newSize;
        const selectedSizeOption = tileList.find(
            (size) => size.tileSize === newSize
        );
        if (selectedSizeOption) {
            updatedFloors[floorIndex].tiles[tileIndex].quantityBox =
                selectedSizeOption.quantityBox;
            updatedFloors[floorIndex].tiles[tileIndex].areaTile =
                selectedSizeOption.areaTile;
        } else {
            updatedFloors[floorIndex].tiles[tileIndex].quantityBox = "";
            updatedFloors[floorIndex].tiles[tileIndex].areaTile = "";
        }
        setFloors(updatedFloors);
    };
    const handleFileChange = (selected) => {
        if (!selected) {
            setSelectedFile(null);
            setSelectedClientData({ calculations: [] });
            setFloors([]);
            return;
        }
        const selectedClientData = fullData.find(calculation => calculation.id === selected.value);
        setSelectedFile(selected);
        if (selectedClientData) {
            setSelectedClientData(selectedClientData);
            const seenFloors = new Set();
            const newFloorsData = selectedClientData.calculations.map(calc => {
                const floorName = calc.floorName || 'No floor name available';
                const areaName = calc.areaName || 'No area name available';
                const floorVisible = !seenFloors.has(floorName);
                seenFloors.add(floorName);
                return {
                    floorName: floorVisible ? floorName : null,
                    areaName: areaName,
                    tiles: calc.tiles.map(tile => {
                        const selectedTile = tileList.find(t => t.tileSize === tile.tileSize);
                        return {
                            type: tile.type,
                            length: tile.length,
                            breadth: tile.breadth,
                            height: tile.height,
                            deductionArea: tile.deductionArea,
                            skirtingArea: tile.skirtingArea,
                            areaTile: tile.areaInSqft || '',
                            quantityBox: tile.qtyPerBox || '',
                            tileName: tile.tileName,
                            tileSize: tile.tileSize,
                            size: tile.size,
                            actualQuantity: tile.actualQuantity,
                            noOfBoxes: tile.noOfBoxes,
                            wastagePercentage: tile.wastagePercentage,
                            isUserChanged: tile.isUserChanged || false,
                            directValue: tile.directValue || 0,
                            defaultSize: selectedTile ? selectedTile.tileSize : tile.tileSize || '',
                            correctQuantityBox: tile.correctQuantityBox,
                            hasSizeChanged: selectedTile && tile.size !== selectedTile.tileSize,
                        };
                    }),
                };
            });
            setFloors(newFloorsData);
        } else {
            setSelectedClientData({ calculations: [] });
            setFloors([]);
        }
    };
    const customerCopyPDF = async () => {
        const doc = new jsPDF({
        });
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const fileType = "CC";
        const fileLabel = selectedFile && selectedFile.label ? selectedFile.label : `${selectedDate}`;
        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                const revisionNumber = matchingClientCalculations.length > 0 ? `R ${Math.max(matchingClientCalculations.length - 1, 0)}` : 'R0';
                return revisionNumber;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 'R 0';
            }
        };
        const getIncrement = async (fileLabel) => {
            try {
                const response = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/increment?fileLabel=${encodeURIComponent(fileLabel)}&fileType=${encodeURIComponent(fileType)}&clientId=${encodeURIComponent(clientId)}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch increment from the backend");
                }
                const increment = await response.json();
                return increment;
            } catch (error) {
                console.error('Error fetching increment:', error.message);
                return 0;
            }
        };
        const postIncrement = async (fileLabel, fileType) => {
            try {
                await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/updateIncrement', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileLabel, fileType, clientId }),
                });
            } catch (error) {
                console.error('Error posting increment:', error.message);
            }
        };
        let filename;
        const incrementValue = await getIncrement(fileLabel, fileType, clientId);
        if (selectedFile && selectedFile.label) {
            filename = `TMS ${fileType} ${clientId} - ${selectedFile.label}.${incrementValue}.pdf`;
        } else {
            const revisionNumber = await getRevisionNumber(siteName);
            filename = `TMS ${fileType} ${clientId} - ${selectedDate} - ${revisionNumber} - ${formatDate(currentDate)}.pdf`;
        }
        await postIncrement(fileLabel, fileType);
        // Header
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("TILE MEASUREMENT SHEET", 14, 15);
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        const clientLabel = "CLIENT: ";
        doc.text(clientLabel, 14, 33);
        const labelWidth = doc.getTextWidth(clientLabel);
        doc.setFont("helvetica", "normal");
        const siteNameText = siteName.toUpperCase();
        doc.text(siteNameText, 14 + labelWidth, 33);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text("CLIENT COPY", doc.internal.pageSize.width - 38, 15);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const tmsDate = `TMS CC ${clientId} - ${selectedFile.label}.${incrementValue}`;
        const textWidth = doc.getTextWidth(tmsDate);
        const rightMargin = 14;
        const pageWidth = doc.internal.pageSize.width;
        const startX = pageWidth - rightMargin - textWidth;
        doc.text(tmsDate, startX, 27);

        doc.setDrawColor('#BF9853');
        doc.setLineWidth(1);
        doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        const headers = [
            [
                "SNO",
                "AREA NAME",
                "TYPE",
                "L (Ft)",
                "B (Ft)",
                "H (Ft)",
                "DEDUCTION AREA (SQFT)",
                "SKIRTING AREA (SQFT)",
                "WASTAGE (%)",
                "QTY TO BE ORDERED (SQFT)"
            ]
        ];
        const rows = [];
        let previousFloorName = null;
        let floorCounter = 0;
        let areaCounter = 1;
        floors.forEach((floor) => {
            const currentFloorName = (floor.floorName || previousFloorName).toUpperCase();
            if (currentFloorName !== previousFloorName) {
                floorCounter += 1;
                areaCounter = 1;
                const floorLetter = String.fromCharCode(64 + floorCounter);
                rows.push([
                    { content: floorLetter, styles: { fontStyle: "bold" } },
                    { content: currentFloorName, styles: { fontStyle: "bold" } },
                    "", "", "", "", "", "", "", "", "", "", "", ""
                ]);
                previousFloorName = currentFloorName;
            }
            let areaNamePrinted = false;
            floor.tiles.forEach(tile => {
                const { length, breadth, height, wastagePercentage, deductionArea, type } = tile;
                const lengthNum = Number(length);
                const breadthNum = Number(breadth);
                const heightNum = Number(height);
                const wastagePercentageNum = Number(wastagePercentage);
                const deductionAreaNum = Number(deductionArea || 0);
                let tileArea;
                let skirtingArea = tile.skirtingArea;
                if (skirtingArea === undefined || skirtingArea === null) {
                    skirtingArea = (tile.type === "Wall Tile" || tile.type === "Highlighter")
                        ? 0
                        : (tile.isUserChanged
                            ? Number(tile.directValue)
                            : ((lengthNum + breadthNum) * 0.33));
                }
                if (type === "Floor Tile") {
                    tileArea = lengthNum * breadthNum;
                } else if (type === "Wall Tile" || type === "Highlighter") {
                    tileArea = lengthNum * heightNum;
                }
                const finalArea = tileArea - deductionAreaNum;
                const actualQuantity = finalArea + skirtingArea;
                const wastage = (wastagePercentageNum / 100) * actualQuantity;
                const totalOrderedQuantity = actualQuantity + wastage;
                const areaNameCell = areaNamePrinted ? '' : floor.areaName;
                areaNamePrinted = true;
                rows.push([
                    areaCounter,
                    areaNameCell,
                    tile.type,
                    length,
                    breadth,
                    height,
                    deductionAreaNum,
                    skirtingArea.toFixed(2),
                    wastagePercentage + "%",
                    totalOrderedQuantity.toFixed(2)
                ]);
                areaCounter += 1;
            });
        });
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.01);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: headers,
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 10,
                halign: "center",
                lineWidth: 0,
            },
            bodyStyles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                halign: "left",
                font: "helvetica",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 11, minCellHeight: 2 },
                1: { halign: "left", cellWidth: 30, cellHeight: 2 },
                2: { halign: "left", cellWidth: 20, cellHeight: 2 },
                3: { halign: "center", cellWidth: 10, cellHeight: 2 },
                4: { halign: "center", cellWidth: 10, cellHeight: 2 },
                5: { halign: "center", cellWidth: 10, cellHeight: 2 },
                6: { halign: "center", cellWidth: 24.1, cellHeight: 2 },
                7: { halign: "center", cellWidth: 22, cellHeight: 2 },
                8: { halign: "center", cellWidth: 21, cellHeight: 2 },
                9: { halign: "center", cellWidth: 24, cellHeight: 2 },
            },
            margin: { top: 30 },
            pageBreak: 'auto',
            tableWidth: 'wrap',
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        footer(doc);
        doc.save(filename);
        return doc.output("blob");
    };
    const summaryPDF = async () => {
        const doc = new jsPDF();
        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const fileType = 'OC';
        const selectedDate = formatDateForName(date);
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const fileLabel = selectedFile && selectedFile.label ? selectedFile.label : `${selectedDate}`;
        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                const revisionNumber = matchingClientCalculations.length > 0 ? `R ${Math.max(matchingClientCalculations.length - 1, 0)}` : 'R0';
                return revisionNumber;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 'R 0';
            }
        };
        const getIncrement = async (fileLabel) => {
            try {

                const response = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/increment?fileLabel=${encodeURIComponent(fileLabel)}&fileType=${encodeURIComponent(fileType)}&clientId=${encodeURIComponent(clientId)}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch increment from the backend");
                }
                const increment = await response.json();
                return increment;
            } catch (error) {
                console.error('Error fetching increment:', error.message);
                return 0;
            }
        };
        const postIncrement = async (fileLabel, fileType) => {
            try {
                await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/updateIncrement', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileLabel, fileType, clientId }),
                });
            } catch (error) {
                console.error('Error posting increment:', error.message);
            }
        };
        let filename;
        const incrementValue = await getIncrement(fileLabel, fileType);
        if (selectedFile && selectedFile.label) {
            filename = `TMS ${fileType} ${clientId} - ${selectedFile.label}.${incrementValue}.pdf`;
        } else {
            const revisionNumber = await getRevisionNumber(siteName);
            filename = `TMS ${fileType} ${clientId} - ${selectedDate} - ${revisionNumber} - ${formatDate(currentDate)}.pdf`;
        }
        await postIncrement(fileLabel, fileType);
        const header = () => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.setFontSize(11);
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            doc.setFontSize(10);
            const tmsDate = `TMS OC ${clientId} - ${selectedFile.label}.${incrementValue}`;
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("ORDER COPY", doc.internal.pageSize.width - 38, 15);
            doc.setDrawColor("#BF9853");
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const columns = [
            { content: "SNO", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TILE NAME", styles: { halign: "left", fontStyle: "bold" } },
            { content: "SIZE", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TOTAL AREA (SQFT)", styles: { halign: "center", fontStyle: "bold" } },
            { content: "NO OF BOXES", styles: { halign: "center", fontStyle: "bold" } },
        ];
        const rows = summary.map((row, index) => {
            const totalOrderedQuantity = parseFloat(row.totalOrderedQuantity) || 0;
            const totalBoxes = parseFloat(row.totalBoxes) || 0;
            return [
                { content: (index + 1).toString(), styles: { halign: "center" } },
                { content: row.tileName, styles: { halign: "left" } },
                { content: row.size, styles: { halign: "center" } },
                { content: totalOrderedQuantity.toFixed(3), styles: { halign: "center" } },
                { content: totalBoxes.toFixed(3), styles: { halign: "center" } },
            ];
        });
        const totalArea = summary.reduce((acc, row) => acc + (parseFloat(row.totalOrderedQuantity) || 0), 0).toFixed(2);
        const totalBoxes = summary.reduce((acc, row) => acc + (parseFloat(row.totalBoxes) || 0), 0).toFixed(2);
        rows.push([
            { content: "", styles: { fontStyle: "bold" } },
            { content: "TOTAL", styles: { fontStyle: "bold" } },
            { content: "", styles: { fontStyle: "bold" } },
            { content: totalArea, styles: { halign: "center", fontStyle: "bold" } },
            { content: totalBoxes, styles: { halign: "center", fontStyle: "bold" } },
        ]);
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.01);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: [columns.map((col) => col.content)],
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                halign: "center",
                fontSize: 11,
            },
            bodyStyles: {
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 15 },
                1: { halign: "left", cellWidth: 57 },
                2: { halign: "center", cellWidth: 35 },
                3: { halign: "center", cellWidth: 40 },
                4: { halign: "center", cellWidth: 35 },
            },
            didDrawPage: (data) => {
                header();
                footer();
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        doc.save(filename);
        return doc.output("blob");
    };
    const floorSummaryPDF = async (summaryData) => {
        const doc = new jsPDF();
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const fileType = 'SC';
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const fileLabel = selectedFile && selectedFile.label ? selectedFile.label : `${selectedDate}`;
        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                const revisionNumber = matchingClientCalculations.length > 0 ? `R ${Math.max(matchingClientCalculations.length - 1, 0)}` : 'R0';
                return revisionNumber;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 'R 0';
            }
        };
        const getIncrement = async (fileLabel) => {
            try {
                const response = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/increment?fileLabel=${encodeURIComponent(fileLabel)}&fileType=${encodeURIComponent(fileType)}&clientId=${encodeURIComponent(clientId)}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch increment from the backend");
                }
                const increment = await response.json();
                return increment;
            } catch (error) {
                console.error('Error fetching increment:', error.message);
                return 0;
            }
        };
        const postIncrement = async (fileLabel, fileType) => {
            try {
                await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/updateIncrement', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileLabel, fileType, clientId }),
                });
            } catch (error) {
                console.error('Error posting increment:', error.message);
            }
        };

        let filename;
        const incrementValue = await getIncrement(fileLabel, fileType);
        if (selectedFile && selectedFile.label) {
            filename = `TMS ${fileType} ${clientId} - ${selectedFile.label}.${incrementValue}.pdf`;
        } else {
            const revisionNumber = await getRevisionNumber(siteName);
            filename = `TMS ${fileType} ${clientId} - ${selectedDate} - ${revisionNumber} - ${formatDate(currentDate)}.pdf`;
        }
        await postIncrement(fileLabel, fileType);
        const header = () => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            doc.setFontSize(10);
            const tmsDate = `TMS SC ${clientId} - ${selectedFile.label}.${incrementValue}`;
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("STOCKING CHART", doc.internal.pageSize.width - 46, 15);
            doc.setDrawColor("#BF9853");
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const columns = [
            { content: "SNO", styles: { halign: "center", fontStyle: "bold" } },
            { content: "FLOOR NAME", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TILE NAME", styles: { halign: "left", fontStyle: "bold" } },
            { content: "TILE SIZE", styles: { halign: "center", fontStyle: "bold" } },
            { content: "TOTAL AREA (SQFT)", styles: { halign: "center", fontStyle: "bold" } },
            { content: "NO OF BOXES", styles: { halign: "center", fontStyle: "bold" } },
        ];
        const rows = [];
        const floorData = {};
        let previousFloorName = "";
        let serialNumber = 1;
        summaryData.forEach(item => {
            const floorName = item.floorName && item.floorName.trim() !== "" ? item.floorName : previousFloorName;
            if (floorName) {
                previousFloorName = floorName;
            }
            const tileKey = `${item.tileName}|${item.size}`;
            if (!floorData[floorName]) {
                floorData[floorName] = {};
            }
            if (!floorData[floorName][tileKey]) {
                floorData[floorName][tileKey] = {
                    tileName: item.tileName,
                    size: item.size,
                    totalOrderedQuantity: 0,
                    totalBoxes: 0
                };
            }
            floorData[floorName][tileKey].totalOrderedQuantity += item.totalOrderedQuantity;
            floorData[floorName][tileKey].totalBoxes += item.totalBoxes;
        });
        for (const floor in floorData) {
            for (const tileKey in floorData[floor]) {
                const tile = floorData[floor][tileKey];
                rows.push([
                    { content: serialNumber++, styles: { halign: "center" } },
                    { content: floor, styles: { halign: "center" } },
                    { content: tile.tileName, styles: { halign: "left" } },
                    { content: tile.size, styles: { halign: "center" } },
                    { content: tile.totalOrderedQuantity.toFixed(2), styles: { halign: "center" } },
                    { content: tile.totalBoxes.toFixed(2), styles: { halign: "center" } },
                ]);
            }
        }
        const totalArea = summaryData.reduce((acc, row) => acc + (parseFloat(row.totalOrderedQuantity) || 0), 0).toFixed(2);
        const totalBoxes = summaryData.reduce((acc, row) => acc + (parseFloat(row.totalBoxes) || 0), 0).toFixed(2);
        rows.push([
            { content: "", styles: { fontStyle: "bold" } },
            { content: "TOTAL", styles: { fontStyle: "bold" }, fontSize: 12 },
            { content: "", styles: { fontStyle: "bold" } },
            { content: "", styles: { fontStyle: "bold" } },
            { content: totalArea, styles: { halign: "center", fontStyle: "bold" }, fontSize: 12 },
            { content: totalBoxes, styles: { halign: "center", fontStyle: "bold" }, fontSize: 12 },
        ]);
        const tableStartY = 45;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.01);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: [columns.map(col => col.content)],
            body: rows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                halign: "center",
                fontSize: 11,
            },
            bodyStyles: {
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 35 },
                2: { cellWidth: 48 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
                5: { cellWidth: 25 },
            },
            didDrawPage: (data) => {
                header();
                footer();
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        doc.save(filename);
        return doc.output("blob");
    };
    const fullPDF = async () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });
        const formatDateForName = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };
        const selectedDate = formatDateForName(date);
        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const fileType = 'EC';
        const siteName = clientName.label;
        const clientId = clientSNo || 0;
        const fileLabel = selectedFile && selectedFile.label ? selectedFile.label : `${selectedDate}`;
        const getRevisionNumber = async (clientName) => {
            try {
                const clientResponse = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/all`);
                if (!clientResponse.ok) {
                    throw new Error("Failed to fetch calculations from the backend");
                }
                const clientData = await clientResponse.json();
                const matchingClientCalculations = clientData.filter(calculation => calculation.clientName === clientName);
                const revisionNumber = matchingClientCalculations.length > 0 ? `R ${Math.max(matchingClientCalculations.length - 1, 0)}` : 'R0';
                return revisionNumber;
            } catch (error) {
                console.error('Error fetching revision number:', error.message);
                return 'R 0';
            }
        };
        const getIncrement = async (fileLabel) => {
            try {
                const response = await fetch(`https://backendaab.in/aabuilderDash/api/tile/tile/increment?fileLabel=${encodeURIComponent(fileLabel)}&fileType=${encodeURIComponent(fileType)}&clientId=${encodeURIComponent(clientId)}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch increment from the backend");
                }
                const increment = await response.json();
                return increment;
            } catch (error) {
                console.error('Error fetching increment:', error.message);
                return 0;
            }
        };
        const postIncrement = async (fileLabel, fileType) => {
            try {
                await fetch('https://backendaab.in/aabuilderDash/api/tile/tile/updateIncrement', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileLabel, fileType, clientId }),
                });
            } catch (error) {
                console.error('Error posting increment:', error.message);
            }
        };
        let filename;
        const incrementValue = await getIncrement(fileLabel, fileType);
        if (selectedFile && selectedFile.label) {
            filename = `TMS ${fileType} ${clientId} - ${selectedFile.label}.${incrementValue}.pdf`;
        } else {
            const revisionNumber = await getRevisionNumber(siteName);
            filename = `TMS ${fileType} ${clientId} - ${selectedDate} - ${revisionNumber} - ${formatDate(currentDate)}.pdf`;
        }
        await postIncrement(fileLabel, fileType);
        const header = (doc) => {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("TILE MEASUREMENT SHEET", 14, 15);
            doc.setFontSize(11);
            doc.setFont("helvetica", "bold");
            doc.setFontSize(11);
            const clientLabel = "CLIENT: ";
            doc.text(clientLabel, 14, 33);
            const labelWidth = doc.getTextWidth(clientLabel);
            doc.setFont("helvetica", "normal");
            const siteNameText = siteName.toUpperCase();
            doc.text(siteNameText, 14 + labelWidth, 33);
            const tmsDate = `TMS EC ${clientId} - ${selectedFile.label}.${incrementValue}`;
            const textWidth = doc.getTextWidth(tmsDate);
            const rightMargin = 14;
            const pageWidth = doc.internal.pageSize.width;
            const startX = pageWidth - rightMargin - textWidth;
            doc.text(tmsDate, startX, 27);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text("ENGINEER COPY", doc.internal.pageSize.width - 44, 15);
            doc.setFont("helvetica", "normal");
            doc.setDrawColor('#BF9853');
            doc.setLineWidth(1);
            doc.line(14, 20, doc.internal.pageSize.width - 14, 20);
        };
        const footer = () => {
            const pageWidth = doc.internal.pageSize.width;
            const footerY = doc.internal.pageSize.height - 17;
            doc.setDrawColor(150);
            doc.setLineWidth(0.5);
            doc.line(14, footerY, pageWidth - 14, footerY);
            doc.setFontSize(10.5);
            doc.setFont("helvetica", "bold");
            doc.text("AA BUILDERS", 14, doc.internal.pageSize.height - 12.5);
            doc.setFontSize(9);
            const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
            const currentPageText = `${currentPage} |`;
            const pageText = " P a g e";
            doc.setTextColor(0, 0, 0);
            doc.text(currentPageText, pageWidth - 19 - doc.getTextWidth(pageText), doc.internal.pageSize.height - 12.5);
            doc.setTextColor(200, 200, 200);
            doc.text(pageText, pageWidth - 25, doc.internal.pageSize.height - 12.5);
        };
        const customerHeaders = [
            [
                "SNO",
                "AREA NAME",
                "TYPE",
                "L (Ft)",
                "B (Ft)",
                "H (Ft)",
                "DEDUCTION AREA (SQFT)",
                "SKIRTING AREA (SQFT)",
                "WASTAGE (%)",
                "QTY TO BE ORDERED (SQFT)",
                "TILE NAME",
                "SIZE",
                "QTY/BOX",
                "NO OF BOXES",
            ],
        ];
        const customerRows = [];
        let previousFloorName = null;
        let floorCounter = 0;
        let areaCounter = 1;
        floors.forEach((floor) => {
            const currentFloorName = (floor.floorName || previousFloorName).toUpperCase();
            if (currentFloorName !== previousFloorName) {
                floorCounter += 1;
                areaCounter = 1;
                const floorLetter = String.fromCharCode(64 + floorCounter);
                customerRows.push([
                    { content: floorLetter, styles: { fontStyle: "bold" } },
                    { content: currentFloorName, styles: { fontStyle: "bold" } },
                    "", "", "", "", "", "", "", "", "", "", "", ""
                ]);
                previousFloorName = currentFloorName;
            }
            let areaNamePrinted = false;
            floor.tiles.forEach(tile => {
                const { length, breadth, height, wastagePercentage, deductionArea, type, quantityBox, tileName, size, areaTile } = tile;
                const lengthNum = Number(length);
                const breadthNum = Number(breadth);
                const heightNum = Number(height);
                const wastagePercentageNum = Number(wastagePercentage);
                const deductionAreaNum = Number(deductionArea || 0);
                let tileArea;
                let skirtingArea = tile.skirtingArea;
                if (skirtingArea === undefined || skirtingArea === null) {
                    skirtingArea = (type === "Wall Tile" || type === "Highlighter")
                        ? 0
                        : (tile.isUserChanged
                            ? Number(tile.directValue)
                            : ((lengthNum + breadthNum) * 0.33));
                }
                if (type === "Floor Tile") {
                    tileArea = lengthNum * breadthNum;
                } else if (type === "Wall Tile" || type === "Highlighter") {
                    tileArea = lengthNum * heightNum;
                }
                const finalArea = tileArea - deductionAreaNum;
                const actualQuantity = finalArea + skirtingArea;
                const wastage = (wastagePercentageNum / 100) * actualQuantity;
                const totalOrderedQuantity = actualQuantity + wastage;
                const areainsqft = Number(areaTile || 1);
                const noOfBoxes = (totalOrderedQuantity / (quantityBox * areainsqft)).toFixed(2);
                const areaNameCell = areaNamePrinted ? '' : floor.areaName;
                areaNamePrinted = true;
                customerRows.push([
                    areaCounter,
                    areaNameCell,
                    type,
                    length,
                    breadth,
                    height,
                    deductionAreaNum,
                    skirtingArea.toFixed(2),
                    `${wastagePercentage}%`,
                    totalOrderedQuantity.toFixed(2),
                    tileName,
                    size,
                    quantityBox,
                    noOfBoxes
                ]);
                areaCounter += 1;
            });
        });
        const tableStartY = 44;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.01);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.01);
        doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
        doc.autoTable({
            head: customerHeaders,
            body: customerRows,
            startY: tableStartY,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 10,
                halign: "center",
                lineWidth: 0,
            },
            bodyStyles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                halign: "left",
                font: "helvetica",
            },
            columnStyles: {
                0: { halign: "center", cellWidth: 12, minCellHeight: 2 },
                1: { halign: "left", cellWidth: 35, cellHeight: 2 },
                2: { halign: "left", cellWidth: 20, cellHeight: 2 },
                3: { halign: "center", cellWidth: 10, cellHeight: 2 },
                4: { halign: "center", cellWidth: 10, cellHeight: 2 },
                5: { halign: "center", cellWidth: 10, cellHeight: 2 },
                6: { halign: "center", cellWidth: 24.1, cellHeight: 2 },
                7: { halign: "center", cellWidth: 22, cellHeight: 2 },
                8: { halign: "center", cellWidth: 22, cellHeight: 2 },
                9: { halign: "center", cellWidth: 25, cellHeight: 2 },
                10: { halign: "left", cellWidth: 33, cellHeight: 2 },
                11: { halign: "center", cellWidth: 17, cellHeight: 2 },
                12: { halign: "center", cellWidth: 13, cellHeight: 2 },
                13: { halign: "center", cellWidth: 16, cellHeight: 2 },
            },
            margin: { left: 14, top: 44, bottom: 25 },
            pageBreak: 'auto',
            didDrawPage: (data) => {
                if (doc.internal.getCurrentPageInfo().pageNumber > 1) {
                    data.settings.startY = -30;
                    const tableStartY = 44;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.01);
                    doc.line(14, tableStartY - 0, doc.internal.pageSize.width - 14, tableStartY - 0);
                }
                header(doc);
                footer(doc);
            },
            didDrawCell: (data) => {
                if (data.section === "head") {
                    const { doc, cell } = data;
                    const startX = cell.x;
                    const startY = cell.y + cell.height;
                    const endX = cell.x + cell.width;
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.6);
                    doc.line(startX, startY, endX, startY);
                }
            },
        });
        doc.save(filename);
        return doc.output("blob");
    };
    let displayIndex = 1;
    const sortedSiteOptions = siteOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
    );
    const reversedFileOptions = [...filteredFileOptions].reverse();
    return (
        <body className="">
            <div className=" mx-auto p-6 border-collapse bg-[#FFFFFF] ml-6 mr-6 ">
                <div className=" flex">
                    <div className=" flex">
                        <div className="w-full -mt-8 mb-4">
                            <h4 className="mt-10 font-bold mb-2 -ml-[70%]">Project Name</h4>
                            <div className="relative group">
                                <Select
                                    value={clientName}
                                    onChange={handleSiteChange}
                                    options={sortedSiteOptions}
                                    placeholder="Select Site Name..."
                                    className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-96 h-12 text-left"
                                    styles={customSelectStyles}
                                    isClearable
                                />
                                {extraClient && (
                                    <div className="absolute top-full left-0 mt-2 bg-black text-white text-sm rounded p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                        {extraClient}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <h4 className=" font-bold -mb-8 mt-2">P.ID</h4>
                            <input
                                type="text"
                                value={clientSNo}
                                readOnly
                                className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg h-12 w-16 mt-10 ml-1 bg-transparent text-center"
                            />
                        </div>
                    </div>
                    <div className=" ml-6 mt-2">
                        <h4 className=" font-bold mb-2 -ml-32">Date </h4>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] w-44 rounded-lg px-4 py-2 "
                        />
                    </div>
                    <div>
                        <h4 className=" mt-1.5 font-bold -ml-20"> E No</h4>
                        <input
                            className="bg-gray-100 rounded-lg w-36 h-12 mt-2 ml-2 pl-4"
                            value={eno}
                            readOnly
                        />
                    </div>
                    <div className=" ml-6">
                        <h4 className=" mt-1.5 font-bold mb-2 -ml-32"> Revision</h4>
                        <Select
                            placeholder="Select the file..."
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-60 h-12 text-left"
                            styles={customSelectStyles}
                            options={reversedFileOptions}
                            isClearable
                            onChange={handleFileChange}
                            value={selectedFile}
                            isDisabled={!clientName}
                        />
                    </div>
                </div>
                <div className="flex ml-[90%] -mt-12">
                    <h4 className="font-normal text-lg mt-1">Wastage :</h4>
                    <select
                        name="wastagePercentageDropdown"
                        value={wastagePercentage}
                        onChange={handleCommonWastageChange}
                        className="ml-2 bg-transparent h-10 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg focus:outline-none">
                        {Array.from({ length: 16 }, (_, index) => (
                            <option key={index} value={index}>
                                {index}%
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className=" p-6 bg-[#FFFFFF] mt-6 ml-6 mr-6">
                <div className="rounded-lg border-l-8 border-l-[#BF9853] flex mt-5" id="full-table">
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="bg-[#FAF6ED]">
                                <th className="w-40 text-left pl-2" rowSpan="2">Description</th>
                                <th className="w-36 text-left pl-5" rowSpan="2">Type</th>
                                <th className="w-32 text-lg " colSpan="3" style={{ letterSpacing: '0.2em' }}>Measurement</th>
                                <th className="w-14 " rowSpan="2">Deduction Area (sqft)</th>
                                <th className="w-12 " rowSpan="2">Skirting Area (sqft)</th>
                                <th className="w-12 " rowSpan="2">Wastage (sqft)</th>
                                <th className="w-16 " rowSpan="2">Order Qty (sqft)</th>
                                <th className="w-60 text-left" rowSpan="2">Tile Name</th>
                                <th className="w-40 text-left" rowSpan="2">Size</th>
                                <th className="w-20 text-left" rowSpan="2">Qty/Box</th>
                                <th className="w-20 text-left" rowSpan="2">No.Box</th>
                            </tr>
                            <tr className="bg-[#FAF6ED]">
                                <th className="w-6 text-[#E4572E] ">L</th>
                                <th className="w-6 text-[#E4572E] ">B</th>
                                <th className="w-6 text-[#E4572E] ">H</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floors.map((floor, floorIndex) => (
                                <React.Fragment key={floorIndex}>
                                    <tr className="bg-gray-50">
                                        <td colSpan="13" className="font-bold text-left pl-2">
                                            {floor.floorName !== null && (
                                                <div>
                                                    <span className="mt-1">{displayIndex++}.</span>
                                                    <span>{selectedClientData.floorName}</span>
                                                    <select
                                                        value={floor.floorName || ""}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            if (newValue) {
                                                                const updatedFloors = [...floors];
                                                                updatedFloors[floorIndex].floorName = newValue;
                                                                setFloors(updatedFloors);
                                                                const selectedValues = updatedFloors.map((f) => f.floorName).filter(Boolean);
                                                                setSelectedOptions(new Set(selectedValues));
                                                            }
                                                        }}
                                                        className="w-52 p-1 rounded-lg bg-transparent focus:outline-none">
                                                        <option value="" disabled>Select Floor..</option>
                                                        {floorOptions
                                                            .filter(
                                                                (floorOption) =>
                                                                    !selectedOptions.has(floorOption) || floorOption === floor.floorName
                                                            )
                                                            .map((floorOption, idx) => (
                                                                <option key={idx} value={floorOption}>
                                                                    {floorOption}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    {floor.tiles.map((tile, tileIndex) => {
                                        const globalRowIndex = floorIndex + tileIndex;
                                        return (
                                            <tr
                                                key={`${floorIndex}-${tileIndex}`}
                                                className={globalRowIndex % 2 === 0 ? "bg-[#FAF6ED]" : "bg-white"}>
                                                <td className="px-1 flex group ml-3">
                                                    {tileIndex === 0 ? (
                                                        <Select
                                                            name="areaName"
                                                            options={areaOptions.map(option => ({ value: option, label: option }))}
                                                            value={floor.areaName ? { value: floor.areaName, label: floor.areaName } : null}
                                                            onChange={(selectedOption) => {
                                                                const updatedFloors = [...floors];
                                                                updatedFloors[floorIndex].areaName = selectedOption ? selectedOption.value : '';
                                                                setFloors(updatedFloors);
                                                            }}
                                                            className="w-72 h-10 text-left ml-3"
                                                            placeholder="Select Area"
                                                            isClearable
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: 'transparent',
                                                                    border: 'none',
                                                                    boxShadow: 'none',
                                                                }),
                                                                dropdownIndicator: (base) => ({
                                                                    ...base,
                                                                    color: '#000',
                                                                }),
                                                                indicatorSeparator: () => ({
                                                                    display: 'none',
                                                                }),
                                                                placeholder: (base) => ({
                                                                    ...base,
                                                                    color: '#888',
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: '#000',
                                                                }),
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className="w-52"></div>
                                                    )}
                                                    {tileIndex === 0 && (
                                                        <div key={floorIndex} className="items-center flex space-x-2 invisible group-hover:visible">
                                                            <button onClick={() => addAreaRow(floorIndex)}>
                                                                <img src={add} alt="add" className="w-4 h-4" />
                                                            </button>
                                                            <button onClick={() => deleteAreaRow(floorIndex)} className="ml-2">
                                                                <img src={delt} alt="delete" className="w-4 h-4 " />
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="px-1 w-40">
                                                    <div className="flex group">
                                                        <select
                                                            name="type"
                                                            value={tile.type}
                                                            onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                            className="rounded-lg bg-transparent">
                                                            {tileFloorTypes.map((floorType) => (
                                                                <option key={floorType.id} value={floorType.floorType}>
                                                                    {floorType.floorType}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="flex items-center ml-3 space-x-2 invisible group-hover:visible">
                                                            <button type="button" onClick={() => addTile(floorIndex, tileIndex)}>
                                                                <img src={add} alt="add" className="w-4 h-4" />
                                                            </button>
                                                            <button onClick={() => deleteTileRow(floorIndex, tileIndex)}>
                                                                <img src={delt} alt="delete" className="w-4 h-4" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-1 ">
                                                    <input
                                                        type="text"
                                                        name="length"
                                                        placeholder="L"
                                                        value={tile.length}
                                                        onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                        onKeyDown={(e) => handleKeyDown(floorIndex, tileIndex, e)} // KeyDown handler
                                                        className="px-2 w-14 bg-transparent hover:border focus:outline-none text-center"
                                                    />
                                                </td>
                                                <td className="px-1">
                                                    <input
                                                        type="text"
                                                        name="breadth"
                                                        placeholder="B"
                                                        value={tile.breadth}
                                                        onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                        disabled={tile.type !== "Floor Tile"}
                                                        className="px-2 w-14 bg-transparent hover:border focus:outline-none text-center"
                                                    />
                                                </td>
                                                <td className="px-1">
                                                    <input
                                                        type="text"
                                                        name="height"
                                                        placeholder="H"
                                                        value={tile.height}
                                                        onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                        disabled={tile.type === "Floor Tile"}
                                                        className="px-2 w-14 bg-transparent hover:border focus:outline-none text-center"
                                                    />
                                                </td>
                                                <td className="px-2">
                                                    <input
                                                        type="text"
                                                        name="deductionArea"
                                                        value={tile.deductionArea}
                                                        onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                        onKeyDown={(e) => handleKeyDown(floorIndex, tileIndex, e)}
                                                        placeholder="Deduction"
                                                        className="px-2 w-20 bg-transparent hover:border focus:outline-none"
                                                    />
                                                </td>
                                                <td className="px-2">
                                                    <div className="flex items-center">
                                                        <input
                                                            type="text"
                                                            name="directValue"
                                                            value={
                                                                tile.type === "Floor Tile"
                                                                    ? calculateSkirtingArea(tile)
                                                                    : "0"
                                                            }
                                                            onChange={(e) => handleDirectValueChange(floorIndex, tileIndex, e)}
                                                            className="px-2 rounded-lg w-20 bg-transparent"
                                                        />
                                                        {tile.isUserChanged && (
                                                            <button onClick={() => resetToCalculatedValue(floorIndex, tileIndex)}>
                                                                <img src={refresh} alt="refresh" className="h-5 w-5" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-2 ">
                                                    <select
                                                        name="wastagePercentage"
                                                        value={tile.wastagePercentage}
                                                        onChange={(e) => handleTileChange(floorIndex, tileIndex, e)}
                                                        className=" rounded-lg w-16 bg-transparent focus:outline-none">
                                                        {Array.from({ length: 16 }, (_, index) => (
                                                            <option key={index} value={index}>
                                                                {index}%
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td className="px-2">
                                                    <div className="w-16">
                                                        {tile.length && (tile.breadth || tile.height) ? (
                                                            (() => {
                                                                const length = Number(tile.length);
                                                                const breadth = Number(tile.breadth);
                                                                const height = Number(tile.height);
                                                                const wastagePercentage = Number(tile.wastagePercentage);
                                                                const deductionArea = Number(tile.deductionArea || 0);
                                                                let tileArea;
                                                                if (tile.type === "Floor Tile") {
                                                                    tileArea = length * breadth;
                                                                } else if (tile.type === "Wall Tile" || tile.type === "Highlighter") {
                                                                    tileArea = length * height;
                                                                }
                                                                const skirtingArea = calculateSkirtingArea(tile);
                                                                const finalArea = tileArea - deductionArea;
                                                                const actualQuantity = finalArea + Number(skirtingArea);
                                                                const wastage = (wastagePercentage / 100) * actualQuantity;
                                                                const totalOrderedTile = actualQuantity + wastage;
                                                                return totalOrderedTile.toFixed(2);
                                                            })()
                                                        ) : (
                                                            "0"
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-2 text-left">
                                                    <Select
                                                        options={tileOptions}
                                                        value={tileOptions.find((option) => option.value === tile.tileName)}
                                                        onChange={(selectedOption) => {
                                                            const newTileName = selectedOption?.value || '';
                                                            handleTileChange(floorIndex, tileIndex, {
                                                                target: {
                                                                    name: "tileName",
                                                                    value: newTileName
                                                                }
                                                            });
                                                            if (!newTileName) {
                                                                handleTileChange(floorIndex, tileIndex, {
                                                                    target: { name: "size", value: '' }
                                                                });
                                                                handleTileChange(floorIndex, tileIndex, {
                                                                    target: { name: "qtyPerBox", value: '' }
                                                                });
                                                                handleTileChange(floorIndex, tileIndex, {
                                                                    target: { name: "Areainsqft", value: '' }
                                                                });
                                                            } else {
                                                                const selectedTile = tileOptions.find(tile => tile.value === newTileName);
                                                                if (selectedTile) {
                                                                    handleTileChange(floorIndex, tileIndex, {
                                                                        target: {
                                                                            name: "size",
                                                                            value: selectedTile.tileSize
                                                                        }
                                                                    });
                                                                    handleTileChange(floorIndex, tileIndex, {
                                                                        target: {
                                                                            name: "qtyPerBox",
                                                                            value: selectedTile.qtyPerBox || ''
                                                                        }
                                                                    });
                                                                    handleTileChange(floorIndex, tileIndex, {
                                                                        target: {
                                                                            name: "Areainsqft",
                                                                            value: selectedTile.Areainsqft || ''
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                        placeholder="Select Tile"
                                                        menuPortalTarget={document.body}
                                                        className="w-80"
                                                        menuPosition="fixed"
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            control: (base) => ({
                                                                ...base,
                                                                backgroundColor: 'transparent',
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                textAlign: 'left',
                                                            }),
                                                            dropdownIndicator: (base) => ({
                                                                ...base,
                                                                color: '#000',
                                                            }),
                                                            indicatorSeparator: () => ({
                                                                display: 'none',
                                                            }),
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                color: '#888',
                                                                textAlign: 'left',
                                                            }),
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                color: '#000',
                                                                textAlign: 'left',
                                                            }),
                                                            menu: (base) => ({
                                                                ...base,
                                                                textAlign: 'left',
                                                            }),
                                                        }}
                                                        isClearable
                                                    />
                                                </td>
                                                <td className="px-2">
                                                    <div className="flex items-center">
                                                        <select
                                                            value={tile.size || ""}
                                                            onChange={(e) => {
                                                                const newSize = e.target.value;
                                                                handleSizeChange(floorIndex, tileIndex, newSize);
                                                            }}
                                                            className="rounded px-1 bg-transparent"
                                                        >
                                                            <option value="">Select Size</option>
                                                            {tileList.map((sizeOption) => (
                                                                <option key={sizeOption.tileSize} value={sizeOption.tileSize}>
                                                                    {sizeOption.tileSize}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {tile.size !== tile.tileSize &&
                                                            (<span className="ml-2 text-red-500">
                                                                <img src={change} alt="Change indicator" className="w-5 h-5 mt-1" />
                                                            </span>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td className="px-2">
                                                    <div className="flex items-center">
                                                        <input
                                                            type="text"
                                                            value={tile.quantityBox}
                                                            onChange={(e) => {
                                                                handleTileChange(floorIndex, tileIndex, {
                                                                    target: { name: "quantityBox", value: e.target.value },
                                                                });
                                                            }}
                                                            className="rounded px-1 w-12 bg-transparent"
                                                        />
                                                        {Number(tile.quantityBox) !== Number(tile.correctQuantityBox) && (
                                                            <span className="ml-2 text-red-500">
                                                                <img src={change} alt="change indicator" className="w-5 h-5 mt-1" />
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-2 w-16">
                                                    <div>
                                                        {tile.length && (tile.breadth || tile.height) ? (
                                                            (() => {
                                                                const length = Number(tile.length);
                                                                const breadth = Number(tile.breadth);
                                                                const height = Number(tile.height);
                                                                const wastagePercentage = Number(tile.wastagePercentage);
                                                                const deductionArea = Number(tile.deductionArea || 0);
                                                                let tileArea;
                                                                if (tile.type === "Floor Tile") {
                                                                    tileArea = length * breadth;
                                                                } else if (tile.type === "Wall Tile" || tile.type === "Highlighter") {
                                                                    tileArea = length * height;
                                                                }
                                                                const skirtingArea = calculateSkirtingArea(tile);
                                                                const finalArea = tileArea - deductionArea;
                                                                const actualQuantity = finalArea + Number(skirtingArea);
                                                                const wastage = (wastagePercentage / 100) * actualQuantity;
                                                                const totalOrderedTile = actualQuantity + wastage;
                                                                const qtyPerBox = Number(tile.quantityBox || 1);
                                                                const Areainsqft = Number(tile.areaTile || 1);
                                                                const finalResult = totalOrderedTile / (qtyPerBox * Areainsqft);
                                                                return finalResult.toFixed(2);
                                                            })()
                                                        ) : (
                                                            "0"
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <button type="button" className="text-[#E4572E] mt-6 mb-20 -ml-[94%] border-dashed border-b-2 border-[#BF9853] font-semibold"
                        onClick={addFloorRow}>
                        + Add Floor
                    </button>
                </div>
                <div className=" buttons -mt-14 flex">
                    <div className="">
                        <button onClick={customerCopyPDF} className=" text-white px-8 py-2 rounded bg-[#007233] hover:text-white transition duration-200 ease-in-out">
                            Customer Copy
                        </button>
                    </div>

                    <div>
                        <button onClick={summaryPDF} className=" text-white px-8 py-2 rounded ml-2 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out">
                            Order Copy
                        </button>
                    </div>
                    <div className="">
                        <button onClick={fullPDF} className=" text-white px-8 py-2 rounded ml-2 bg-[#007233] hover:text-white transition duration-200 ease-in-out">
                            Engineer Copy
                        </button>
                    </div>
                    <div className="">
                        <button onClick={handleGeneratePDF} className=" text-white px-8 py-2 rounded ml-2 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out">
                            Stocking Chart
                        </button>
                    </div>
                    <div>
                        {isPopupOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-3 rounded-lg shadow-lg text-center">
                                    <div>
                                        <img src={loadingScreen} alt="Loading..." className="w-10 h-10 mx-auto" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {isModalOpen && <PopupModal />}
                        <form onSubmit={handleSubmit}>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="bg-[#BF9853] text-white px-6 py-2 rounded-lg ml-[62rem]"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                <div className="-mt-3 flex">
                    <div>
                        <div>
                            <h1 className="font-bold text-2xl mt-8 -ml-[67%]">Tile Order Copy </h1>
                        </div>
                        <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                            <table className="table-auto mt-2">
                                <thead>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="font-extrabold p-2">S.No</th>
                                        <th className="p-2 font-extrabold text-left">Tile Name</th>
                                        <th className="p-2 font-extrabold">Tile Size</th>
                                        <th className="p-2 w-40 font-extrabold">Total Area (sqft)</th>
                                        <th className="p-2 w-36 font-extrabold">No.Box</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary.map((row, index) => {
                                        const totalOrderedQuantity = Number(row.totalOrderedQuantity) || 0;
                                        const totalBoxes = Number(row.totalBoxes) || 0;
                                        return (
                                            <tr key={index} className="odd:bg-white even:bg-[#FAF6ED]">
                                                <td className="p-2 text-left">{(index + 1).toString().padStart(1, '0')}</td>
                                                <td className="p-2 text-left">{row.tileName}</td>
                                                <td className="p-2 text-left">{row.size}</td>
                                                <td className="p-2 w-20">{totalOrderedQuantity.toFixed(2)}</td>
                                                <td className="p-2 w-20">{totalBoxes.toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr className="bg-[#FAF6ED] font-bold">
                                        <td className="p-2 font-bold" colSpan="3">Total</td>
                                        <td className="p-2 font-bold">{(Number(totalArea) || 0).toFixed(2)}</td>
                                        <td className="p-2 font-bold">{(Number(totalBoxes) || 0).toFixed(2)}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                    </div>
                    <div className=" ml-10">
                        <div >
                            <h1 className="font-bold text-2xl mt-8 -ml-[60%]">Tile Stocking Chart </h1>
                        </div>
                        <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                            <table id="summaryTable" className="table-auto mt-2">
                                <thead>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="font-extrabold p-2">S.No</th>
                                        <th className="p-2 font-extrabold">Floor Name</th>
                                        <th className="p-2 font-extrabold text-left">Tile Name</th>
                                        <th className="p-2 font-extrabold">Tile Size</th>
                                        <th className="p-2 font-extrabold">Total Area (sqft)</th>
                                        <th className="p-2 font-extrabold">No.Box</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary1.map((item, index) => (
                                        <tr key={index} className="odd:bg-white even:bg-[#FAF6ED]">
                                            <td className="p-2 text-left ">{(index + 1).toString().padStart(1, '0')}</td>
                                            <td className="p-2 text-left ">{item.floorName}</td>
                                            <td className="p-2 text-left ">{item.tileName}</td>
                                            <td className="p-2 text-left ">{item.tileSize}</td>
                                            <td className="p-2 ">{parseFloat(item.totalOrderedQuantity || 0).toFixed(2)}</td>
                                            <td className="p-2 ">{parseFloat(item.totalBoxes || 0).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                    <tr className="bg-[#FAF6ED] font-bold">
                                        <td className="p-2 font-bold" colSpan="4">Total</td>
                                        <td className="p-2 font-bold">{totalArea.toFixed(2)}</td>
                                        <td className="p-2 font-bold">{totalBoxes.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {isClientName && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div
                            className="bg-white rounded-md w-[9rem] h-[4rem] p-2 relative"
                            ref={inputRef} // Attach the ref to the container
                        >
                            <input
                                type="text"
                                name="extraClient"
                                className="w-32 border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] p-2 rounded h-12 focus:outline-none"
                                value={extraClient}
                                onChange={(e) => setExtraClient(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setIsClientName(false); // Close the popup
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </body>
    );
};
export default DesignTool;