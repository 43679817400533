import React, { useState, useEffect } from "react";
import Select from "react-select";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Right from '../Images/Right.svg'
import Wrong from '../Images/Worng.svg'
import delt from '../Images/Worng.svg';
import add from '../Images/Right.svg';

const RccCalculator = () => {
    const [activeTab, setActiveTab] = useState("formwork");
    const [isOpen, setIsOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen1, setIsPopupOpen1] = useState(false);
    const [isPopupOpen2, setIsPopupOpen2] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [floorOptions, setFloorOptions] = useState([]);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    const [rateLabel, setRateLabel] = useState("Rate (sqft)");
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Update the label based on the selected tab
        if (tab === "steel") {
            setRateLabel("Rate (kg)");
        } else if (tab === "formwork") {
            setRateLabel("Rate (sqft)");
        } else {
            setRateLabel(""); // Reset the label for other cases
        }

        setInputValue(""); // Replace `setInputValue` with the relevant state update function
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [billData, setBillData] = useState([
        {
            name: "Floor 1",
            rows: [
                {
                    description: "Tile Work",
                    type: "RB 01",
                    size: "12\"x12\"",
                    length: 5,
                    breadth: 5,
                    height: 0,
                    area: 25,
                    deductionArea: 5,
                    totalArea: 20,
                    rate: 50,
                    amount: 1000,
                },
            ],
        },
    ]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/floorName');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const areas = data.map(item => item.floorName);
                setFloorOptions([...new Set(areas)]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const [areaOptions, setAreaOptions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://backendaab.in/aabuilderDash/api/tile/areaName');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const areas = data.map(item => item.areaName);
                setAreaOptions([...new Set(areas)]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const [isPopupOpen3, setIsPopupOpen3] = useState(false);

    const openPopup = () => {
        setIsPopupOpen3(true);
    };

    const closePopup = () => {
        setIsPopupOpen3(false);
    };
    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            borderColor: state.isFocused ? "#FAF6ED" : "transparent",
            "&:hover": {
                borderColor: "none",
            },

            boxShadow: state.isFocused ? "0 0 0 #FAF6ED" : "none",
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#000',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const [rows, setRows] = useState([
        {
            description: 'Roof Beam',
            type: 'RB 01',
            size: '12"x12"',
            length: '',
            breadth: '',
            height: '',
            area: '',
            deductionArea: '',
            totalArea: '',
            rate: '',
            amount: '',
        },
    ]);

    const [floors, setFloors] = useState([
        {
            floorName: "Ground Floor",
            areaName: "",
            tiles: [
                { type: '', size: '', length: '', breadth: '', height: '', area: '', deductionArea: '', totalArea: '', rate: '', amount: '' },
            ],
        },
    ]);

    const handleRowChange = (floorIndex, rowIndex, field, value) => {
        setFloors((prevFloors) => {
            return prevFloors.map((floor, fIndex) =>
                fIndex === floorIndex
                    ? {
                        ...floor,
                        rows: floor.rows.map((row, rIndex) =>
                            rIndex === rowIndex ? { ...row, [field]: value } : row
                        ),
                    }
                    : floor
            );
        });
    };


    const removeRow1 = (floorIndex, tileIndex) => {
        const updatedFloors = [...floors];
        updatedFloors[floorIndex].tiles.splice(tileIndex, 1);
        setFloors(updatedFloors);
    };
    const addRow = (floorIndex) => {
        setFloors((prevFloors) =>
            prevFloors.map((floor, index) =>
                index === floorIndex
                    ? {
                        ...floor,
                        rows: [
                            ...floor.rows,
                            { description: '', type: '', size: '', length: '', breadth: '', height: '', area: '', deductionArea: '', totalArea: '', rate: '', amount: '' },
                        ],
                    }
                    : floor
            )
        );
    };
    const addRow1 = (floorIndex) => {
        setFloors((prevFloors) =>
            prevFloors.map((floor, index) =>
                index === floorIndex
                    ? {
                        ...floor,
                        rows: [
                            ...floor.rows,
                            { type: '', size: '', length: '', breadth: '', height: '', area: '', deductionArea: '', totalArea: '', rate: '', amount: '' },
                        ],
                    }
                    : floor
            )
        );
    };

    // Add a new floor
    const addFloor = () => {
        setFloors([
            ...floors,
            {
                name: 'New Floor',
                rows: [
                    { description: '', type: '', size: '', length: '', breadth: '', height: '', area: '', deductionArea: '', totalArea: '', rate: '', amount: '' },
                ],
            },
        ]);
    };




    // Function to export PDF (dummy function for now)
    const exportPDF = () => {
        const doc = new jsPDF();
        const element = document.getElementById("full-table");
        doc.html(element, {
            callback: (doc) => {
                doc.save("Generated_Bill.pdf");
            },
        });
    };

    const addNewRowAfter = (floorIndex, tileIndex) => {
        const newTile = {
            type: 'RB 01',
            length: '',
            breadth: '',
            height: '',
            deductionArea: '',
            wastagePercentage: '0',
        };
        const updatedFloors = floors.map((floor, index) => {
            if (index === floorIndex) {
                const updatedTiles = [...floor.tiles];
                updatedTiles.splice(tileIndex + 1, 0, newTile);
                return { ...floor, tiles: updatedTiles };
            }
            return floor;
        });
        setFloors(updatedFloors);
    };

    const [selectedGroundFloor, setSelectedGroundFloor] = useState([]);
    const [selectedFirstFloor, setSelectedFirstFloor] = useState([]);
    const [groundFloorOpen, setGroundFloorOpen] = useState(false);
    const [firstFloorOpen, setFirstFloorOpen] = useState(false);

    const handleGroundFloorChange = (e) => {
        const value = e.target.value;
        setSelectedGroundFloor(prevState =>
            prevState.includes(value)
                ? prevState.filter(item => item !== value)
                : [...prevState, value]
        );
    };

    const handleFirstFloorChange = (e) => {
        const value = e.target.value;
        setSelectedFirstFloor(prevState =>
            prevState.includes(value)
                ? prevState.filter(item => item !== value)
                : [...prevState, value]
        );
    };
    const deleteAreaRow = (floorIndex) => {
        const updatedFloors = [...floors];
        updatedFloors.splice(floorIndex, 1);
        setFloors(updatedFloors);
    };
    const addAreaRow = (floorIndex) => {
        const updatedFloors = [...floors];
        updatedFloors.splice(floorIndex + 1, 0, {
            floorName: null,
            areaName: "",
            tiles: [
                { type: "", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" },
            ],
        });
        setFloors(updatedFloors);
    };
    const handleInputChange = (floorIndex, tileIndex, field, value) => {
        const updatedFloors = [...floors];
        updatedFloors[floorIndex].tiles[tileIndex][field] = value;
        setFloors(updatedFloors);
    };
    
    const addFloorRow = () => {
        setFloors((prevFloors) => [
            ...prevFloors,
            {
                floorName: "",
                areaName: "",
                tiles: [
                    { type: "", length: "", breadth: "", height: "", deductionArea: "", wastagePercentage: "0" },
                ],
            },
        ]);
    };
    let displayIndex = 1;
    return (
        <body className="">
            <div className="-mt-12 mb-3">
                <button
                    className="w-44 h-9 bg-[#BF9853] rounded text-white"
                    style={{ marginLeft: "1690px" }}
                    onClick={openPopup}
                >
                    Order Summary
                </button>
            </div>
            {/* Popup */}
            {isPopupOpen3 && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                    onClick={closePopup} // Close when clicking outside
                >
                    <div
                        className="bg-white  shadow-lg  p-6" style={{ width: '500px' }}
                        onClick={(e) => e.stopPropagation()} // Prevent close on clicking inside
                    >
                        {/* Popup Header */}
                        <div className="flex justify-between items-center  pb-3">
                            <h2 className="text-lg font-semibold text-[#E4572E] ml-40">Ramar Krishnankovil</h2>
                            {/* Close Button */}
                            <button
                                className="text-[#E4572E] transition"
                                onClick={closePopup}
                            >
                                ✖
                            </button>
                        </div>
                        <table className=" text-left w-full text-sm mt-4">
                            <thead className="bg-[#FAF6ED]">
                                <tr>
                                    <th className="px-4 py-2 text-base font-bold">Floor Name</th>
                                    <th className="px-4 py-2 text-base font-bold">Beam Name</th>
                                    <th className="px-4 py-2 text-base font-bold">Size</th>
                                    <th className="px-4 py-2 text-base font-bold">SQFT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Sample Rows */}
                                <tr>
                                    <td className="px-4 py-2 font-semibold">First Floor</td>
                                    <td className="px-4 py-2 font-semibold">Roof Beam</td>
                                    <td className="px-4 py-2 font-semibold">12"X12"</td>
                                    <td className="px-4 py-2 font-semibold">3</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 font-semibold">First Floor</td>
                                    <td className="px-4 py-2 font-semibold">Roof Beam</td>
                                    <td className="px-4 py-2 font-semibold">2"X2"</td>
                                    <td className="px-4 py-2 font-semibold">0.5</td>
                                </tr>
                                {/* Repeat rows dynamically */}
                            </tbody>
                        </table>
                        <div className="flex gap-16 mt-4 text-lg font-semibold text-[#E4572E]" style={{ marginLeft: '290px' }}>
                            <h1>Total</h1>
                            <h1 >17</h1>
                        </div>
                    </div>
                </div>
            )}
            <div className=" mx-auto p-6 border-collapse bg-[#FFFFFF] ml-6 mr-6 rounded-md">
                <div className=" flex">
                    <div className=" flex">
                        <div className="w-full -mt-8 mb-4">
                            <h4 className=" mt-10 font-bold mb-2 -ml-60">Site Name </h4>
                            <Select
                                placeholder="Select Site Name..."
                                className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-80 h-12 text-left"
                                styles={customSelectStyles}
                                isClearable />
                        </div>
                    </div>
                    <div className=" ml-6 mt-1">
                        <h4 className=" font-bold mb-2 -ml-32">Date </h4>
                        <input
                            type="date"
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] w-44 rounded-lg px-4 py-2 "
                        />
                    </div>
                    <div>
                        <h4 className=" mt-1.5 font-bold -ml-20"> E No</h4>
                        <input
                            className="bg-gray-100 rounded-lg w-36 h-12 mt-2 ml-2 pl-4"
                            readOnly
                        />
                    </div>
                    <div className=" ml-6">
                        <h4 className=" mt-1.5 font-bold mb-2 -ml-32"> Revision</h4>
                        <Select
                            placeholder="Select the file..."
                            className="border border-[#FAF6ED] border-r-[0.25rem] border-l-[0.25rem] border-b-[0.25rem] border-t-[0.25rem] rounded-lg w-52 h-12"
                            styles={customSelectStyles}
                            isClearable
                        />
                    </div>
                </div>
                <div className="flex ml-[80%] -mt-5">
                    <h4 className="font-semibold text-lg mt-1">{rateLabel}</h4>

                    {/* Conditionally render the input box based on the active tab */}
                    {activeTab !== "concrete" && (
                        <input
                            value={inputValue}
                            className="ml-2 bg-transparent w-16 h-10 border-2 border-[#FAF6ED] rounded-lg focus:outline-none"
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    )}

                    <button className="ml-5 mt-1 bg-[#007233] w-28 h-8 rounded-md text-white">
                        + Import
                    </button>
                </div>
            </div>
            <div className="mt-5">
                {/* Content Section */}
                <div className="mt-5">
                    {/* Tab Section */}
                    <div className="tabs flex ml-12 gap-5">
                        <button
                            className={`p-2 ${activeTab === "formwork"
                                ? "font-bold text-lg border-b-2 border-[#DAA520]"
                                : "font-semibold text-black"
                                }`}
                            onClick={() => handleTabChange("formwork")}
                        >
                            FormWork
                        </button>
                        <button
                            className={`p-2 ${activeTab === "steel"
                                ? "font-bold text-lg border-b-2 border-[#DAA520]"
                                : "font-semibold text-black"
                                }`}
                            onClick={() => handleTabChange("steel")}
                        >
                            Steel
                        </button>
                        <button
                            className={`p-2 ${activeTab === "concrete"
                                ? "font-bold text-lg border-b-2 border-[#DAA520]"
                                : "font-semibold text-black"
                                }`}
                            onClick={() => handleTabChange("concrete")}
                        >
                            Concrete
                        </button>
                    </div>
                </div>
            </div>

            <div className="content">
                {activeTab === "formwork" && (
                    <div className=" p-6 bg-[#FFFFFF] ml-6 mr-6  rounded-lg">
                        <div className="rounded-lg border-l-8 border-l-[#BF9853] flex -mt-3" id="full-table">
                            <table className="table-auto w-full border-collapse">
                                <thead>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-28 text-left pl-2 align-middle" rowSpan="2">Description</th>
                                        <th className="w-20 text-center align-middle" rowSpan="2">Type</th>
                                        <th className="w-20 text-center align-middle" rowSpan="2">Size</th>
                                        <th className="text-center text-lg align-middle" colSpan="3">Measurement</th>
                                        <th className="w-24 text-center align-middle" rowSpan="2">Area (sqft)</th>
                                        <th className="w-28 text-center align-middle" rowSpan="2">Deduction Area (sqft)</th>
                                        <th className="w-28 text-center align-middle" rowSpan="2">Total Area (sqft)</th>
                                        <th className="w-24 text-center align-middle" rowSpan="2">Rate (sqft)</th>
                                        <th className="w-28 text-center align-middle" rowSpan="2">Total Amount</th>
                                    </tr>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-16 text-[#E4572E] text-center">L</th>
                                        <th className="w-16 text-[#E4572E] text-center">B</th>
                                        <th className="w-16 text-[#E4572E] text-center">H</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {floors.map((floor, floorIndex) => (
                                        <React.Fragment key={floorIndex}>
                                            <tr className="bg-gray-50">
                                                <td colSpan="13" className="font-bold text-left pl-2">
                                                    {floor.floorName !== null && (
                                                        <div>
                                                            <span className="mt-1">{displayIndex++}.</span>
                                                            <select
                                                                value={floor.floorName || ""}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value;
                                                                    if (newValue) {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].floorName = newValue;
                                                                        setFloors(updatedFloors);
                                                                        const selectedValues = updatedFloors.map((f) => f.floorName).filter(Boolean);
                                                                        setSelectedOptions(new Set(selectedValues));
                                                                    }
                                                                }}
                                                                className="w-40 p-1 rounded-lg bg-transparent focus:outline-none">
                                                                <option value="" disabled>Select Floor..</option>
                                                                {floorOptions
                                                                    .filter(
                                                                        (floorOption) =>
                                                                            !selectedOptions.has(floorOption) || floorOption === floor.floorName
                                                                    )
                                                                    .map((floorOption, idx) => (
                                                                        <option key={idx} value={floorOption}>
                                                                            {floorOption}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            {floor.tiles.map((tile, tileIndex) => {
                                                const globalRowIndex = floorIndex + tileIndex;
                                                return (
                                                    <tr
                                                        key={`${floorIndex}-${tileIndex}`}
                                                        className={globalRowIndex % 2 === 0 ? "bg-[#FAF6ED]" : "bg-white"}>
                                                        <td className="px-1 flex group ml-3">
                                                            {tileIndex === 0 ? (
                                                                <Select
                                                                    name="areaName"
                                                                    options={areaOptions.map(option => ({ value: option, label: option }))}
                                                                    value={floor.areaName ? { value: floor.areaName, label: floor.areaName } : null}
                                                                    onChange={(selectedOption) => {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].areaName = selectedOption ? selectedOption.value : '';
                                                                        setFloors(updatedFloors);
                                                                    }}
                                                                    className="w-44 h-10 text-left ml-3"
                                                                    placeholder="Select Area"
                                                                    isClearable
                                                                    styles={{
                                                                        control: (base) => ({
                                                                            ...base,
                                                                            backgroundColor: 'transparent',
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                        }),
                                                                        dropdownIndicator: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorSeparator: () => ({
                                                                            display: 'none',
                                                                        }),
                                                                        placeholder: (base) => ({
                                                                            ...base,
                                                                            color: '#888',
                                                                        }),
                                                                        singleValue: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className="w-52"></div>
                                                            )}
                                                            {tileIndex === 0 && (
                                                                <div key={floorIndex} className="items-center flex space-x-2 invisible group-hover:visible">
                                                                    <button onClick={() => addAreaRow(floorIndex)}>
                                                                        <img src={add} alt="add" className="w-4 h-4" />
                                                                    </button>
                                                                    <button onClick={() => deleteAreaRow(floorIndex)} className="ml-2">
                                                                        <img src={delt} alt="delete" className="w-4 h-4 " />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                        {/* Type and Buttons */}
                                                        <td className="text-center w-[67px] px-2 group">
                                                            <div className="flex items-center">
                                                                <select
                                                                    className="w-full bg-transparent rounded-sm px-2"
                                                                    placeholder= 'Select RB'
                                                                    value={tile.type || ''}
                                                                    onChange={(e) =>
                                                                        handleInputChange(floorIndex, tileIndex, 'type', e.target.value)
                                                                    }
                                                                >
                                                                    <option value="RB 01">RB 01</option>
                                                                    <option value="RB 02">RB 02</option>
                                                                    <option value="RB 03">RB 03</option>
                                                                    <option value="RB 04">RB 04</option>
                                                                    <option value="RB 05">RB 05</option>
                                                                </select>
                                                                <div className="flex ml-2 space-x-1 w-12 invisible group-hover:visible">
                                                                    <button onClick={() => addNewRowAfter(floorIndex, tileIndex)}>
                                                                        <img src={Right} alt="add" className="h-4" />
                                                                    </button>
                                                                    <button>
                                                                        <img src={Wrong} onClick={() => removeRow1(floorIndex, tileIndex)} alt="delete" className="h-4" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* Size */}
                                                        <td className="text-center px-2">
                                                            <select
                                                                className="w-[100px] h-[27px] focus:outline-gray-200 bg-transparent rounded-sm px-2"
                                                                value={tile.size}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'size', e.target.value)
                                                                }
                                                            >
                                                                <option value='12"x12"'>12"x12"</option>
                                                                <option value='2"x2"'>2"x2"</option>
                                                                <option value='9"x9"'>9"x9"</option>
                                                            </select>
                                                        </td>
                                                        {/* Measurements */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[32px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="L"
                                                                value={tile.length}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'length', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[32px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="B"
                                                                value={tile.breadth || ""}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'breadth', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[32px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="H"
                                                                value={tile.height}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'height', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        {/* Area */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[52px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="Select Area"
                                                                value={tile.area}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'area', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        {/* Deduction */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[66px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="Select Deduction"
                                                                value={tile.deductionArea}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'deductionArea', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        {/* Total Area */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[52px] bg-transparent text-center focus:outline-gray-200 rounded-sm px-1"
                                                                placeholder="Total Area"
                                                                value={tile.totalArea}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'totalArea', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        {/* Rate */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[46px] bg-gray-200 text-center focus:outline-gray-200 rounded-sm px-1"
                                                                value={tile.rate}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'rate', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                        {/* Total Amount */}
                                                        <td className="text-center px-2">
                                                            <input
                                                                type="text"
                                                                className="w-[90px] bg-gray-200 text-right focus:outline-gray-200 rounded-sm px-1"
                                                                value={tile.amount}
                                                                onChange={(e) =>
                                                                    handleInputChange(floorIndex, tileIndex, 'amount', e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <button type="button" onClick={addFloorRow} className="text-[#E4572E] mt-6 mb-20 -ml-[94%] border-dashed border-b-2 border-[#BF9853] font-semibold">
                                + Add Floor
                            </button>
                        </div>
                        <div className=" buttons -mt-14 flex">
                            <div className="">
                                <button className="w-56 text-white px-6 py-2 rounded ml-2 bg-green-800 hover:text-white transition duration-200 ease-in-out">
                                    Engineer Copy
                                </button>
                            </div>
                            <div className="">
                                <button className="w-44 h-10  text-white px-8 py-2 rounded ml-3 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out"
                                    onClick={openModal}>
                                    Generate Bill
                                </button>

                                {isModalOpen && (
                                    <div
                                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                        onClick={closeModal} // Closes modal on background click
                                    >
                                        <div
                                            className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 max-h-[90vh] overflow-y-auto p-6"
                                            onClick={(e) => e.stopPropagation()} // Prevents modal close on content click
                                        >
                                            {/* Modal Content */}
                                            <h2 className="text-xl text-center font-bold">Generated Bill</h2>
                                            <div className="text-left border-b pb-3">
                                                <h1 className="flex font-semibold">Site Name:<span className="ml-2">Ramar Krishnankovil</span></h1>
                                                <h1 className="ml-10 flex font-semibold">Date: <span className="ml-2">{new Date().toLocaleDateString()}</span> </h1>
                                            </div>
                                            {/* Table Content */}
                                            <div className="rounded-lg flex -mt-3 overflow-hidden border" id="full-table">
                                                <table className="w-full text-left text-sm">
                                                    <thead className="bg-[#E6E6E6]">
                                                        <tr>
                                                            <th className="px-4 py-2 font-bold text-base">Description</th>
                                                            <th className="px-4 py-2 font-bold text-base">Type</th>
                                                            <th className="px-4 py-2 font-bold text-base">Size</th>
                                                            <th className="px-4 py-2 font-bold text-base">L</th>
                                                            <th className="px-4 py-2 font-bold text-base">B</th>
                                                            <th className="px-4 py-2 font-bold text-base">H</th>
                                                            <th className="px-4 py-2 font-bold text-base">Area (sqft)</th>
                                                            <th className="px-4 py-2 font-bold text-base">Deduction Area (sqft)</th>
                                                            <th className="px-4 py-2 font-bold text-base">Total Area</th>
                                                            <th className="px-4 py-2 font-bold text-base">Rate (sqft)</th>
                                                            <th className="px-4 py-2 font-bold text-base">Total Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {billData.map((floor, floorIndex) => (
                                                            <React.Fragment key={floorIndex}>
                                                                <tr className="bg-gray-100">
                                                                    <td className="px-4 py-2 font-bold" colSpan="11">
                                                                        {floor.name}
                                                                    </td>
                                                                </tr>
                                                                {floor.rows.map((row, rowIndex) => (
                                                                    <tr className="border-b" key={rowIndex}>
                                                                        <td className="px-4 py-2 font-semibold">{row.description}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.type}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.size}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.length}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.breadth}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.height}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.area}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.deductionArea}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.totalArea}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.rate}</td>
                                                                        <td className="px-4 py-2 font-semibold">{row.amount}</td>
                                                                    </tr>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex ml-[80%]">
                                <button
                                    type="submit"
                                    className="btn bg-[#BF9853] w-32 text-white px-8 py-2 rounded-md  font-semibold -ml-56">Submit
                                </button>
                            </div>
                        </div>
                        <div className="-mt-3 flex">
                            <div>
                                <div>
                                    <h1 className="font-bold text-lg mt-8 -ml-[77%]">Item Summary </h1>
                                </div>
                                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                                    <table className="table-auto mt-2">
                                        <thead>
                                            <tr className="bg-[#FAF6ED]">
                                                <th className="font-extrabold p-2">S.No</th>
                                                <th className="p-2 font-extrabold">Floor Name</th>
                                                <th className="p-2 font-extrabold">Member Name</th>
                                                <th className="p-2 w-40 font-extrabold">Size</th>
                                                <th className="p-2 w-40 font-extrabold">Total Area</th>
                                                <th className="p-2 w-36 font-extrabold">Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className=" ml-10">
                                <div >
                                    <h1 className="font-bold text-lg mt-8 -ml-[72%]">Overall Summary </h1>
                                </div>
                                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                                    <table id="summaryTable" className="table-auto mt-2">
                                        <thead>
                                            <tr className="bg-[#FAF6ED]">
                                                <th className="font-extrabold p-2">S.No</th>
                                                <th className="p-2 font-extrabold">Floor Name</th>
                                                <th className="p-2 font-extrabold">Member Name</th>
                                                <th className="p-2 font-extrabold">Total Area</th>
                                                <th className="p-2 font-extrabold">Qty</th>
                                                <th className="p-2 font-extrabold">Amount</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ml-80 mt-5">
                                    <th>Total</th>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
            <div className="content">
                {activeTab === "steel" && (
                    <div className=" p-6 bg-[#FFFFFF] ml-6 mr-6 rounded-lg">
                        <div className="rounded-lg border-l-8 border-l-[#BF9853] flex -mt-3" id="full-table">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-40 text-left pl-2 align-middle" rowSpan="2">Description</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">Type</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">Configuration</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">Size</th>
                                        <th className="w-32 text-center align-middle text-lg " colSpan="3" style={{ letterSpacing: '0.2em' }}>Measurement</th>
                                        <th className="w-14 text-center align-middle" rowSpan="2">8MM</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">10MM</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">12MM</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">16MM</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">20MM</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">25MM</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">32MM</th>
                                        <th className="w-20 text-center align-middle" rowSpan="2">Total Weight(kg)</th>
                                        <th className="w-20 text-center align-middle" rowSpan="2">Rate (kg)</th>
                                        <th className="w-20 text-center align-middle" rowSpan="2">Total Amount</th>
                                    </tr>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-6 text-[#E4572E] ">L</th>
                                        <th className="w-6 text-[#E4572E] ">B</th>
                                        <th className="w-6 text-[#E4572E] ">H</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {floors.map((floor, floorIndex) => (
                                        <React.Fragment key={floorIndex}>
                                            <tr className="bg-gray-50">
                                                <td colSpan="13" className="font-bold text-left pl-2">
                                                    {floor.floorName !== null && (
                                                        <div>
                                                            <span className="mt-1">{displayIndex++}.</span>
                                                            <select
                                                                value={floor.floorName || ""}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value;
                                                                    if (newValue) {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].floorName = newValue;
                                                                        setFloors(updatedFloors);
                                                                        const selectedValues = updatedFloors.map((f) => f.floorName).filter(Boolean);
                                                                        setSelectedOptions(new Set(selectedValues));
                                                                    }
                                                                }}
                                                                className="w-40 p-1 rounded-lg bg-transparent focus:outline-none">
                                                                <option value="" disabled>Select Floor..</option>
                                                                {floorOptions
                                                                    .filter(
                                                                        (floorOption) =>
                                                                            !selectedOptions.has(floorOption) || floorOption === floor.floorName
                                                                    )
                                                                    .map((floorOption, idx) => (
                                                                        <option key={idx} value={floorOption}>
                                                                            {floorOption}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            {floor.tiles.map((tile, tileIndex) => {
                                                const globalRowIndex = floorIndex + tileIndex;
                                                return (
                                                    <tr
                                                        key={`${floorIndex}-${tileIndex}`}
                                                        className={globalRowIndex % 2 === 0 ? "bg-[#FAF6ED]" : "bg-white"}>
                                                        <td className="px-1 flex group ml-3">
                                                            {tileIndex === 0 ? (
                                                                <Select
                                                                    name="areaName"
                                                                    options={areaOptions.map(option => ({ value: option, label: option }))}
                                                                    value={floor.areaName ? { value: floor.areaName, label: floor.areaName } : null}
                                                                    onChange={(selectedOption) => {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].areaName = selectedOption ? selectedOption.value : '';
                                                                        setFloors(updatedFloors);
                                                                    }}
                                                                    className="w-44 h-10 text-left ml-3"
                                                                    placeholder="Select Area"
                                                                    isClearable
                                                                    styles={{
                                                                        control: (base) => ({
                                                                            ...base,
                                                                            backgroundColor: 'transparent',
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                        }),
                                                                        dropdownIndicator: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorSeparator: () => ({
                                                                            display: 'none',
                                                                        }),
                                                                        placeholder: (base) => ({
                                                                            ...base,
                                                                            color: '#888',
                                                                        }),
                                                                        singleValue: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className="w-52"></div>
                                                            )}
                                                            {tileIndex === 0 && (
                                                                <div key={floorIndex} className="items-center flex space-x-2 invisible group-hover:visible">
                                                                    <button onClick={() => addAreaRow(floorIndex)}>
                                                                        <img src={add} alt="add" className="w-4 h-4" />
                                                                    </button>
                                                                    <button onClick={() => deleteAreaRow(floorIndex)} className="ml-2">
                                                                        <img src={delt} alt="delete" className="w-4 h-4 " />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                        {/* Type and Buttons */}
                                                        <td className="text-center w-[67px] px-2 group">
                                                            <div className="flex items-center">
                                                                <select
                                                                    className="w-32 bg-transparent rounded-sm px-2"
                                                                    value={tile.type}
                                                                    onChange={(e) =>
                                                                        handleInputChange(floorIndex, tileIndex, 'type', e.target.value)
                                                                    }
                                                                >
                                                                    <option value="RB 01">RB 01</option>
                                                                    <option value="RB 02">RB 02</option>
                                                                    <option value="RB 03">RB 03</option>
                                                                    <option value="RB 04">RB 04</option>
                                                                    <option value="RB 05">RB 05</option>
                                                                </select>
                                                                <div className="flex ml-2 space-x-1 w-12 invisible group-hover:visible">
                                                                    <button onClick={() => addNewRowAfter(floorIndex, tileIndex)}>
                                                                        <img src={Right} alt="add" className="h-4" />
                                                                    </button>
                                                                    <button>
                                                                        <img src={Wrong} onClick={() => removeRow1(floorIndex, tileIndex)} alt="delete" className="h-4" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <div>
                                                            <button
                                                                className="bg-[#BF9853] w-16 text-sm h-7 text-white rounded"
                                                                onClick={togglePopup}
                                                            >
                                                                Edit
                                                            </button>
                                                            {isOpen && (
                                                                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                                                    <div className="bg-white p-6 rounded h-5/6 relative" style={{ width: '600px' }}>
                                                                        <h2 className="text-lg font-bold text-[#E4572E] -mb-4" style={{ marginLeft: '-370px' }}>Rebar Configuration</h2><h1 className="text-lg font-semibold cursor-pointer text-red-400" style={{ marginLeft: '450px' }} onClick={togglePopup}>x</h1>
                                                                        <p className="mb-2 font-bold" style={{ marginLeft: '-430px' }}>First Floor RB01</p>
                                                                        <div className="flex gap-6">
                                                                            {/* Left side - Form */}
                                                                            <div className="flex">
                                                                                <div className="flex">
                                                                                    {/*first table*/}
                                                                                    <table className="h-72 border-collapse w-52 mb-4 border">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="border p-2">Layer</th>
                                                                                                <th className="border p-2">Qty</th>
                                                                                                <th className="border p-2">Dia</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {/* Example Rows */}
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center">T1</td>
                                                                                                <td className="border p-2 text-center">2</td>
                                                                                                <td className="border p-2 text-center">16MM</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center">T2</td>
                                                                                                <td className="border p-2 text-center">-</td>
                                                                                                <td className="border p-2 text-center">-</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center">B1</td>
                                                                                                <td className="border p-2 text-center">2</td>
                                                                                                <td className="border p-2 text-center">12MM</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                {/*Second table*/}
                                                                                <div className="block">
                                                                                    <table className="ml-3 border-collapse w-40 h-72 mb-4 border">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="border p-2">Qty</th>
                                                                                                <th className="border p-2">Dia</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {/* Example Rows */}
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                                <td className="border p-2 text-center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div className="grid grid-cols-1 gap-2 -ml-44 mt-10">
                                                                                        <div className="flex ml-80">
                                                                                            <h1 className="text-[#E4572E] text-lg -ml-5 font-semibold">B</h1>
                                                                                            <h1 className="ml-16 text-lg text-[#E4572E] font-semibold">D</h1>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" -ml-8 border h-9 font-semibold border-[#f0e6d2] bg-[#fcf8f8] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none px-2 py-1" style={{ width: '280px' }}
                                                                                                defaultValue={"Stirrups Size"}
                                                                                            />
                                                                                            <input className="border  ml-6 w-16 px-2 mr-1 py border-[#f0e6d2] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none"></input>  x<input className="mr-5 ml-2 border border-[#f0e6d2] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-16 px-2 py"></input>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <input className=" -ml-8 border h-9 font-semibold border-[#f0e6d2] bg-[#fcf8f8] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-full px-2 py-1 " style={{ width: '280px' }}
                                                                                                defaultValue={"Stirrups Dia"}
                                                                                            />
                                                                                            <select className=" ml-6 border border-[#f0e6d2] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-40 px-2 py">
                                                                                                <option>8MM</option>
                                                                                                <option>12MM</option>
                                                                                                <option>16MM</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" -ml-8 border h-9 border-[#f0e6d2] font-semibold bg-[#fcf8f8] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-full px-2 py-1" style={{ width: '280px' }}
                                                                                                defaultValue={"Stirrups Spacing"}
                                                                                            />
                                                                                            <input className=" ml-6 border border-[#f0e6d2] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-20 px-2 py"></input>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" -ml-8 border h-9 border-[#f0e6d2] font-semibold bg-[#fcf8f8] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-full px-2 py-1" style={{ width: '280px' }}
                                                                                                defaultValue={"Concrete Cover"}
                                                                                            />
                                                                                            <input className=" ml-6 border border-[#f0e6d2] border-r-[0.10rem] border-l-[0.10rem] border-b-[0.10rem] border-t-[0.10rem] rounded focus:outline-none w-20 px-2 py"></input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* Buttons */}
                                                                        <div className="mt-6 flex justify-end gap-4 mr-28">
                                                                            <button
                                                                                className="bg-[#007233] text-white px-4 py-2 w-32 rounded "

                                                                            >
                                                                                Cut List
                                                                            </button>
                                                                            <button className="bg-white text-[#DF9853] w-32 font-semibold border border-[#f3cc88] px-4 py-2 rounded"
                                                                                onClick={togglePopup}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                            <button className="bg-[#BF9853] w-32 text-white px-4 py-2 rounded">
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <td className="-mt-2">
                                                            <select className="bg-transparent">
                                                                <option>12"x12"</option>
                                                                <option>2"x2"</option>
                                                                <option>9"x9"</option>
                                                            </select>
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="length"
                                                                placeholder="L"
                                                                className="px-2 w-10 bg-transparent  hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="breadth"
                                                                placeholder="B"
                                                                className="px-2 w-10 bg-transparent hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="height"
                                                                placeholder="H"
                                                                className="px-2 w-10 bg-transparent hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input className="px-2 w-10 bg-transparent " placeholder="Select" />
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input className="w-14 bg-transparent" 
                                                            
                                                            ></input>
                                                        </td>
                                                        <td className="px-2">
                                                            <input className="w-10 bg-transparent"></input>
                                                        </td>
                                                        <td className="px-2">
                                                            <input className="w-10 bg-transparent"></input>
                                                        </td>
                                                        <td className="px-2">
                                                            <input className="w-10 bg-transparent"></input>
                                                        </td>
                                                        <td className="px-2">
                                                            <input className="w-10 bg-transparent"></input>
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input
                                                                className=" w-10 bg-transparent h-7 rounded p-2"
                                                                defaultValue=""
                                                            />
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input className=" bg-transparent" style={{ width: '45px' }}></input>
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input className="w-12 text-right bg-gray-200 rounded"></input>
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input
                                                                className="bg-gray-200 w-20 h-7 text-right rounded p-2"
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <button type="button" className="text-[#E4572E] mt-6 mb-20 -ml-[94%] border-dashed border-b-2 border-[#BF9853] font-semibold"
                                onClick={addFloorRow}>
                                + Add Floor
                            </button>
                        </div>
                        <div className=" buttons -mt-14 flex">
                            <div className="">
                                <button className="w-44 h-10  text-white px-8 py-2 rounded ml-2 bg-green-800 hover:text-white transition duration-200 ease-in-out">
                                    Engineer Copy
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setIsPopupOpen(true)} // Open popup
                                    className="w-44 h-10 text-white px-8 py-2 rounded ml-3 bg-green-800 hover:text-white transition duration-200 ease-in-out"
                                >
                                    Order Copy
                                </button>

                            </div>
                            {/* Popup */}
                            {isPopupOpen && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    {/* Popup Container */}
                                    <div className="bg-white  shadow-lg w-[450px] h-48 p-4">
                                        {/* Header with Close Button */}
                                        <div className="flex justify-between items-center mb-4">
                                            <h2 className="text-sm font-bold -ml-5 "><input type="checkbox" className="rounded-xl accent-green-100 w-14 -mr-3"></input> Select all</h2>
                                            <button
                                                onClick={() => setIsPopupOpen(false)} // Close popup
                                                className="text-red-500 text-2xl font-bold hover:text-red-700"
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        {/* Checkbox Options */}
                                        <div className="space-y-4">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ground-floor"
                                                    className=" bg-[#EBEBEB] rounded-xl accent-green-100 text-sm border-gray-300   ml-20 "
                                                />
                                                <select className="text-base font-bold ">
                                                    <option>Ground Floor</option>
                                                </select>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="first-floor"
                                                    className=" rounded-xl accent-green-100  border-gray-300 ml-20"
                                                />
                                                <select htmlFor="first-floor" className="text-base font-bold">
                                                    <option>First Floor</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* Buttons */}
                                        <div className="flex justify-end space-x-4 mt-2">
                                            <button
                                                onClick={() => {
                                                    setIsPopupOpen(false); // Perform action and close popup
                                                    console.log("Order Copy generated!");
                                                }}
                                                className="bg-[#BF9853] text-white px-4 py-2  rounded" style={{ width: '214px' }}
                                            >
                                                Generate Order Copy
                                            </button>
                                            <button
                                                onClick={() => setIsPopupOpen(false)} // Close popup
                                                className="border border-[#BF9853] px-4 py-2 rounded-md" style={{ width: '114px', marginRight: '50px' }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="">
                                <button className="w-44 h-10  text-white px-8 py-2 rounded ml-3 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out">
                                    Generate Bill
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setIsPopupOpen2(true)}
                                    className="w-32 h-10 text-white px-8 py-2 rounded ml-3 bg-green-800 hover:text-white transition duration-200 ease-in-out"
                                >
                                    Cut List
                                </button>
                                {isPopupOpen2 && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                        {/* Popup Container */}
                                        <div className="bg-white shadow-lg w-[400px] p-5 rounded-md">
                                            {/* Header with Close Button */}
                                            <div className="flex justify-between items-center mb-4">
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        className="rounded-xl accent-green-200"
                                                    />
                                                    <span className="text-sm font-semibold">Select all</span>
                                                </div>
                                                <button
                                                    onClick={() => setIsPopupOpen2(false)}
                                                    className="text-red-500 text-xl font-bold hover:text-red-700"
                                                >
                                                    &times;
                                                </button>
                                            </div>

                                            {/* Ground Floor Dropdown */}
                                            <div className="mb-4">
                                                <div
                                                    className="flex items-center justify-between cursor-pointer"
                                                    onClick={() => setGroundFloorOpen(!groundFloorOpen)}
                                                >
                                                    <span className="text-base font-semibold w-60">Ground Floor</span>
                                                    <span className={`text-sm ${groundFloorOpen ? 'rotate-180' : ''}`}>
                                                        ▼
                                                    </span>
                                                </div>
                                                {groundFloorOpen && (
                                                    <div className="mt-2 pl-4">
                                                        <label className="block mr-10">
                                                            <input
                                                                type="checkbox"
                                                                value="roof-beam"
                                                                checked={selectedGroundFloor.includes("roof-beam")}
                                                                onChange={handleGroundFloorChange}
                                                                className="rounded-xl accent-green-200"
                                                            />
                                                            Roof Beam
                                                        </label>
                                                        <label className="block mr-8">
                                                            <input
                                                                type="checkbox"
                                                                value="plinth-beam"
                                                                checked={selectedGroundFloor.includes("plinth-beam")}
                                                                onChange={handleGroundFloorChange}
                                                                className="rounded-xl accent-green-200"
                                                            />
                                                            Plinth Beam
                                                        </label>
                                                    </div>
                                                )}
                                            </div>


                                            {/* First Floor Dropdown */}
                                            <div className="mb-4">
                                                <div
                                                    className="flex items-center justify-between cursor-pointer"
                                                    onClick={() => setFirstFloorOpen(!firstFloorOpen)}
                                                >
                                                    <span className="text-base font-semibold w-56">First Floor</span>
                                                    <span className={`text-sm ${firstFloorOpen ? 'rotate-180' : ''}`}>
                                                        ▼
                                                    </span>
                                                </div>
                                                {firstFloorOpen && (
                                                    <div className="mt-2 pl-4">
                                                        <label className="block mr-10">
                                                            <input
                                                                type="checkbox"
                                                                value="lintel-beam"
                                                                checked={selectedFirstFloor.includes("lintel-beam")}
                                                                onChange={handleFirstFloorChange}
                                                                className=""
                                                            />
                                                            Lintel Beam
                                                        </label>
                                                        <label className="block mr-[68px]">
                                                            <input
                                                                type="checkbox"
                                                                value="column"
                                                                checked={selectedFirstFloor.includes("column")}
                                                                onChange={handleFirstFloorChange}
                                                                className=""
                                                            />
                                                            Column
                                                        </label>
                                                        <label className="block mr-[93px]">
                                                            <input
                                                                type="checkbox"
                                                                value="slab"
                                                                checked={selectedFirstFloor.includes("slab")}
                                                                onChange={handleFirstFloorChange}
                                                                className=""
                                                            />
                                                            Slab
                                                        </label>
                                                    </div>
                                                )}
                                            </div>


                                            {/* Buttons */}
                                            <div className="flex justify-between items-center mt-5">
                                                <button
                                                    onClick={() => {
                                                        setIsPopupOpen2(false);
                                                        console.log("Cut List generated!");
                                                    }}
                                                    className="bg-[#BF9853] text-white px-4 py-2 rounded w-60 font-semibold"
                                                >
                                                    Generate Cut List
                                                </button>
                                                <button
                                                    onClick={() => setIsPopupOpen2(false)}
                                                    className="border border-[#BF9853] px-4 py-2 rounded w-24 font-semibold"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button onClick={() => setIsPopupOpen1(true)} className="w-44 h-10  text-white px-8 py-2 rounded ml-3 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out">
                                Stirrups List
                            </button>
                            {isPopupOpen1 && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    {/* Popup Container */}
                                    <div className="bg-white  shadow-lg w-[450px] h-48 p-4">
                                        {/* Header with Close Button */}
                                        <div className="flex justify-between items-center mb-4">
                                            <h2 className="text-sm font-bold -ml-5 "><input type="checkbox" className="rounded-xl accent-green-100 w-14 -mr-3"></input> Select all</h2>
                                            <button
                                                onClick={() => setIsPopupOpen1(false)} // Close popup
                                                className="text-red-500 text-2xl font-bold hover:text-red-700"
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        {/* Checkbox Options */}
                                        <div className="space-y-4">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="ground-floor"
                                                    className=" bg-[#EBEBEB] rounded-xl accent-green-100 text-sm border-gray-300   ml-20 "
                                                />
                                                <select className="text-base font-bold ">
                                                    <option>Ground Floor</option>
                                                </select>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="first-floor"
                                                    className=" rounded-xl accent-green-100  border-gray-300 ml-20"
                                                />
                                                <select htmlFor="first-floor" className="text-base font-bold">
                                                    <option>First Floor</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* Buttons */}
                                        <div className="flex justify-end space-x-4 mt-2">
                                            <button
                                                onClick={() => {
                                                    setIsPopupOpen1(false); // Perform action and close popup
                                                    console.log("Order Copy generated!");
                                                }}
                                                className="bg-[#BF9853] text-white px-4 py-2  rounded " style={{ width: '214px' }}
                                            >
                                                Generate Stirrups List
                                            </button>
                                            <button
                                                onClick={() => setIsPopupOpen1(false)} // Close popup
                                                className="border border-[#BF9853] px-4 py-2 rounded-md" style={{ width: '114px', marginRight: '50px' }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex ml-[52%]">
                                <button
                                    type="submit"
                                    className="btn bg-[#BF9853] text-white px-8 py-2 rounded-md w-32 h-10 font-semibold -ml-36">Submit
                                </button>
                            </div>
                        </div>
                        <div className="-mt-3 flex">
                            <div className="mt-3">
                                <div className="flex justify-between">
                                    <h1 className="font-bold text-lg mt-12 -mb-2 ">Overall Summary</h1>
                                    <h1 className="font-bold text-lg mt-8 ml-96 ">Wastage : <input className="bg-transparent w-16 h-9 border border-[#FAF6ED] border-r-[0.20rem] border-l-[0.20rem] border-b-[0.20rem] border-t-[0.20rem] rounded-lg focus:outline-none"></input></h1>
                                    <h1 className="font-bold text-sm mt-12 text-[#E4572E] hover:underline cursor-pointer">Export PDF</h1>
                                </div>
                                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                                    <table className="table-auto mt-2" style={{ width: '800px' }}>
                                        <thead>
                                            <tr className="bg-[#FAF6ED]">
                                                <th className="font-extrabold p-2">S.No</th>
                                                <th className="p-2 w-40 font-extrabold">Floor Name</th>
                                                <th className="p-2 w-32 font-extrabold">Member Name</th>
                                                <th className="p-2 w-20 font-extrabold">Dia of Bar</th>
                                                <th className="p-2  font-extrabold">Total Length</th>
                                                <th className="p-2  font-extrabold">Wastage</th>
                                                <th className="p-2  font-extrabold">Required Qty</th>
                                                <th className="p-2  font-extrabold">Weight (kg)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-5" style={{ marginLeft: '540px' }}>
                                    <th>Total</th>
                                </div>
                            </div>
                            <div className=" ml-10">
                                <div className="flex justify-between">
                                    <h1 className="font-bold text-lg mt-10 ">Stirrups Summary</h1>
                                    <h1 className="font-bold text-sm mt-11 text-[#E4572E] hover:underline cursor-pointer">Export PDF</h1>
                                </div>
                                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                                    <table id="summaryTable" className="table-auto mt-">
                                        <thead>
                                            <tr className="bg-[#FAF6ED]">
                                                <th className="font-extrabold p-2">S.No</th>
                                                <th className="p-2 font-extrabold">Floor Name</th>
                                                <th className="p-2 w-32 font-extrabold">Member Name</th>
                                                <th className="p-2 w-28 font-extrabold">Member Size</th>
                                                <th className="p-2 w-20 font-extrabold">Dia of Bar</th>
                                                <th className="p-2 w-24 font-extrabold">Stirrup Size</th>
                                                <th className="p-2 font-extrabold">Qty</th>
                                                <th className="p-2 w-20 font-extrabold">Weight kg</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-5" style={{ marginLeft: '520px' }}>
                                    <th>Total</th>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {activeTab === "concrete" && (
                    <div className=" p-6 bg-[#FFFFFF] ml-6 mr-6 rounded-lg">
                        <div className="flex -mt-6 mb-3" style={{ marginLeft: '1300px' }}>
                            <h1 className="font-semibold -mt-12 -ml-40 w-60 flex">Cement Rate/Bag: <input className="ml-2 bg-white w-14 h-8 border border-[#f0e6d2] border-r-[0.15rem] border-l-[0.15rem] border-b-[0.15rem] border-t-[0.15rem] rounded focus:outline-none"></input></h1>
                            <h1 className="ml-2 font-semibold -mt-12 w-60 flex">Sand Rate/Unit: <input className="ml-2 bg-white w-14 h-8 border border-[#f0e6d2] border-r-[0.15rem] border-l-[0.15rem] border-b-[0.15rem] border-t-[0.15rem] rounded focus:outline-none"></input></h1>
                            <h1 className=" ml-2 font-semibold -mt-12 w-60 flex">Jally Rate/Unit: <input className="ml-2 bg-white w-14 h-8 border border-[#f0e6d2] border-r-[0.15rem] border-l-[0.15rem] border-b-[0.15rem] border-t-[0.15rem] rounded focus:outline-none"></input></h1>
                        </div>
                        <div className="rounded-lg border-l-8 border-l-[#BF9853] flex mt-1" id="full-table">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-20 text-left pl-2" rowSpan="2">Description</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Type</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Mix</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Size</th>
                                        <th className="w-32 text-lg text-center align-middle" colSpan="3" style={{ letterSpacing: '0.2em' }}>Measurement</th>
                                        <th className="w-12 text-center align-middle" rowSpan="2">Cement</th>
                                        <th className="w-14 text-center align-middle" rowSpan="2">Sand</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Jally</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Total Valume(CFT)</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Labour Rate</th>
                                        <th className="w-16 text-center align-middle" rowSpan="2">Total Amount</th>
                                    </tr>
                                    <tr className="bg-[#FAF6ED]">
                                        <th className="w-6 text-[#E4572E] ">L</th>
                                        <th className="w-6 text-[#E4572E] ">B</th>
                                        <th className="w-6 text-[#E4572E] ">H</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {floors.map((floor, floorIndex) => (
                                        <React.Fragment key={floorIndex}>
                                            <tr className="bg-gray-50">
                                                <td colSpan="13" className="font-bold text-left pl-2">
                                                    {floor.floorName !== null && (
                                                        <div>
                                                            <span className="mt-1">{displayIndex++}.</span>
                                                            <select
                                                                value={floor.floorName || ""}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value;
                                                                    if (newValue) {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].floorName = newValue;
                                                                        setFloors(updatedFloors);
                                                                        const selectedValues = updatedFloors.map((f) => f.floorName).filter(Boolean);
                                                                        setSelectedOptions(new Set(selectedValues));
                                                                    }
                                                                }}
                                                                className="w-40 p-1 rounded-lg bg-transparent focus:outline-none">
                                                                <option value="" disabled>Select Floor..</option>
                                                                {floorOptions
                                                                    .filter(
                                                                        (floorOption) =>
                                                                            !selectedOptions.has(floorOption) || floorOption === floor.floorName
                                                                    )
                                                                    .map((floorOption, idx) => (
                                                                        <option key={idx} value={floorOption}>
                                                                            {floorOption}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            {floor.tiles.map((tile, tileIndex) => {
                                                const globalRowIndex = floorIndex + tileIndex;
                                                return (
                                                    <tr
                                                        key={`${floorIndex}-${tileIndex}`}
                                                        className={globalRowIndex % 2 === 0 ? "bg-[#FAF6ED]" : "bg-white"}>
                                                        <td className="px-1 flex group ml-3">
                                                            {tileIndex === 0 ? (
                                                                <Select
                                                                    name="areaName"
                                                                    options={areaOptions.map(option => ({ value: option, label: option }))}
                                                                    value={floor.areaName ? { value: floor.areaName, label: floor.areaName } : null}
                                                                    onChange={(selectedOption) => {
                                                                        const updatedFloors = [...floors];
                                                                        updatedFloors[floorIndex].areaName = selectedOption ? selectedOption.value : '';
                                                                        setFloors(updatedFloors);
                                                                    }}
                                                                    className="w-44 h-10 text-left ml-3"
                                                                    placeholder="Select Area"
                                                                    isClearable
                                                                    styles={{
                                                                        control: (base) => ({
                                                                            ...base,
                                                                            backgroundColor: 'transparent',
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                        }),
                                                                        dropdownIndicator: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                        indicatorSeparator: () => ({
                                                                            display: 'none',
                                                                        }),
                                                                        placeholder: (base) => ({
                                                                            ...base,
                                                                            color: '#888',
                                                                        }),
                                                                        singleValue: (base) => ({
                                                                            ...base,
                                                                            color: '#000',
                                                                        }),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className="w-52"></div>
                                                            )}
                                                            {tileIndex === 0 && (
                                                                <div key={floorIndex} className="items-center flex space-x-2 invisible group-hover:visible">
                                                                    <button onClick={() => addAreaRow(floorIndex)}>
                                                                        <img src={add} alt="add" className="w-4 h-4" />
                                                                    </button>
                                                                    <button onClick={() => deleteAreaRow(floorIndex)} className="ml-2">
                                                                        <img src={delt} alt="delete" className="w-4 h-4 " />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                        {/* Type and Buttons */}
                                                        <td className="text-center w-[67px] px-2 group">
                                                            <div className="flex items-center">
                                                                <select
                                                                    className="w-32 bg-transparent rounded-sm px-2"
                                                                    value={tile.type}
                                                                    onChange={(e) =>
                                                                        handleInputChange(floorIndex, tileIndex, 'type', e.target.value)
                                                                    }
                                                                >
                                                                    <option value="RB 01">RB 01</option>
                                                                    <option value="RB 02">RB 02</option>
                                                                    <option value="RB 03">RB 03</option>
                                                                    <option value="RB 04">RB 04</option>
                                                                    <option value="RB 05">RB 05</option>
                                                                </select>
                                                                <div className="flex ml-2 space-x-1 w-12 invisible group-hover:visible">
                                                                    <button onClick={() => addNewRowAfter(floorIndex, tileIndex)}>
                                                                        <img src={Right} alt="add" className="h-4" />
                                                                    </button>
                                                                    <button>
                                                                        <img src={Wrong} onClick={() => removeRow1(floorIndex, tileIndex)} alt="delete" className="h-4" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="w-20 p-1 rounded-lg  focus:outline-none cursor-pointer bg">
                                                            <select className="bg-transparent font-semibold">
                                                                <option>M15-1:2:4</option>
                                                                <option>M20-1:1.5:3</option>
                                                            </select>
                                                        </td>
                                                        <td className="-mt-2">
                                                            <select className="bg-transparent font-semibold">
                                                                <option>12"x12"</option>
                                                                <option>2"x2"</option>
                                                                <option>9"x9"</option>
                                                            </select>
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="length"
                                                                placeholder="L"
                                                                className="px-2 w-10 bg-transparent font-semibold hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="breadth"
                                                                placeholder="B"
                                                                className="px-2 w-10 bg-transparent font-semibold hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-1 ">
                                                            <input
                                                                type="text"
                                                                name="height"
                                                                placeholder="H"
                                                                className="px-2 w-10 bg-transparent font-semibold hover:border focus:outline-none text-center" />
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input className="w-14 bg-transparent font-semibold hover:border focus:outline-none"></input>
                                                        </td>

                                                        <td className="px-2 ">
                                                            <input
                                                                type="text"
                                                                className="px-2 w-16 bg-transparent font-semibold hover:border focus:outline-none" />
                                                        </td>
                                                        <td>
                                                            <input className="px-2 w-16 bg-transparent font-semibold hover:border focus:outline-none"></input>
                                                        </td>
                                                        <td className="px-2 ">
                                                            <input
                                                                className="  w-12 h-6 rounded bg-transparent font-semibold focus:outline-gray-200 focus:outline-none">
                                                            </input>
                                                        </td>

                                                        <td className="px-2 ">
                                                            <input className="  w-12 h-6 rounded bg-transparent font-semibold focus:outline-gray-200 focus:outline-none"></input>
                                                        </td>

                                                        <td className="px-2 ">
                                                            <input className="  w-20 h-7 rounded bg-gray-200 font-semibold focus:outline-none text-right"></input>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <button type="button" className="text-[#E4572E] mt-6 mb-20 -ml-[94%] border-dashed border-b-2 border-[#BF9853] font-semibold"
                                onClick={addFloorRow}>
                                + Add Floor
                            </button>
                        </div>
                        <div className=" buttons -mt-14 flex">
                            <div className="">
                                <button className="w-56 text-white px-6 py-2 rounded ml-2 bg-green-800 hover:text-white transition duration-200 ease-in-out">
                                    Engineer Copy
                                </button>
                            </div>
                            <div className="">
                                <button className="w-56 text-white px-6 py-2 rounded ml-2 bg-[#BF9853] hover:text-white transition duration-200 ease-in-out">
                                    Generate Bill
                                </button>
                            </div>
                            <div className="flex ml-[80%]">
                                <button
                                    type="submit"
                                    className="btn bg-[#BF9853] w-32 text-white px-8 py-2 rounded-md  font-semibold -ml-56">Submit
                                </button>
                            </div>
                        </div>
                        <div className="-mt-3 flex">
                            <div>
                                <div className="flex justify-between">
                                    <h1 className="font-bold text-lg mt-8">Overall Summary </h1>
                                    <h1 className="font-bold text-sm mt-10 text-[#E4572E]  hover:underline cursor-pointer">Export PDF</h1>
                                </div>
                                <div className="rounded-lg border-l-8 border-l-[#BF9853]">
                                    <table className="table-auto mt-2">
                                        <thead>
                                            <tr className="bg-[#FAF6ED]">
                                                <th className="font-extrabold p-2">S.No</th>
                                                <th className="p-2 font-extrabold">Floor Name</th>
                                                <th className="p-2 w-20 font-extrabold">Member Name</th>
                                                <th className="p-2 w-20 font-extrabold">Cement</th>
                                                <th className="p-2 w-20 font-extrabold">Sand</th>
                                                <th className="p-2 w-20 font-extrabold">Jally</th>
                                                <th className="p-2 w-32 font-extrabold">Total Qty (CFT)</th>
                                                <th className="p-2 w-20 font-extrabold">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-8" style={{ marginLeft: '420px' }}>
                                    <th>Total</th>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </body >

    );
}

export default RccCalculator
